import { isSubPlanIn, isSubLimitUnderFor, isSubDeviceIn } from "src/utils/rules";

const SUB_PLANS = Object.freeze({
  BASIC: "basic",
  FULL: "full",
});

const DEVICES = Object.freeze({
  INSPIRIT: "INSPIRIT",
  POLAR: "POLAR",
  INTENSE: "INTENSE",
});

const SUB_LIMITS = Object.freeze({
  TEAMS: "teams",
  MEMBERS: "members",
  PLAYERS: "players",
});

const permissions = {
  "club:members:any": isSubPlanIn([SUB_PLANS.FULL, SUB_PLANS.BASIC]),
  "club:members:write": isSubLimitUnderFor(SUB_LIMITS.MEMBERS),
  "club:teams:write": isSubLimitUnderFor(SUB_LIMITS.TEAMS),
  "event:charts:offline:read": isSubPlanIn([SUB_PLANS.FULL]),
  "event:compute:any": isSubDeviceIn([DEVICES.INSPIRIT, DEVICES.INTENSE]),
  "event:export:any": isSubPlanIn([SUB_PLANS.FULL, SUB_PLANS.BASIC]),
  "event:export:archive": isSubPlanIn([SUB_PLANS.FULL]),
  "event:export:csv": isSubPlanIn([SUB_PLANS.FULL, SUB_PLANS.BASIC]),
  "event:export:excel": isSubPlanIn([SUB_PLANS.FULL, SUB_PLANS.BASIC]),
  "event:export:fieldwiz": isSubPlanIn([SUB_PLANS.FULL, SUB_PLANS.BASIC]),
  "event:export:report": isSubPlanIn([SUB_PLANS.FULL]),
  "event:ranges:write": isSubPlanIn([SUB_PLANS.FULL]),
  "event:records:read": isSubPlanIn([SUB_PLANS.FULL, SUB_PLANS.BASIC]),
  "player:analyses:read": isSubPlanIn([SUB_PLANS.FULL]),
  "player:records:read": isSubPlanIn([SUB_PLANS.FULL]),
  "player:compare:any": isSubPlanIn([SUB_PLANS.FULL]),
  "player:export:report": isSubPlanIn([SUB_PLANS.FULL]),
  "player:health:any": isSubPlanIn([SUB_PLANS.FULL]),
  "player:picture:any": isSubPlanIn([SUB_PLANS.FULL, SUB_PLANS.BASIC]),
  "player:stats:read": isSubPlanIn([SUB_PLANS.FULL]),
  "record:analyses:read": isSubPlanIn([SUB_PLANS.FULL]),
  "record:load:read": isSubPlanIn([SUB_PLANS.FULL]),
  "team:analyses:read": isSubPlanIn([SUB_PLANS.FULL]),
  "team:players:write": isSubLimitUnderFor(SUB_LIMITS.PLAYERS),
  "team:ranges:write": isSubPlanIn([SUB_PLANS.FULL, SUB_PLANS.BASIC]),
};

export default permissions;
