<template>
  <q-timeline color="secondary" class="q-ma-none q-py-sm q-px-md">
    <q-timeline-entry
      :title="event.name"
      :subtitle="formatISO9075(new Date(event.startDate))"
      color="secondary"
      icon="timer"
      :body="body"
    >
      <slot name="event" :event="event" />
    </q-timeline-entry>

    <q-timeline-entry
      v-for="period in event.periods"
      :key="period.id"
      :title="period.name"
      :subtitle="`${formatISO9075(new Date(period.startDate))} (${
        getDuration(event.startDate, period.startDate) || '-'
      })`"
    >
      <slot name="period" :event="event" :period="period" />
    </q-timeline-entry>

    <q-timeline-entry
      title=""
      color="primary"
      icon="stop"
      :subtitle="`${formatISO9075(new Date(event.endDate))} (${getDuration(
        event.startDate,
        event.endDate
      )})`"
    />
  </q-timeline>
</template>

<script>
import { intervalToDuration, formatDuration, formatISO9075 } from "date-fns";

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatISO9075: formatISO9075,
    getDuration(startDate, endDate) {
      return formatDuration(
        intervalToDuration({
          start: new Date(startDate),
          end: new Date(endDate),
        })
      );
    },
  },
};
</script>
