import gql from "graphql-tag";
import { sessionFragment } from "./sessionQueries";

export const periodFragment = gql`
  fragment periodFragment on Period {
    id
    name
    startDate
    endDate
    number
  }
`;

export const intervalFragment = gql`
  fragment intervalFragment on Interval {
    periodId
    name
    startDate
    endDate
  }
`;

export const UPDATE_PERIOD_MUTATION = gql`
  mutation updatePeriod($eventId: ID!, $periodId: ID!, $period: PeriodInput) {
    updatePeriod(eventId: $eventId, periodId: $periodId, period: $period) {
      id
      name
    }
  }
`;

export const UPDATE_PERIOD_DATES_MUTATION = gql`
  mutation updatePeriodDates($eventId: ID!, $periodId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
    updatePeriodDates(eventId: $eventId, periodId: $periodId, startDate: $startDate, endDate: $endDate) {
      id
      periods {
        ...periodFragment
      }
      intervals {
        ...intervalFragment
      }
      sessions {
        ...sessionFragment
      }
    }
  }
  ${periodFragment}
  ${intervalFragment}
  ${sessionFragment}
`;
