import { CLUB_QUERY } from "src/graphql/clubQueries";
import { USER_QUERY } from "src/graphql/userQueries";
import permissions from "src/utils/permissions";

export const can = (ctx, rule, cache = {}) => {
  return (...args) => {
    try {
      const perm = permissions[rule];
      if (!perm) return false;

      // if args do not hit cache
      if (args.length) return perm(ctx)(...args);

      const cachedCan = cache[rule];

      if (cachedCan !== undefined) {
        return cachedCan;
      }

      const can = perm(ctx)(...args);

      cache[rule] = can;

      return can;
    } catch (error) {
      console.error(error);

      return false;
    }
  };
};

export default {
  data() {
    return {
      accessCache: {},
    };
  },
  methods: {
    can(rule) {
      if (!this.club || !this.user) return () => false;

      return can({ club: this.club, user: this.user }, rule, this.accessCache);
    },
    resetAccessCache() {
      this.accessCache = {};
    },
  },
  apollo: {
    club: {
      query: CLUB_QUERY,
      update: function (data) {
        this.resetAccessCache();

        return data.club;
      },
      skip() {
        return !this.user;
      },
    },
    user: {
      query: USER_QUERY,
      update: function (data) {
        this.resetAccessCache();

        return data.user;
      },
    },
  },
};
