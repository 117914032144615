import gql from "graphql-tag";
import { injuryFragment } from "./injuryQueries";
import { deviceFragment } from "./deviceQueries";
import { recordDataFragment } from "./recordQueries";
import { workloadFragment } from "./analysisQueries";

export const playerFragment = gql`
  fragment playerFragment on Player {
    id
    teamId
    name
    firstName
    lastName
    number
    position
    birthdate
    age
    gender
    weight
    height
    profile
    hrMax
    speedMax
    footRef
    status
    picture
    injury {
      ...injuryFragment
    }
    device {
      ...deviceFragment
    }
  }
  ${deviceFragment}
  ${injuryFragment}
`;

export const playerAnalysisFragment = gql`
  fragment playerAnalysisFragment on Player {
    analyses(followUp: true) {
      weekly {
        week
        workload {
          ...workloadFragment
        }
        data {
          distance {
            value
          }
          speed {
            ranges {
              hid {
                distance
              }
              sprint {
                distance
              }
            }
          }
        }
      }
    }
  }
  ${workloadFragment}
`;

export const PLAYERS_STATUSES_QUERY = gql`
  query playerStatuses {
    playerStatuses
  }
`;

export const PLAYER_QUERY = gql`
  query player($playerId: ID!) {
    player(playerId: $playerId) {
      ...playerFragment
      ...playerAnalysisFragment
    }
  }
  ${playerFragment}
  ${playerAnalysisFragment}
`;

export const PLAYER_STATS_QUERY = gql`
  query player($playerId: ID!) {
    player(playerId: $playerId) {
      id
      stats {
        match {
          average {
            data {
              ...recordDataFragment
            }
            periods {
              ...recordDataFragment
            }
          }
          best {
            data {
              ...recordDataFragment
            }
          }
          total {
            data {
              ...recordDataFragment
            }
          }
          ref {
            data {
              ...recordDataFragment
            }
          }
          time
          count
        }
        training {
          time
          count
          best {
            data {
              ...recordDataFragment
            }
          }
          average {
            data {
              ...recordDataFragment
            }
          }
          total {
            data {
              ...recordDataFragment
            }
          }
        }
        individualTraining {
          time
          count
          best {
            data {
              ...recordDataFragment
            }
          }
          average {
            data {
              ...recordDataFragment
            }
          }
          total {
            data {
              ...recordDataFragment
            }
          }
        }
      }
      ref {
        ...recordDataFragment
      }
    }
  }
  ${recordDataFragment}
`;

export const PLAYER_ANALYSES_QUERY = gql`
  query player($playerId: ID!, $from: Date, $to: Date, $followUp: Boolean) {
    player(playerId: $playerId) {
      id
      analyses(from: $from, to: $to, followUp: $followUp) {
        daily {
          date
          week
          day
          data {
            ...recordDataFragment
          }
          count {
            total
            training
            match
          }
        }
        weekly {
          date
          week
          data {
            ...recordDataFragment
          }
          count {
            total
            training
            match
          }
          workload {
            ...workloadFragment
          }
        }
      }
    }
  }
  ${workloadFragment}
  ${recordDataFragment}
`;

export const PLAYERS_QUERY = gql`
  query players {
    players {
      ...playerFragment
    }
  }
  ${playerFragment}
`;

export const UPDATE_PLAYER_MUTATION = gql`
  mutation updatePlayer($playerId: ID!, $player: PlayerInput) {
    updatePlayer(playerId: $playerId, player: $player) {
      ...playerFragment
    }
  }
  ${playerFragment}
`;

export const CREATE_PLAYER_MUTATION = gql`
  mutation createPlayer($teamId: ID!, $player: PlayerInput, $picture: File) {
    createPlayer(teamId: $teamId, player: $player, picture: $picture) {
      ...playerFragment
    }
  }
  ${playerFragment}
`;

export const DELETE_PLAYER_MUTATION = gql`
  mutation deletePlayer($playerId: ID!) {
    deletePlayer(playerId: $playerId)
  }
`;

export const ASSIGN_PLAYERS_TO_TEAM_MUTATION = gql`
  mutation assignPlayersToTeam($teamId: ID!, $playerIds: [ID]!) {
    assignPlayersToTeam(teamId: $teamId, playerIds: $playerIds)
  }
`;

export const UNASSIGN_PLAYERS_MUTATION = gql`
  mutation unassignPlayers($playerIds: [ID]!) {
    unassignPlayers(playerIds: $playerIds)
  }
`;

export const UPDATE_PLAYER_STATUS_MUTATION = gql`
  mutation updatePlayerStatus($playerId: ID!, $status: PlayerStatus!) {
    updatePlayerStatus(playerId: $playerId, status: $status) {
      ...playerFragment
    }
  }
  ${playerFragment}
`;

export const UPDATE_PLAYER_INJURY_STATUS_MUTATION = gql`
  mutation updatePlayerInjuryStatus($playerId: ID!, $status: InjuryStatus!) {
    updatePlayerInjuryStatus(playerId: $playerId, status: $status) {
      ...playerFragment
    }
  }
  ${playerFragment}
`;

export const UPLOAD_PLAYER_PICTURE_MUTATION = gql`
  mutation uploadPlayerPicture($playerId: ID!, $picture: File) {
    uploadPlayerPicture(playerId: $playerId, picture: $picture) {
      id
      picture
    }
  }
`;

export const REMOVE_PLAYER_PICTURE_MUTATION = gql`
  mutation removePlayerPicture($playerId: ID!) {
    removePlayerPicture(playerId: $playerId) {
      id
      picture
    }
  }
`;
