<template>
  <q-table
    title="Jobs"
    :rows="jobs"
    :columns="columns"
    row-key="id"
    flat
    bordered
    :rows-per-page-options="[0]"
    :no-data-label="$t('upload.no_jobs')"
  >
    <template #body-cell-state="props">
      <q-td :props="props">
        <q-circular-progress
          v-if="props.row.state === 'PROCESSING'"
          indeterminate
          rounded
          color="accent"
          size="1.2rem"
          class="q-mr-xs"
        />
        {{ props.row.state }}
      </q-td>
    </template>
  </q-table>
</template>
<script>
import { JOB_STATE_UPDATED_SUB } from "src/graphql/deviceQueries";

export default {
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      subs: [],
      columns: [
        {
          name: "device.name",
          label: "Device",
          field: ({ device }) => device.name,
          align: "left",
          sortable: true,
        },
        {
          name: "device.udid",
          label: "UDID",
          field: ({ device }) => device.udid,
          align: "left",
          sortable: true,
        },
        {
          name: "jobId",
          label: "jobId",
          field: "jobId",
          align: "left",
          sortable: true,
        },
        {
          name: "state",
          label: "State",
          field: "state",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    jobs: {
      handler() {
        for (const job of this.jobs) {
          let sub = this.subs[`job:${job.jobId}`];

          if (sub) continue;

          const observer = this.$apollo.subscribe({
            query: JOB_STATE_UPDATED_SUB,
            variables: {
              jobId: job.jobId,
            },
          });

          sub = observer.subscribe({
            next({ data }) {
              Object.assign(job, data.jobStateUpdated);
            },
            error(err) {
              console.error(err);

              job.state = "SUB_ERROR";
            },
          });

          this.subs[`job:${job.jobId}`] = sub;
        }
      },
      deep: true,
    },
  },
  beforeUnmount() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  },
};
</script>
