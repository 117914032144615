<template>
  <div class="q-pa-md">
    <e-charts v-if="data.length" :option="chart" :style="`height: ${barHeight}px !important`" />
    <q-range
      v-model="range"
      :style="`padding: 0 ${chartPadding}px !important`"
      :min="computedMin"
      :max="computedMax"
      :step="step"
      label-always
      snap
      color="secondary"
      label
      drag-range
      :left-label-value="leftLabelValue"
      :right-label-value="rightLabelValue"
      markers
      :track-color="offColor"
      @update:model-value="$emit('update', $event)"
    />
  </div>
</template>

<script>
import { colors } from "quasar";

const { getPaletteColor } = colors;

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    barHeight: {
      type: Number,
      default: 125,
    },
    barWidth: {
      type: Number,
      default: 14,
    },
    xKey: {
      type: String,
      default: "ts",
    },
    yKey: {
      type: String,
      default: "value",
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    from: {
      type: Number,
      default: 0,
    },
    to: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      step: 1000,
      range: {
        min: this.from || this.min || this.data[0]?.[this.xKey] || 0,
        max: this.to || this.max || this.data[this.data.length - 1]?.[this.xKey] || 0,
      },
      offColor: "grey",
      color: getPaletteColor("secondary"),
    };
  },
  computed: {
    leftLabelValue() {
      return this.markerLabelFn(this.range.min);
    },
    rightLabelValue() {
      return this.markerLabelFn(this.range.max);
    },
    computedMin() {
      return this.min || this.data[0]?.[this.xKey] || 0;
    },
    computedMax() {
      return this.max || this.data[this.data.length - 1]?.[this.xKey] || 0;
    },
    chartPadding() {
      return this.barWidth / 2;
    },
    chart() {
      return {
        dataset: {
          source: this.data,
        },
        grid: {
          left: this.chartPadding,
          right: this.chartPadding,
          bottom: this.chartPadding,
          top: 0,
        },
        visualMap: {
          show: false,
          dimension: 0,
          pieces: [
            {
              max: this.range.min,
              color: this.offColor,
            },
            {
              min: this.range.min,
              max: this.range.max,
              color: this.color,
            },
            {
              min: this.range.max,
              color: this.offColor,
            },
          ],
        },
        tooltip: {
          show: false,
        },
        xAxis: {
          show: false,
          min: this.computedMin,
          max: this.computedMax,
        },
        yAxis: {
          show: false,
          min: 0,
        },
        series: [
          {
            type: "bar",
            barMaxWidth: this.barWidth,
            itemStyle: {
              borderRadius: 14,
            },
            encode: {
              x: this.xKey,
              y: this.yKey,
            },
          },
          // {
          //   type: "line",
          //   smooth: true,
          //   areaStyle: {
          //     opacity: 0.5,
          //   },
          //   encode: {
          //     x: this.xKey,
          //     y: this.yKey,
          //   },
          // },
        ],
      };
    },
  },
  watch: {
    from(val) {
      this.range.min = val;
    },
    to(val) {
      this.range.max = val;
    },
  },
  methods: {
    markerLabelFn(value) {
      if (!value) return "";
      const time = (value - this.computedMin) / this.step;
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);

      return `${minutes} min` + (seconds ? ` ${seconds} sec` : "");
    },
  },
};
</script>
