<template>
  <q-stepper v-model="step" animated header-nav flat>
    <q-step
      v-for="period in periods"
      :key="period.id"
      :name="period.id"
      active-color="secondary"
      :title="period.name"
      :caption="intervalCaption(period)"
      icon="pending_actions"
      class="square"
    >
      <q-form class="row wrap q-gutter-sm" @reset="handlePeriodReset(period)" @submit="handlePeriodUpdate(period)">
        <div class="col-12">
          <BarSliderVue
            :data="speedSamples"
            :from="new Date(period.startDate).getTime()"
            :to="new Date(period.endDate).getTime()"
            :min="new Date(event.startDate).getTime()"
            :max="new Date(event.endDate).getTime()"
            @update="(data) => onUpdate(period, data)"
          />
        </div>
        <q-input v-model="period.name" label="period name" filled dense color="secondary" style="width: 300px">
          <template #after>
            <q-btn round dense flat icon="save" color="secondary" @click="handlePeriodNameUpdate(period)" />
          </template>
        </q-input>
        <q-space />
        <q-btn icon="restart_alt" type="reset" color="secondary" flat fab-mini dense />
        <q-btn flat fab-mini dense color="negative" icon="playlist_remove" @click="handleRemovePeriod(period)" />
        <q-btn icon="save" type="submit" color="positive" fab-mini flat />
      </q-form>
    </q-step>
    <q-step :name="periods.length" color="positive" title="createPeriod" icon="add">
      <q-form
        class="row wrap q-gutter-sm"
        @reset="handleNewPeriodReset(newPeriod)"
        @submit="handlePeriodCreate(newPeriod)"
      >
        <div class="col-12">
          <BarSliderVue
            :data="speedSamples"
            :from="new Date(newPeriod.startDate).getTime()"
            :to="new Date(newPeriod.endDate).getTime()"
            :min="new Date(event.startDate).getTime()"
            :max="new Date(event.endDate).getTime()"
            @update="(data) => onUpdate(newPeriod, data)"
          />
        </div>
        <q-input v-model="newPeriod.name" label="period name" filled dense color="secondary" style="width: 300px" />
        <q-space />
        <q-btn icon="restart_alt" type="reset" color="secondary" flat fab-mini dense />
        <q-btn icon="save" type="submit" color="positive" fab-mini flat />
      </q-form>
    </q-step>
  </q-stepper>
</template>

<script>
import { CREATE_PERIOD_MUTATION, REMOVE_PERIOD_MUTATION } from "src/graphql/eventQueries";
import { UPDATE_PERIOD_DATES_MUTATION, UPDATE_PERIOD_MUTATION } from "src/graphql/periodQueries";
import BarSliderVue from "src/components/ui/BarSlider.vue";
import { differenceInMinutes } from "date-fns";
import { cloneDeep } from "@apollo/client/utilities";
const MIN = 60 * 1000;

export default {
  components: { BarSliderVue },
  props: {
    event: Object,
    speedSamples: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      step: this.event.periods[0]?.id,
      newPeriod: this.getBlankPeriod(),
      periods: cloneDeep(this.event.periods),
    };
  },
  computed: {
    eventStartTs() {
      return new Date(this.event.startDate).getTime();
    },
    eventDuration() {
      return this.getDurationMin(this.event.startDate, this.event.endDate);
    },
  },
  methods: {
    ts2min(ts) {
      return Math.round(ts / MIN);
    },
    intervalCaption(interval) {
      const startTs = new Date(interval.startDate).getTime();
      const endTs = new Date(interval.endDate).getTime();

      return `${this.ts2min(startTs - this.eventStartTs)} - ${this.ts2min(endTs - this.eventStartTs)} (${this.ts2min(
        endTs - startTs
      )} min)`;
    },
    onUpdate(interval, range) {
      interval.startDate = new Date(range.min);

      interval.endDate = new Date(range.max);
    },
    handlePeriodReset(period) {
      const eventPeriod = this.event.periods.find((p) => p.id === period.id);

      period.startDate = eventPeriod.startDate;

      period.endDate = eventPeriod.endDate;
    },
    async handlePeriodUpdate(period) {
      await this.$apollo.mutate({
        mutation: UPDATE_PERIOD_DATES_MUTATION,
        variables: {
          eventId: this.event.id,
          periodId: period.id,
          startDate: period.startDate,
          endDate: period.endDate,
        },
      });

      this.periods = this.event.periods;
    },
    handlePeriodNameUpdate(period) {
      this.$apollo.mutate({
        mutation: UPDATE_PERIOD_MUTATION,
        variables: {
          eventId: this.event.id,
          periodId: period.id,
          period: {
            name: period.name,
          },
        },
      });
    },
    getDurationMin(startDate, endDate) {
      return differenceInMinutes(new Date(endDate), new Date(startDate));
    },
    async handlePeriodCreate(period) {
      this.$q
        .dialog({
          color: "positive",
          title: `${this.$t("event.period.create")}`,
          options: {
            type: "toggle",
            model: [true],
            items: [
              {
                label: this.$t("event.period.create.force"),
                value: true,
              },
            ],
          },
          cancel: {
            flat: true,
            rounded: true,
          },
          ok: {
            rounded: true,
            push: true,
          },
        })
        .onOk(async ([force]) => {
          await this.$apollo.mutate({
            mutation: CREATE_PERIOD_MUTATION,
            variables: {
              eventId: this.event.id,
              name: period.name,
              startDate: new Date(period.startDate),
              endDate: new Date(period.endDate),
              force,
            },
          });

          this.newPeriod = this.getBlankPeriod();

          this.periods = this.event.periods;
        });
    },
    handleNewPeriodReset() {
      this.newPeriod = this.getBlankPeriod();
    },
    getBlankPeriod() {
      return {
        name: "",
        startDate: this.event.startDate,
        endDate: this.event.endDate,
      };
    },
    handleRemovePeriod(period) {
      const that = this;

      this.$q
        .dialog({
          title: `Delete ${period.name} and the associated sessions ?`,
          ok: {
            color: "negative",
            rounded: true,
            flat: true,
          },
          cancel: {
            color: "primary",
            rounded: true,
            flat: true,
          },
        })
        .onOk(async () => {
          await that.$apollo.mutate({
            mutation: REMOVE_PERIOD_MUTATION,
            variables: {
              eventId: that.event.id,
              periodId: period.id,
            },
          });

          that.periods = that.periods.filter((p) => p.id !== period.id);
        });
    },
  },
};
</script>
