import { ApolloClient, InMemoryCache, from, split } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { createUploadLink } from "apollo-upload-client";
import { createApolloProvider } from "@vue/apollo-option";
import { setContext } from "@apollo/client/link/context";
import { i18n } from "src/boot/i18n";
const DEV = process.env.DEV;

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.GRAPHQL_WS,
    autoReconnect: true,
    connectionParams: () => {
      const authToken = localStorage.getItem("auth_token");
      const impersonateToken = localStorage.getItem("impersonate_token");

      return {
        headers: {
          authorization: authToken ? `Bearer ${authToken}` : "",
          impersonate: impersonateToken ? `Bearer ${impersonateToken}` : "",
        },
      };
    },
  })
);

import { Notify } from "quasar";

const httpLink = createUploadLink({
  uri: process.env.GRAPHQL_API,
});

export const apolloContext = {
  demo: false,
};

const contextLink = setContext((_, { headers }) => {
  const authToken = localStorage.getItem("auth_token");
  const impersonateToken = localStorage.getItem("impersonate_token");

  return {
    headers: {
      ...(headers || {}),
      authorization: authToken ? `Bearer ${authToken}` : "",
      impersonate: impersonateToken ? `Bearer ${impersonateToken}` : "",
      demo: apolloContext.demo,
      locale: i18n.locale.value,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    console.warn(`[Network error]: ${networkError}`);
  }

  if (graphQLErrors) {
    for (const { message, locations, path, extensions } of graphQLErrors) {
      const code = extensions?.code;

      if (DEV) {
        console.error(`[Error ${code}]: ${message}, Location: ${locations}, Path: ${path}`);
      }

      if (code) {
        Notify.create({
          message: i18n.t(`error_codes.${code}`, extensions?.args),
          type: "negative",
        });
      } else {
        Notify.create({
          message: `${i18n.t(`error_codes.UNEXPECTED_ERROR`)} : ${message}`,
          type: "negative",
        });
      }
    }
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink
);

const apolloClient = new ApolloClient({
  link: from([errorLink, contextLink, splitLink]),
  cache: new InMemoryCache(),
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

export default ({ app }) => {
  app.use(apolloProvider);
};

export { apolloClient };
