{
  "accel": "Aceleraciones",
  "accel.history": "Detalles de aceleración",
  "action": "Acción",
  "actions": "Acciónes",
  "activity": "Actividad",
  "analyses": "Análisis",
  "auto": "Auto",
  "average": "Promedio",
  "cancel": "Anular",
  "charts": "Gráficos",
  "clipboard.copy_msg": "Copiado al portapapeles !",
  "club.create": "crear club",
  "club.create.msg": "Introduce el nombre del club que quieres crear. \nSerás el dueño de este club.",
  "club.create.warning_msg": "Este proceso intentará crear una cuenta con su correo electrónico en nuestro proveedor de dispositivos asociado.",
  "club.member.add": "Añadir miembro",
  "club.member.add_msg": "Ingrese el correo electrónico del usuario que desea agregar.",
  "club.member.edit_access": "Editar acceso",
  "club.member.edit_access_msg": "Seleccionar acceso",
  "club.member.remove": "Quitar miembro",
  "club.member.remove_msg": "¿Estás seguro de que quieres eliminar a este miembro?",
  "club.members": "miembros del club",
  "club.name": "Nombre del club",
  "club.subscription.active": "suscripción activa",
  "club.subscription.not_active_msg": "Su suscripción al club no está activa. \nPóngase en contacto con nuestro equipo para activarlo.",
  "coach": "Entrenador",
  "columns": "Columnas",
  "compare": "Comparar",
  "compute": "Calcular",
  "compute.range.name": "Nombre de rango",
  "compute.ranges.accel": "Rangos de acel",
  "compute.ranges.speed": "Rangos de velocidad",
  "compute.warning_msg": "Calcule los registros de los jugadores en función de las sesiones. \n¡No se asuste, esta acción llevará algún tiempo!",
  "confirm": "Confirmar",
  "connected": "Conectado",
  "connection": "Conexión",
  "dashboard": "Dashboard",
  "data": "Datos",
  "day": "Día",
  "decel": "desacelerar",
  "demo_mode": "Modo de demostración",
  "device.add": "Añadir dispositivo",
  "device.add.enter_serial": "Ingrese el número de serie del dispositivo",
  "device.add.enter_serial_msg": "Puede encontrar el número de serie en la parte posterior del dispositivo.",
  "device.assign": "Asignar",
  "device.assign._confirm_msg": "¿Está seguro de que desea asignar este dispositivo a este reproductor?",
  "device.externalId": "Identificación del socio",
  "device.fwVersion": "Versión de firmware",
  "device.hwVersion": "Versión de hardware",
  "device.serial": "Número de serie",
  "device.unassign": "Desasignar",
  "device.unassign.confirm_msg": "¿Está seguro de que desea cancelar la asignación de este dispositivo?",
  "device.usb.connect": "Conectar dispositivo",
  "device.usb.discover": "Descubre dispositivos USB",
  "devices": "Dispositivos",
  "devices.sync": "sincronizar dispositivos",
  "disconnected": "Desconectado",
  "distance": "Distancia",
  "docking_station": "Docking station",
  "duration": "Duración",
  "email": "Correo electrónico",
  "endDate": "Fecha final",
  "error_codes.ASI_SERVICE_BULK_UPLOAD_INVALID_FILE": "Archivo no válido de carga masiva del servicio ASI",
  "error_codes.ASI_SERVICE_DEVICE_ALREADY_OWENED": "Dispositivo de servicio ASI ya en propiedad",
  "error_codes.ASI_SERVICE_DEVICE_NOT_ALLOWED": "Dispositivo de servicio ASI no permitido",
  "error_codes.ASI_SERVICE_DEVICE_NOT_FOUND": "Dispositivo de servicio ASI no encontrado",
  "error_codes.ASI_SERVICE_INVALID_CREDENTIALS": "Credenciales inválidas del servicio ASI",
  "error_codes.ASI_SERVICE_MISSING_CREDENTIALS": "Faltan credenciales del servicio ASI",
  "error_codes.ASI_SERVICE_NOT_CONFIGURED": "Servicio ASI no configurado",
  "error_codes.ASI_SERVICE_REFRESH_FAILED": "Error en la actualización del servicio ASI",
  "error_codes.ASI_SERVICE_UNKNOW_ERROR": "Error desconocido del servicio ASI",
  "error_codes.ASI_SERVICE_USERNAME_MISMATCH": "No coincide el nombre de usuario del servicio ASI",
  "error_codes.CANNOT_ASSIGN_DEVICE_TO_PLAYER": "No se puede asignar el dispositivo al jugador",
  "error_codes.CANNOT_DELETE_EVENT": "No se puede eliminar el evento",
  "error_codes.CANNOT_EDIT_MANAGER_ACCESS": "No se puede editar el acceso del administrador",
  "error_codes.CLUB_NOT_FOUND": "club no encontrado",
  "error_codes.CLUB_USER_NOT_FOUND": "Usuario del club no encontrado",
  "error_codes.DEVICE_ASSIGNED": "Dispositivo asignado",
  "error_codes.DEVICE_NOT_FOUND": "Dispositivo no encontrado",
  "error_codes.DEVICE_TYPE_NOT_SUPPORTED": "Tipo de dispositivo no compatible",
  "error_codes.EMAIL_ALREADY_EXISTS": "el Email ya existe",
  "error_codes.EMAIL_ALREADY_SENT": "Correo electrónico ya enviado",
  "error_codes.EVENT_ALREADY_EXISTS": "El evento ya existe",
  "error_codes.EVENT_COMPUTE_RUNNING": "Cómputo de eventos en ejecución",
  "error_codes.EVENT_FINISHED": "Evento terminado",
  "error_codes.EVENT_LOCKED": "Evento bloqueado",
  "error_codes.EVENT_NOT_FINISHED": "Evento no terminado",
  "error_codes.EVENT_NOT_FOUND": "Evento no encontrado",
  "error_codes.EVENT_NOT_STARTED": "Evento no iniciado",
  "error_codes.EVENT_STARTED": "Evento iniciado",
  "error_codes.INJURY_NOT_FOUND": "Lesión no encontrada",
  "error_codes.INTERNAL_SERVER_ERROR": "Error Interno del Servidor",
  "error_codes.INVALID_CREDENTIALS": "Credenciales no válidas",
  "error_codes.INVALID_TOKEN": "Simbolo no valido",
  "error_codes.MEMBER_LIMIT_REACHED": "Límite de miembros alcanzado",
  "error_codes.NO_CLUB_FOR_USER": "No hay club para el usuario",
  "error_codes.NO_CURRENT_SEASON": "Sin temporada actual",
  "error_codes.PERIODS_OVERLAP": "Los períodos se superponen",
  "error_codes.PERIOD_FINISHED": "Período terminado",
  "error_codes.PERIOD_NOT_FOUND": "Período no encontrado",
  "error_codes.PERIOD_OVERLAP": "Superposición de períodos",
  "error_codes.PERIOD_STARTED": "Período iniciado",
  "error_codes.PLAYER_HAS_NO_PICTURE": "El jugador no tiene imagen.",
  "error_codes.PLAYER_HAS_TEAM": "El jugador tiene equipo.",
  "error_codes.PLAYER_LIMIT_REACHED": "Límite de jugadores alcanzado",
  "error_codes.PLAYER_NOT_FOUND": "Jugador no encontrado",
  "error_codes.PLAYER_NOT_SELECTED": "Jugador no seleccionado",
  "error_codes.PLAYER_SELECTED": "jugador seleccionado",
  "error_codes.RANGE_LIMIT_EXCEEDED": "Límite de rango de datos excedido",
  "error_codes.RANGE_LIMIT_REACHED": "Límite de rango de datos alcanzado",
  "error_codes.RANGE_NOT_FOUND": "Rango de datos no encontrado",
  "error_codes.RECORD_NOT_FOUND": "Registro no encontrado",
  "error_codes.SEASON_NOT_FOUND": "Temporada no encontrada",
  "error_codes.SELECTION_NOT_FOUND": "Selección no encontrada",
  "error_codes.SERVER_UNREACHABLE": "Servidor inalcanzable",
  "error_codes.SESSION_NOT_FOUND": "Sesión no encontrada",
  "error_codes.TEAM_LIMIT_REACHED": "Límite de equipo alcanzado",
  "error_codes.TEAM_NOT_FOUND": "Equipo no encontrado",
  "error_codes.UNAUTHORIZED": "No autorizado",
  "error_codes.UNEXPECTED_ERROR": "Error inesperado",
  "error_codes.UNKNOWN_ERROR": "Error desconocido",
  "error_codes.USER_ALREADY_EXISTS": "El usuario ya existe",
  "error_codes.USER_HAS_CLUB": "El usuario tiene club",
  "error_codes.USER_NOT_FOUND": "Usuario no encontrado",
  "error_codes.USER_NOT_VERIFIED": "Usuario no verificado",
  "errors": "errores",
  "event.compute": "Calcular evento",
  "event.create": "Crear evento",
  "event.creation": "Creación de eventos",
  "event.export.download.fieldwiz_analyze_CSV": "Descargar FieldWiz Analizar CSV",
  "event.export.download_report": "Descargar informe",
  "event.export.empty_report": "Informe vacío",
  "event.export.reset_report": "Restablecer informe",
  "event.name": "Nombre del evento",
  "event.period.create": "Crear período",
  "event.period.create.force": "Forzar sesiones para todos los jugadores durante este período",
  "event.period.new": "Nuevo periodo",
  "event.period.start": "Periodo de inicio",
  "event.period.stop": "Periodo de parada",
  "event.quick_import": "Importación rápida",
  "event.start": "Evento de inicio",
  "event.stop": "Detener evento",
  "event.type": "Tipo de evento",
  "event.type.INDIVIDUAL_MATCH": "Partido Individual",
  "event.type.INDIVIDUAL_TRAINING": "Entrenamiento Individual",
  "event.type.MATCH": "Partido",
  "event.type.TRAINING": "Entrenamiento",
  "exit": "Salida",
  "exit_confirm_msg": "¿Está seguro de que desea cerrar la sesión?",
  "export": "Exportar",
  "friday": "Viernes",
  "global.assign": "Asignar",
  "global.assigned": "Asignado",
  "global.back": "Atrás",
  "global.cancel": "Cancelar",
  "global.compute": "Calcular",
  "global.contact_us": "Contáctenos",
  "global.delete": "Borrar",
  "global.finish": "Finalizar",
  "global.hybrid": "Híbrido",
  "global.live": "Live",
  "global.loading": "Cargando",
  "global.next": "Próximo",
  "global.offline": "Offline",
  "global.online": "On line",
  "global.options": "Opciones",
  "global.period": "Período",
  "global.scenario": "Guión",
  "global.start": "Comenzar",
  "global.stop": "Detener",
  "global.success": "Éxito",
  "global.system": "Sistema",
  "global.unassign": "Desasignar",
  "global.view": "Vista",
  "health": "Salud",
  "heartRate": "Ritmo cardiaco",
  "heatmap": "Heat map",
  "home": "Home",
  "hr": "RC",
  "import": "Importar",
  "import.usb-dkg": "Importación USB/DKG",
  "import.usb.close_modal": "Cerrar modal",
  "import.usb.create_file_error": "Error al crear el archivo",
  "import.usb.create_file_msg": "Creando archivo para subir...",
  "import.usb.data_upload": "Carga de datos del dispositivo",
  "import.usb.detected_browser": "Navegador detectado: {nombre}",
  "import.usb.download_device_data_msg": "Descargando datos del dispositivo...",
  "import.usb.download_error": "Error al descargar datos del dispositivo",
  "import.usb.download_msg": "Descarga de datos del dispositivo",
  "import.usb.erase_error": "Error al borrar datos del dispositivo",
  "import.usb.erase_msg": "Borrado de datos del dispositivo",
  "import.usb.file_upload_error": "Error al cargar el archivo",
  "import.usb.file_upload_msg": "Cargando archivo",
  "import.usb.job_error": "¡Error en la importación de datos del dispositivo!",
  "import.usb.job_error_msg": "La importación de datos del dispositivo falló, inténtalo de nuevo más tarde.",
  "import.usb.job_state_updated_msg": "Estado de procesamiento de datos del dispositivo: {estado}",
  "import.usb.job_success": "¡Importación de datos del dispositivo finalizada!",
  "import.usb.link_copy_success_msg": "URL copiada al portapapeles",
  "import.usb.no_data": "No hay datos del dispositivo",
  "import.usb.processing_job": "Procesando datos",
  "import.usb.success_msg": "Datos subidos con éxito",
  "import.usb.unsupported_browser_msg": "La carga USB no está disponible en el navegador actual, recomendamos usar el navegador de escritorio Google Chrome.",
  "import.usb.uploading_file_msg": "Cargando archivo de datos del dispositivo...",
  "intervals": "Intervalos",
  "lan.it-IT": "italiano",
  "lang.de-DE": "Alemán",
  "lang.en-GB": "Inglés",
  "lang.en-US": "Inglés",
  "lang.es-ES": "ingles",
  "lang.fr-FR": "francés",
  "language": "Idioma",
  "league.ACADEMY": "Academia",
  "league.INTERNATIONAL": "Internacional",
  "league.PRO1": "Pro1",
  "league.PRO2": "Pro2",
  "light": "Luz",
  "list": "Lista",
  "live": "Live",
  "load]": "Carga",
  "login": "Login",
  "login.caption": "Por favor inicie sesión para continuar",
  "manager": "Gerente",
  "max": "máx.",
  "monday": "Lunes",
  "name": "Nombre",
  "night": "Noche",
  "off": "Off",
  "offline": "Offline",
  "on": "En",
  "password": "Clave",
  "password_reset.error": "Error al restablecer la contraseña",
  "player.add": "Agregar jugador",
  "player.birthdate": "Fecha de nacimiento",
  "player.delete": "Eliminar {playerName}",
  "player.delete.msg": "¿Estás seguro de que quieres eliminar {playerName}?",
  "player.firstName": "Appelido",
  "player.footRef": "Pie",
  "player.footRef.LEFT": "Izquierdo",
  "player.footRef.RIGHT": "Derecho",
  "player.gender": "Género",
  "player.gender.MAN": "Hombre",
  "player.gender.WOMAN": "Mujer",
  "player.gps_profile": "perfil GPS",
  "player.height": "altura",
  "player.hrMax": "RC máx.",
  "player.lastName": "Nombre",
  "player.number": "Número",
  "player.position": "Posición",
  "player.position.ASSISTANT_REFEREE": "Árbitro asistente",
  "player.position.CENTER_BACK": "Defensa central",
  "player.position.CENTRE_THREE_QUARTER": "Centre Three Quarter",
  "player.position.DEFENSIVE_MIDFIELDER": "Mediocampista defensivo",
  "player.position.FLANKER": "Flanker",
  "player.position.FLY_HALF": "Fly half",
  "player.position.FORWARD": "Delantero",
  "player.position.FULL_BACK": "Defensa lateral",
  "player.position.GOALKEEPER": "Portero",
  "player.position.HOOKER": "Hooker N°2",
  "player.position.LOCK": "Second row",
  "player.position.MIDFIELDER": "Centrocampista",
  "player.position.OFFENSIVE_MIDFIELDER": "Mediocampista ofensivo",
  "player.position.PROP": "Prop",
  "player.position.REFEREE": "Árbitro",
  "player.position.SCRUM_HALF": "Scrum half",
  "player.position.SIDE_MIDFIELDER": "Mediocampista lateral",
  "player.position.THIRD_LINE": "Third line",
  "player.position.WINGER": "Lateral offensive",
  "player.profile": "Perfil del jugador",
  "player.profile.AEROBIC": "Aerobio",
  "player.profile.INTERMEDIATE": "Intermedio",
  "player.profile.SPEED": "Velocidad",
  "player.speedMax": "máxima velocidad",
  "player.status": "Estado",
  "player.status.ABSENT": "Ausente",
  "player.status.CARE": "Cuidado",
  "player.status.FITNESS_REHAB": "rehab física",
  "player.status.INJURED": "Herido",
  "player.status.PHYSIO_REHAB": "Rehab fisio",
  "player.status.RECOVERED": "Curado",
  "player.status.RECOVERY": "Recuperación",
  "player.status.SELECTION": "Selección",
  "player.status.SICK": "Enfermo",
  "player.status.VALID": "Válido",
  "player.status.select": "Selecciona el estado del jugador",
  "player.update": "Actualizar jugador",
  "player.weight": "Peso",
  "players": "jugadores",
  "profile": "Perfil",
  "range.dates": "rango de fechas",
  "range.from": "De",
  "range.to": "A",
  "ratio": "Relación",
  "record": "Registro",
  "refresh": "Actualizar",
  "register": "Registro",
  "register.caption": "Registre una nueva cuenta",
  "report": "Reporte",
  "reset": "Reiniciar",
  "reset_password": "Restablecer la contraseña",
  "reset_password.caption": "Restablecer su contraseña",
  "reset_password.error": "Error al restablecer la contraseña",
  "reset_password.success": "Correo electrónico de restablecimiento de contraseña enviado",
  "rule.email": "Por favor introduzca una dirección de correo electrónico válida",
  "rule.password": "La contraseña debe tener al menos 8 caracteres y contener al menos una letra mayúscula, una letra minúscula y un número",
  "rule.required": "Este campo es obligatorio",
  "saturday": "sábado",
  "search": "Búsqueda",
  "season": "Temporada",
  "season.create": "Nueva temporada",
  "season.endDate": "Fin de la temporada",
  "season.league": "Liga",
  "season.name": "Nombre de la temporada",
  "season.startDate": "Inicio de la temporada",
  "seasons": "Estaciones",
  "selection": "Selección",
  "selection.slide_left_to_unselect": "Desliza hacia la izquierda para deseleccionar al jugador",
  "selection.slide_right_to_select": "Desliza hacia la derecha para seleccionar jugador",
  "selection.update": "Actualizar selección para {playerName}",
  "sessions": "Sesiones",
  "settings": "Ajustes",
  "speed": "Velocidad",
  "sprint": "Esprint",
  "sprint.history": "Detalles del sprint",
  "sprints": "Sprints",
  "sprints.weekly_stats": "Estadísticas de sprints semanales",
  "startDate": "Fecha de inicio",
  "statistics": "Estadísticas",
  "subscription": "Suscripción",
  "subscription.level": "Nivel de suscripción",
  "subscription.limits.members": "límite de miembros",
  "subscription.limits.players": "Límite de jugadores",
  "subscription.limits.teams": "Límite de equipos",
  "subscription.plan": "Plan de suscripción",
  "subscription.services": "Servicios de suscripción",
  "subscriptions": "Suscripciones",
  "sunday": "Domingo",
  "team": "Equipo",
  "team.create": "Crear equipo",
  "team.delete": "Eliminar equipo",
  "team.delete_keepPlayers": "Mantener jugadores",
  "team.delete_keepPlayers_msg": "¿Quieres eliminar a los jugadores del equipo?",
  "team.name": "Nombre del equipo",
  "team.players.assign": "Asignar a {teamName}",
  "team.players.assign_confirm": "¿Estás seguro de que quieres asignar {playerNames} a {teamName}?",
  "team.players.unassign": "Desasignar de {teamName}",
  "team.players.unassign_confirm": "¿Estás seguro de que deseas cancelar la asignación de {playerNames} de {teamName}?",
  "team.update": "Actualizar equipo",
  "teams": "Equipos",
  "thursday": "Jueves",
  "time": "Hora",
  "timeline": "Cronología",
  "tuesday": "Martes",
  "type": "Escribe",
  "update": "Actualizar",
  "upload": "Subir",
  "upload.no_device": "Sin dispositivo",
  "upload.no_jobs": "No trabajos",
  "usb": "USB",
  "user": "Usuario",
  "user.copy_email": "Copiar correo electrónico",
  "user.new": "Nuevo Usuario",
  "user.verification": "Verificacion de email",
  "user.verification_msg": "Para obtener acceso a la aplicación, debe verificar su correo electrónico.",
  "users": "Usuarios",
  "verification_msg": "Para verificar su cuenta, haga clic en el enlace enviado a su correo electrónico. \nSi ya ha realizado la verificación, haga clic en el botón de abajo para acceder a la aplicación. \nSi no encuentra el correo electrónico, por favor revise su carpeta de correo no deseado.",
  "verified": "Verificado",
  "verify": "Verificar",
  "version": "Versión",
  "wednesday": "Miércoles",
  "week": "Semana",
  "welcome_msg": "¡Bienvenido a la aplicación! \npara comenzar a usarlo, debe crear uno nuevo o unirse a un club existente pidiéndole al administrador del club que agregue su correo electrónico.",
  "workload": "Carga de trabajo"
}
