import gql from "graphql-tag";
import { paginationFragment } from "./baseQueries";

export const recordDataFragment = gql`
  fragment recordDataFragment on RecordData {
    time {
      value
      duration
    }
    hr {
      value
      min
      max
      avg
      ranges {
        z0 {
          distance
          duration
        }
        z1 {
          distance
          duration
        }
        z2 {
          distance
          duration
        }
        z3 {
          distance
          duration
        }
        z4 {
          distance
          duration
        }
        z5 {
          distance
          duration
        }
        z6 {
          distance
          duration
        }
      }
    }
    distance {
      value
      avg
    }
    speed {
      min
      max
      avg
      ranges {
        hid {
          distance
        }
        hid0 {
          distance
        }
        hid1 {
          distance
        }
        hid2 {
          distance
        }
        hir {
          count
          distance
        }
        sprint {
          count
          distance
          duration
          avg
        }
        custom_0 {
          distance
          count
          duration
        }
        custom_1 {
          distance
          count
          duration
        }
        custom_2 {
          distance
          count
          duration
        }
        custom_3 {
          distance
          count
          duration
        }
      }
    }
    accel {
      min
      max
      ranges {
        a2 {
          count
        }
        a25 {
          count
        }
        a3 {
          count
        }
        d2 {
          count
        }
        d25 {
          count
        }
        d3 {
          count
        }
        ad2 {
          count
        }
        ad3 {
          count
        }
        custom_0 {
          count
        }
        custom_1 {
          count
        }
        custom_2 {
          count
        }
        custom_3 {
          count
        }
      }
    }
    load {
      tpi
      gs
      gpi
    }
    err {
      time
      speed
      accel
    }
  }
`;

export const historyDataPointFragment = gql`
  fragment historyDataPointFragment on HistoryDataPoint {
    start
    end
    max
    min
    distance
  }
`;

export const recordExtendedFragment = gql`
  fragment recordExtendedFragment on Record {
    extended {
      hr {
        serie
      }
      speed {
        serie
        ranges {
          sprint {
            history {
              ...historyDataPointFragment
            }
          }
        }
      }
      accel {
        ranges {
          a2 {
            history {
              ...historyDataPointFragment
            }
          }
          d2 {
            history {
              ...historyDataPointFragment
            }
          }
        }
      }
      heatmap
    }
  }
  ${historyDataPointFragment}
`;

export const recordFragment = gql`
  fragment recordFragment on Record {
    id
    playerId
    globalData {
      ...recordDataFragment
      ratio {
        ...recordDataFragment
      }
    }
    data {
      ...recordDataFragment
      ratio {
        ...recordDataFragment
      }
    }
    periodRecords {
      periodId
      data {
        ...recordDataFragment
        ratio {
          ...recordDataFragment
        }
      }
    }
    training
  }
  ${recordDataFragment}
`;

export const UPDATE_RECORD_DATA_MUTATION = gql`
  mutation updateRecordData($eventId: ID!, $playerId: ID!, $data: RecordDataInput!, $periodId: ID) {
    updateRecordData(eventId: $eventId, playerId: $playerId, data: $data, periodId: $periodId) {
      ...recordFragment
    }
  }
  ${recordFragment}
`;

export const CREATE_RECORDS_MUTATION = gql`
  mutation createRecords($eventId: ID!, $playerIds: [ID]!) {
    createRecords(eventId: $eventId, playerIds: $playerIds) {
      ...recordFragment
    }
  }
  ${recordFragment}
`;

export const DELETE_RECORD_MUTATION = gql`
  mutation deleteRecord($eventId: ID!, $playerId: ID!, $periodId: ID) {
    deleteRecord(eventId: $eventId, playerId: $playerId, periodId: $periodId) {
      ...recordFragment
    }
  }
  ${recordFragment}
`;

export const RECORD_UPDATED_SUB = gql`
  subscription recordUpdated($eventId: ID!) {
    recordUpdated(eventId: $eventId) {
      ...recordFragment
    }
  }
  ${recordFragment}
`;

export const PLAYER_RECORDS_QUERY = gql`
  query playerRecords($playerId: ID!, $type: String, $limit: Int, $page: Int) {
    player(playerId: $playerId) {
      id
      records(eventType: $type, pagination: { limit: $limit, page: $page }) {
        docs {
          eventId
          date
          eventType
          ...recordFragment
        }
        pagination {
          ...paginationFragment
        }
      }
    }
  }
  ${recordFragment}
  ${paginationFragment}
`;

export const RECORD_QUERY = gql`
  query record($playerId: ID!, $eventId: ID!) {
    record(playerId: $playerId, eventId: $eventId) {
      id
      globalData {
        ...recordDataFragment
      }
      data {
        ...recordDataFragment
      }
      periodRecords {
        periodId
        data {
          ...recordDataFragment
        }
      }
      training
    }
  }
  ${recordDataFragment}
`;

export const EXTENDED_RECORD_QUERY = gql`
  query record($playerId: ID!, $eventId: ID!) {
    record(playerId: $playerId, eventId: $eventId) {
      id
      globalData {
        ...recordDataFragment
        ratio {
          ...recordDataFragment
        }
      }
      data {
        ...recordDataFragment
        ratio {
          ...recordDataFragment
        }
      }
      periodRecords {
        periodId
        data {
          ...recordDataFragment
          ratio {
            ...recordDataFragment
          }
        }
      }
      ref {
        ...recordDataFragment
      }
      training
      ...recordExtendedFragment
    }
  }
  ${recordDataFragment}
  ${recordExtendedFragment}
`;
