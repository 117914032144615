<template>
  <q-table
    v-model:selected="selectedPlayers"
    flat
    title="Selection"
    :rows="players"
    :columns="columns"
    row-key="id"
    selection="multiple"
    binary-state-sort
    :sort-method="customSort"
    :loading="$apollo.loading"
    :rows-per-page-options="[0]"
    hide-bottom
    column-sort-order="da"
    class="table-top-no-padding sticky-header-table"
    :filter="filter"
    @update:selected="onSelected"
  >
    <template #header-selection="scope">
      <q-toggle v-model="scope.selected" color="secondary" />
    </template>

    <template #body-selection="scope">
      <q-toggle v-model="scope.selected" color="secondary" />
    </template>
    <template #body-cell-teamId="props">
      <q-td :props="props" :class="{ 'text-secondary': props.row.teamId === teamId }">
        {{ teamsById[props.row.teamId]?.name }}
      </q-td>
    </template>

    <template #top>
      <div class="fit row q-pa-sm">
        <q-input
          v-model="filter"
          outlined
          filled
          dense
          color="secondary"
          clearable
          debounce="300"
          placeholder="Search"
          class="q-mr-md"
        />

        <q-space />
        <div>
          <slot name="action" />
        </div>
      </div>
    </template>
  </q-table>
</template>

<script>
import { PLAYERS_QUERY } from "src/graphql/playerQueries";
import { TEAMS_QUERY } from "src/graphql/teamQueries";
import { keyBy, orderBy } from "lodash";

export default {
  props: {
    teamId: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      players: [],
      selectedPlayers: [],
      filter: "",
      columns: [
        {
          name: "name",
          required: true,
          label: "Player",
          align: "center",
          field: (row) => row.name,
          sortable: true,
        },
        {
          name: "deviceName",
          required: true,
          label: "Device",
          align: "center",
          field: ({ device }) => device?.name,
          sortable: true,
        },
        {
          name: "status",
          required: true,
          label: "Status",
          align: "center",
          field: (row) => row.status,
          sortable: true,
        },
        {
          name: "number",
          required: true,
          label: "Number",
          align: "center",
          field: (row) => row.number,
          sortable: false,
        },
        {
          name: "teamId",
          required: true,
          label: "Team",
          align: "center",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    teamsById() {
      return keyBy(this.teams, "id");
    },
    selectedPlayersById() {
      return keyBy(this.selectedPlayers, "id");
    },
  },
  methods: {
    customSort(rows, sortBy, descending) {
      if (sortBy === "name") {
        sortBy = ({ id }) => !!this.selectedPlayersById[id];
      }

      const iterate = [sortBy];

      if (this.teamId) {
        iterate.push((p) => p.teamId === this.teamId);
      }

      iterate.push("device");

      return orderBy(rows, iterate, [descending ? "desc" : "asc", "desc", "desc"]);
    },
    onSelected() {
      this.$emit("update:modelValue", Object.keys(this.selectedPlayersById));
    },
  },
  apollo: {
    players: {
      query: PLAYERS_QUERY,
      update({ players }) {
        players = players.filter((p) => !!p.teamId);

        this.selectedPlayers = players.filter((p) => this.modelValue.includes(p.id));

        return this.customSort(players, "name", true);
      },
    },
    teams: {
      query: TEAMS_QUERY,
    },
  },
};
</script>
