<template>
  <q-footer elevated>
    <q-toolbar>
      <q-btn flat round icon="menu" stretch class="q-px-md" @click="onMenuClick" />
      <q-tabs inline-label stretch class="full-width" align="justify" narrow-indicator switch-indicator>
        <q-tab icon="arrow_back" @click="onNavBack" />
        <q-route-tab v-if="teamId" icon="people" :to="{ name: 'teamPlayers', params: { teamId } }" />
        <q-route-tab
          v-if="teamId && $route.query.eventId"
          icon="sports_score"
          :to="{ name: 'eventRecords', params: { eventId: $route.query.eventId } }"
        />
        <q-route-tab v-else-if="teamId" icon="sports_score" :to="{ name: 'teamEvents', params: { teamId } }" />
      </q-tabs>
    </q-toolbar>
  </q-footer>
</template>
<script>
import { PLAYER_QUERY } from "src/graphql/playerQueries";
import { EVENT_QUERY } from "src/graphql/eventQueries";

export default {
  computed: {
    teamId() {
      return this.$route.params.teamId || this.player?.teamId || this.event?.teamId;
    },
    playerId() {
      return this.$route.params.playerId;
    },
    eventId() {
      return this.$route.params.eventId;
    },
  },
  methods: {
    onNavBack() {
      this.$router.back();
    },
    onMenuClick() {
      this.$emit("menu-click");
    },
  },
  apollo: {
    player: {
      query: PLAYER_QUERY,
      variables() {
        return {
          playerId: this.playerId,
        };
      },
      skip() {
        return !this.playerId;
      },
    },
    event: {
      query: EVENT_QUERY,
      variables() {
        return {
          eventId: this.eventId,
        };
      },
      skip() {
        return !this.eventId;
      },
    },
  },
};
</script>
