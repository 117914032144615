import gql from "graphql-tag";
import { recordDataFragment } from "./recordQueries";

export const workloadFragment = gql`
  fragment workloadFragment on Workload {
    acute
    chronic
    ratio
  }
`;

export const EVENT_PLAYERS_ANALYSES_QUERY = gql`
  query eventPlayerAnalyses($eventId: ID!, $to: Date) {
    event(eventId: $eventId) {
      id
      selection {
        id
        player {
          id
          lastName
          analyses(to: $to, followUp: true) {
            weekly {
              workload {
                ...workloadFragment
              }
            }
          }
        }
      }
    }
  }
  ${workloadFragment}
`;

export const TEAM_ANALYSES_QUERY = gql`
  query teamAnalayses($teamId: ID!, $from: Date, $to: Date) {
    team(teamId: $teamId) {
      id
      players {
        id
        lastName
        status
        analyses(from: $from, to: $to) {
          period {
            count {
              total
              training
              match
            }
            data {
              ...recordDataFragment
            }
          }
        }
      }
      analyses(from: $from, to: $to) {
        weekly {
          week
          date
          data {
            load {
              tpi
            }
          }
        }
        daily {
          date
          week
          data {
            load {
              tpi
            }
          }
          count {
            total
            training
            match
          }
        }
      }
    }
  }
  ${recordDataFragment}
`;
