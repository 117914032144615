const SUBSCRIPTION_FIELD = "subscription";

// first arg is config if needed
// second arg is context
// third arg is value

export const isSubPlanIn = (levels) => (ctx) => () => {
  return levels.includes(ctx?.club?.[SUBSCRIPTION_FIELD]?.plan);
};

export const isSubLimitUnderFor = (limitKey) => (ctx) => (value) => {
  return ctx?.club?.[SUBSCRIPTION_FIELD]?.limits?.[limitKey] > value;
};

export const isUserManager = (ctx) => () => {
  return ctx?.user?.manager;
};

export const isSubDeviceIn = (services) => (ctx) => () => {
  return services.some((service) => ctx?.club?.[SUBSCRIPTION_FIELD]?.devices?.includes(service));
};

export const or =
  (...rules) =>
  (ctx) =>
  (value) => {
    return rules.some((rule) => rule(ctx)(value));
  };

export const and =
  (...rules) =>
  (ctx) =>
  (value) => {
    return rules.every((rule) => rule(ctx)(value));
  };

export const not = (rule) => (ctx) => (value) => {
  return !rule(ctx)(value);
};

export const allow = () => () => true;

export const deny = () => () => false;
