<template>
  <q-breadcrumbs active-color="white" :gutter="$q.screen.lt.md ? 'xs' : 'sm'">
    <template #separator>
      <q-icon size="1.2em" name="arrow_forward" color="secondary" />
    </template>
    <q-breadcrumbs-el v-if="club && $q.screen.lt.md" icon="home" :to="{ name: 'clubDashboard' }" />
    <q-breadcrumbs-el v-else-if="club" :label="club.name" :to="{ name: 'clubDashboard' }" />
    <q-breadcrumbs-el
      v-if="selectedTeam && $q.screen.lt.md && selectedEvent"
      icon="groups"
      :to="{
        name: selectedEvent ? 'teamEvents' : selectedPlayer ? 'teamPlayers' : 'teamDashboard',
        params: { teamId: selectedTeam.id },
      }"
    />
    <q-breadcrumbs-el
      v-else-if="selectedTeam"
      :label="selectedTeam.name"
      icon="groups"
      :to="{
        name: selectedEvent ? 'teamEvents' : selectedPlayer ? 'teamPlayers' : 'teamDashboard',
        params: { teamId: selectedTeam.id },
      }"
    />
    <q-breadcrumbs-el
      v-if="selectedEvent"
      :label="$q.screen.lt.md ? trim(selectedEvent.name, 24) : selectedEvent.name"
      :to="{
        name: 'eventRecords',
        params: {
          eventId: selectedEvent.id,
        },
      }"
    />
    <q-breadcrumbs-el v-if="selectedPlayer" :label="selectedPlayer.name" />
  </q-breadcrumbs>
</template>

<script>
import { TEAMS_QUERY } from "src/graphql/teamQueries";
import { PLAYER_QUERY } from "src/graphql/playerQueries";
import { EVENT_QUERY } from "src/graphql/eventQueries";

export default {
  props: {
    club: {
      type: Object,
      default: null,
    },
  },
  computed: {
    computedTeamId() {
      return this.teamId || this.selectedPlayer?.teamId || this.selectedEvent?.teamId;
    },
    selectedTeam() {
      return this.computedTeamId && this.teams?.find((t) => t.id === this.computedTeamId);
    },
    selectedPlayer() {
      return this.playerId && this.player;
    },
    selectedEvent() {
      return this.eventId && this.event;
    },
    teamId() {
      return this.$route.params.teamId || this.selectedPlayer?.teamId || this.selectedEvent?.teamId;
    },
    playerId() {
      return this.$route.params.playerId;
    },
    eventId() {
      return this.$route.params.eventId || this.$route.query.eventId;
    },
  },
  methods: {
    trim(string, length, trimEnd = true) {
      const ending = "...";

      if (string.length <= length - ending.length) {
        return string + (trimEnd ? ending : "");
      } else {
        const stringArray = string.split(" ");

        if (stringArray.length > 1) {
          return this.trim(stringArray.slice(0, -1).join(" "), length, true);
        }

        return string.substring(0, length) + ending;
      }
    },
  },
  apollo: {
    teams: {
      query: TEAMS_QUERY,
      skip() {
        return !this.club;
      },
    },
    player: {
      query: PLAYER_QUERY,
      variables() {
        return { playerId: this.playerId };
      },
      skip() {
        return !this.playerId;
      },
    },
    event: {
      query: EVENT_QUERY,
      variables() {
        return { eventId: this.eventId };
      },
      skip() {
        return !this.eventId;
      },
    },
  },
};
</script>
