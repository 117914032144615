import { apolloClient } from "boot/apollo";
import { CLUB_QUERY } from "src/graphql/clubQueries";
import { USER_QUERY } from "src/graphql/userQueries";
import { can } from "src/mixins/accessMixin";

export const getUser = () =>
  apolloClient
    .query({ query: USER_QUERY })
    .then(({ data }) => data?.user)
    .catch(() => null);

export const getClub = () =>
  apolloClient
    .query({ query: CLUB_QUERY })
    .then(({ data }) => data?.club)
    .catch(() => null);

export const hasClub = async () => getClub();

export const isClubSubscriptionActive = async () => {
  const club = await getClub();

  return club?.subscription?.active;
};

export const isAuthenticated = async () => getUser();

export const isVerified = async () => (await getUser())?.verified;

export const isAdmin = async () => (await getUser())?.admin;

export const isClubManager = async () => (await getUser())?.manager;

export const isTeamManager = async (teamId) => {
  const user = await getUser();

  return user.manager || user.coach.includes(teamId);
};

export const isPlayerManager = async (playerId) => {
  return !!playerId; //WIP
};

export const canAsync = async (rule) => {
  const [user, club] = await Promise.all([getUser(), getClub()]);

  return can({ club, user }, rule);
};
