<template>
  <q-item v-ripple="!!asiService" :clickable="!!asiService" class="q-layout-padding q-px-md" @click="connect2ASI">
    <q-item-section avatar style="min-width: auto">
      <insiders-icon dark />
    </q-item-section>
    <q-item-section v-if="asiService">
      <q-item-label>
        Insiders :
        <q-badge v-if="asiService.connected" transparent align="middle" color="positive">
          {{ $t("connected") }}
        </q-badge>
        <q-badge v-else transparent align="middle" color="negative">
          {{ $t("disconnected") }}
        </q-badge>
      </q-item-label>
      <q-item-label caption>{{ asiService.email }}</q-item-label>
    </q-item-section>
    <q-item-section v-else>
      <q-btn flat color="secondary" rounded label="Connect to ASI" @click="connect2ASI" />
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  props: {
    club: {
      type: Object,
      required: true,
    },
  },
  computed: {
    asiService() {
      return this.club?.services?.asi;
    },
  },
  methods: {
    connect2ASI() {
      const url = new URL(process.env.API);

      url.pathname = url.pathname + "connect/asi";

      url.searchParams.append("next", window.location.href);

      window.open(url, "_self");
    },
  },
};
</script>
