<template>
  <q-list padding>
    <q-item
      v-ripple
      :to="{ name: 'adminUsers' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="people" />
      </q-item-section>

      <q-item-section> {{ $t("users") }} </q-item-section>
    </q-item>

    <q-item
      v-ripple
      :to="{ name: 'adminSubscriptions' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="payment" />
      </q-item-section>

      <q-item-section> {{ $t("subscriptions") }} </q-item-section>
    </q-item>

    <q-item
      v-ripple
      :to="{ name: 'adminSystem' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="dns" />
      </q-item-section>

      <q-item-section> {{ $t("global.system") }} </q-item-section>
    </q-item>
  </q-list>
</template>

<script>
export default {
  props: {
    club: Object,
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
