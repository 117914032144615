<template>
  <q-table
    title="USB Devices"
    :rows="devices"
    :columns="columns"
    bordered
    flat
    :rows-per-page-options="[0]"
    :no-data-label="$t('upload.no_device')"
  >
    <template #top-right>
      <q-btn push rounded :color="color" :label="$t('device.usb.discover')" @click="onRequestDevice" />
    </template>
    <template #body-cell-action="props">
      <q-td :props="props">
        <div class="row q-gutter-sm justify-end">
          <q-btn push round :color="color" icon="download" dense @click="onDownload(props.row)" />
        </div>
      </q-td>
    </template>
  </q-table>
  <q-separator spaced inset />

  <DeviceUploadJobTable :jobs="jobs" />
</template>

<script>
import deviceMixin from "src/mixins/deviceMixin";
import { UPLOAD_DEVICE_FILE_MUTATION } from "src/graphql/deviceQueries";
import DeviceUploadJobTable from "src/components/device/DeviceUploadJobTable.vue";

export default {
  components: {
    DeviceUploadJobTable,
  },
  mixins: [deviceMixin],
  props: {
    color: {
      type: String,
      default: "secondary",
    },
  },
  data() {
    return {
      vendorId: 0x1915,
      devices: [],
      jobs: [],
      columns: [
        {
          name: "name",
          label: "Name",
          field: (row) => `${row.manufacturerName}:${row.productName}`,
          align: "left",
          sortable: true,
        },
        {
          name: "udid",
          label: "UDID",
          field: "udid",
          align: "left",
          sortable: true,
        },
        {
          name: "productId",
          label: "Serial Number",
          field: "productId",
          align: "left",
          sortable: true,
        },
        {
          name: "fwVersion",
          label: "Version",
          field: "fwVersion",
          align: "left",
          sortable: true,
        },
        {
          name: "action",
          label: "action",
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    const devices = await navigator.usb.getDevices();

    this.devices = await Promise.all(devices.map(this.onConnect));

    navigator.usb.addEventListener("connect", this.onDeviceConnectEvent);

    navigator.usb.addEventListener("disconnect", this.onDeviceDisconnectEvent);
  },
  beforeUnmount() {
    navigator.usb.removeEventListener("connect", this.onDeviceConnectEvent);

    navigator.usb.removeEventListener("disconnect", this.onDeviceDisconnectEvent);
  },
  methods: {
    async onDeviceConnectEvent(event) {
      await this.onConnect(event.device);

      this.devices.push(event.device);
    },
    async onDeviceDisconnectEvent(event) {
      this.devices = this.devices.filter((device) => device !== event.device);
    },

    async onDownload(device) {
      const dialog = this.$q.dialog({
        title: this.$t("import.usb.data_upload"),
        message: "",
        progress: { color: "secondary" },
        persistent: true,
        ok: false,
      });
      const fileName = `${device.udid}.asi`;
      let datas;
      let file;
      let buffer;

      // download data
      try {
        dialog.update({
          message: this.$t("import.usb.download_msg"),
        });

        datas = await this.getdeviceData(device);

        buffer = datas?.data?.buffer;

        if (!buffer || buffer.byteLength <= 512) {
          dialog.update({
            title: this.$t("import.usb.create_file_error"),
            message: this.$t("import.usb.no_data"),
            progress: false,
            ok: {
              rounded: true,
              unelevated: true,
            },
          });

          return;
        }
      } catch (error) {
        console.error(error);

        dialog.update({
          title: this.$t("import.usb.download_error"),
          message: JSON.stringify(error.message || error.msg || error),
          progress: false,
          ok: {
            rounded: true,
            unelevated: true,
          },
        });

        return;
      }

      // create file
      try {
        dialog.update({
          message: this.$t("import.usb.create_file_msg"),
        });

        file = new File([buffer], fileName);
      } catch (error) {
        dialog.update({
          title: this.$t("import.usb.create_file_error"),
          message: JSON.stringify(error),
          progress: false,
          ok: {
            rounded: true,
            unelevated: true,
          },
        });

        return;
      }

      // upload file
      try {
        dialog.update({
          message: this.$t("import.usb.file_upload_msg"),
        });

        const { data } = await this.$apollo.mutate({
          mutation: UPLOAD_DEVICE_FILE_MUTATION,
          variables: {
            deviceUDID: device.udid,
            file,
          },
        });
        const job = data.uploadDeviceFile;

        this.jobs.push(job);
      } catch (error) {
        console.error(error);

        dialog.update({
          title: this.$t("import.usb.file_upload_error"),
          message: JSON.stringify(error),
          progress: false,
          ok: {
            rounded: true,
            unelevated: true,
          },
        });

        return;
      }

      // erase data
      try {
        dialog.update({
          message: this.$t("import.usb.erase_msg"),
        });

        this.eraseDeviceData(device);
      } catch (error) {
        console.error(error);

        dialog.update({
          title: this.$t("import.usb.erase_error"),
          message: JSON.stringify(error),
          progress: false,
          ok: {
            rounded: true,
            unelevated: true,
          },
        });

        return;
      }

      dialog.update({
        title: this.$t("global.success"), // Success
        message: this.$t("import.usb.success_msg"),
        progress: false,
        ok: {
          rounded: true,
        },
      });
    },
    async onRequestDevice() {
      await navigator.usb.requestDevice({
        filters: [
          {
            vendorId: this.vendor,
          },
        ],
      });

      const devices = await navigator.usb.getDevices();

      this.devices = await Promise.all(devices.map(this.onConnect));
    },
    async onConnect(device) {
      await device.open();

      if (device.configuration === null) await device.selectConfiguration(1);

      await device.claimInterface(0);

      device.udid = await this.getDeviceUDID(device);

      device.fwVersion = await this.getDeviceVersion(device);

      return device;
    },
  },
};
</script>
