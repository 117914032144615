{
  "accel": "Beschleunigung",
  "accel.history": "Beschleunigungsdetail",
  "action": "Aktion",
  "actions": "Aktionen",
  "activity": "Aktivität",
  "analyses": "Analysen",
  "auto": "Auto",
  "average": "Durchschnitt",
  "cancel": "Stornieren",
  "charts": "Diagramme",
  "clipboard.copy_msg": "In die Zwischenablage kopiert !",
  "club.create": "Verein gründen",
  "club.create.msg": "Geben Sie den Namen des Clubs ein, den Sie erstellen möchten. \nSie werden der Eigentümer dieses Clubs sein.",
  "club.create.warning_msg": "Bei diesem Vorgang wird versucht, mit Ihrer E-Mail-Adresse ein Konto bei unserem Partnergeräteanbieter zu erstellen.",
  "club.member.add": "Mitglied hinzufügen",
  "club.member.add_msg": "Geben Sie die E-Mail-Adresse des Benutzers ein, den Sie hinzufügen möchten.",
  "club.member.edit_access": "Zugriff bearbeiten",
  "club.member.edit_access_msg": "Wählen Sie den Zugriff aus",
  "club.member.remove": "Mitglied entfernen",
  "club.member.remove_msg": "Sind Sie sicher, dass Sie dieses Mitglied entfernen möchten?",
  "club.members": "Clubmitglieder",
  "club.name": "Vereinsname",
  "club.subscription.active": "Aktives Abonnement",
  "club.subscription.not_active_msg": "Ihr Clubabonnement ist nicht aktiv. \nBitte kontaktieren Sie unser Team, um es zu aktivieren.",
  "coach": "Coach",
  "columns": "Säulen",
  "compare": "Vergleichen",
  "compute": "Berechnen",
  "compute.range.name": "Bereichsname",
  "compute.ranges.accel": "Beschleunigungsbereiche",
  "compute.ranges.speed": "Geschwindigkeitsbereiche",
  "compute.warning_msg": "Berechnen Sie Spieleraufzeichnungen basierend auf Sitzungen. \nKeine Panik, diese Aktion wird einige Zeit dauern!",
  "confirm": "Bestätigen",
  "connected": "In Verbindung gebracht",
  "connection": "Verbindung",
  "dashboard": "Armaturenbrett",
  "data": "Daten",
  "day": "Tag",
  "decel": "Verzögerung",
  "demo_mode": "Demo-Modus",
  "device.add": "Gerät hinzufügen",
  "device.add.enter_serial": "Geben Sie die Seriennummer des Geräts ein",
  "device.add.enter_serial_msg": "Die Seriennummer finden Sie auf der Rückseite des Geräts",
  "device.assign": "Zuordnen",
  "device.assign._confirm_msg": "Sind Sie sicher, dass Sie dieses Gerät diesem Player zuweisen möchten?",
  "device.externalId": "Partner-ID",
  "device.fwVersion": "Firmware Version",
  "device.hwVersion": "Hardware-Version",
  "device.serial": "Seriennummer",
  "device.unassign": "Zuweisung aufheben",
  "device.unassign.confirm_msg": "Sind Sie sicher, dass Sie die Zuweisung dieses Geräts aufheben möchten?",
  "device.usb.connect": "Gerät anschließen",
  "device.usb.discover": "Entdecken Sie USB-Geräte",
  "devices": "Geräte",
  "devices.sync": "Geräte synchronisieren",
  "disconnected": "Getrennt",
  "distance": "Distanz",
  "docking_station": "Dockingstation",
  "duration": "Dauer",
  "email": "Email",
  "endDate": "Endtermin",
  "error_codes.ASI_SERVICE_BULK_UPLOAD_INVALID_FILE": "ASI-Dienst-Massen-Upload ungültige Datei",
  "error_codes.ASI_SERVICE_DEVICE_ALREADY_OWENED": "ASI-Servicegerät bereits im Besitz",
  "error_codes.ASI_SERVICE_DEVICE_NOT_ALLOWED": "ASI-Dienstgerät nicht zulässig",
  "error_codes.ASI_SERVICE_DEVICE_NOT_FOUND": "ASI-Dienstgerät nicht gefunden",
  "error_codes.ASI_SERVICE_INVALID_CREDENTIALS": "Ungültige Anmeldeinformationen für den ASI-Dienst",
  "error_codes.ASI_SERVICE_MISSING_CREDENTIALS": "Für den ASI-Dienst fehlen Anmeldeinformationen",
  "error_codes.ASI_SERVICE_NOT_CONFIGURED": "ASI-Dienst nicht konfiguriert",
  "error_codes.ASI_SERVICE_REFRESH_FAILED": "Die Aktualisierung des ASI-Dienstes ist fehlgeschlagen",
  "error_codes.ASI_SERVICE_UNKNOW_ERROR": "ASI-Dienst unbekannter Fehler",
  "error_codes.ASI_SERVICE_USERNAME_MISMATCH": "Nicht übereinstimmender Benutzername des ASI-Dienstes",
  "error_codes.CANNOT_ASSIGN_DEVICE_TO_PLAYER": "Das Gerät kann dem Player nicht zugewiesen werden",
  "error_codes.CANNOT_DELETE_EVENT": "Ereignis kann nicht gelöscht werden",
  "error_codes.CANNOT_EDIT_MANAGER_ACCESS": "Der Managerzugriff kann nicht bearbeitet werden",
  "error_codes.CLUB_NOT_FOUND": "Verein nicht gefunden",
  "error_codes.CLUB_USER_NOT_FOUND": "Clubbenutzer nicht gefunden",
  "error_codes.DEVICE_ASSIGNED": "Gerät zugewiesen",
  "error_codes.DEVICE_NOT_FOUND": "Gerät nicht gefunden",
  "error_codes.DEVICE_TYPE_NOT_SUPPORTED": "Gerätetyp wird nicht unterstützt",
  "error_codes.EMAIL_ALREADY_EXISTS": "E-Mail existiert bereits",
  "error_codes.EMAIL_ALREADY_SENT": "E-Mail bereits gesendet",
  "error_codes.EVENT_ALREADY_EXISTS": "Veranstaltung existiert bereits",
  "error_codes.EVENT_COMPUTE_RUNNING": "Ereignisberechnung läuft",
  "error_codes.EVENT_FINISHED": "Veranstaltung beendet",
  "error_codes.EVENT_LOCKED": "Veranstaltung gesperrt",
  "error_codes.EVENT_NOT_FINISHED": "Veranstaltung nicht beendet",
  "error_codes.EVENT_NOT_FOUND": "Veranstaltung nicht gefunden",
  "error_codes.EVENT_NOT_STARTED": "Veranstaltung nicht gestartet",
  "error_codes.EVENT_STARTED": "Die Veranstaltung hat begonnen",
  "error_codes.INJURY_NOT_FOUND": "Verletzung nicht gefunden",
  "error_codes.INTERNAL_SERVER_ERROR": "Interner Serverfehler",
  "error_codes.INVALID_CREDENTIALS": "Ungültige Anmeldeinformationen",
  "error_codes.INVALID_TOKEN": "Ungültiges Token",
  "error_codes.MEMBER_LIMIT_REACHED": "Mitgliederlimit erreicht",
  "error_codes.NO_CLUB_FOR_USER": "Kein Club für Benutzer",
  "error_codes.NO_CURRENT_SEASON": "Keine aktuelle Saison",
  "error_codes.PERIODS_OVERLAP": "Perioden überschneiden sich",
  "error_codes.PERIOD_FINISHED": "Periode beendet",
  "error_codes.PERIOD_NOT_FOUND": "Zeitraum nicht gefunden",
  "error_codes.PERIOD_OVERLAP": "Periodenüberschneidung",
  "error_codes.PERIOD_STARTED": "Die Periode hat begonnen",
  "error_codes.PLAYER_HAS_NO_PICTURE": "Der Spieler hat kein Bild",
  "error_codes.PLAYER_HAS_TEAM": "Spieler hat Team",
  "error_codes.PLAYER_LIMIT_REACHED": "Spielerlimit erreicht",
  "error_codes.PLAYER_NOT_FOUND": "Spieler nicht gefunden",
  "error_codes.PLAYER_NOT_SELECTED": "Spieler nicht ausgewählt",
  "error_codes.PLAYER_SELECTED": "Spieler ausgewählt",
  "error_codes.RANGE_LIMIT_EXCEEDED": "Datenbereichsgrenze überschritten",
  "error_codes.RANGE_LIMIT_REACHED": "Datenbereichsgrenze erreicht",
  "error_codes.RANGE_NOT_FOUND": "Datenbereich nicht gefunden",
  "error_codes.RECORD_NOT_FOUND": "Aufnahme nicht gefunden",
  "error_codes.SEASON_NOT_FOUND": "Staffel nicht gefunden",
  "error_codes.SELECTION_NOT_FOUND": "Auswahl nicht gefunden",
  "error_codes.SERVER_UNREACHABLE": "Server nicht erreichbar",
  "error_codes.SESSION_NOT_FOUND": "Sitzung nicht gefunden",
  "error_codes.TEAM_LIMIT_REACHED": "Teamlimit erreicht",
  "error_codes.TEAM_NOT_FOUND": "Team nicht gefunden",
  "error_codes.UNAUTHORIZED": "Nicht autorisiert",
  "error_codes.UNEXPECTED_ERROR": "Unerwarteter Fehler",
  "error_codes.UNKNOWN_ERROR": "Unbekannter Fehler",
  "error_codes.USER_ALREADY_EXISTS": "Benutzer existiert bereits",
  "error_codes.USER_HAS_CLUB": "Benutzer hat Club",
  "error_codes.USER_NOT_FOUND": "Benutzer nicht gefunden",
  "error_codes.USER_NOT_VERIFIED": "Benutzer nicht verifiziert",
  "errors": "Fehler",
  "event.compute": "Rechenereignis",
  "event.create": "Veranstaltung erstellen",
  "event.creation": "Event-Erstellung",
  "event.export.download.fieldwiz_analyze_CSV": "Laden Sie FieldWiz Analyze CSV herunter",
  "event.export.download_report": "Bericht herunterladen",
  "event.export.empty_report": "Leerer Bericht",
  "event.export.reset_report": "Bericht zurücksetzen",
  "event.name": "Veranstaltungsname",
  "event.period.create": "Zeitraum erstellen",
  "event.period.create.force": "Erzwinge Sitzungen für alle Spieler in diesem Zeitraum",
  "event.period.new": "neue Periode",
  "event.period.start": "Beginn der Periode",
  "event.period.stop": "Abschaltzeitraum",
  "event.quick_import": "Schneller Import",
  "event.start": "Veranstaltung starten",
  "event.stop": "Ereignis stoppen",
  "event.type": "Ereignistyp",
  "event.type.INDIVIDUAL_MATCH": "individuelles Spiel",
  "event.type.INDIVIDUAL_TRAINING": "Individuelles Training",
  "event.type.MATCH": "übereinstimmen",
  "event.type.TRAINING": "Training",
  "exit": "ausgehen",
  "exit_confirm_msg": "Sind Sie sicher, dass Sie sich abmelden möchten?",
  "export": "Export",
  "friday": "Freitag",
  "global.assign": "Zuordnen",
  "global.assigned": "Zugewiesen",
  "global.back": "Zurück",
  "global.cancel": "Stornieren",
  "global.compute": "Berechnen",
  "global.contact_us": "Kontaktiere uns",
  "global.delete": "Löschen",
  "global.finish": "Beenden",
  "global.hybrid": "Hybrid",
  "global.live": "Live",
  "global.loading": "Wird geladen",
  "global.next": "Nächste",
  "global.offline": "Offline",
  "global.online": "Online",
  "global.options": "Optionen",
  "global.period": "Zeitraum",
  "global.scenario": "Szenario",
  "global.start": "Start",
  "global.stop": "Stoppen",
  "global.success": "Erfolg",
  "global.system": "System",
  "global.unassign": "Zuweisung aufheben",
  "global.view": "Sicht",
  "health": "Gesundheit",
  "heartRate": "Herzfrequenz",
  "heatmap": "HeatMap",
  "home": "Home",
  "hr": "HF",
  "import": "Importieren",
  "import.usb-dkg": "USB-/DKG-Import",
  "import.usb.close_modal": "Modal schließen",
  "import.usb.create_file_error": "Fehler beim Erstellen der Datei",
  "import.usb.create_file_msg": "Datei zum Hochladen wird erstellt...",
  "import.usb.data_upload": "Hochladen von Gerätedaten",
  "import.usb.detected_browser": "Erkannter Browser: {name}",
  "import.usb.download_device_data_msg": "Gerätedaten werden heruntergeladen ...",
  "import.usb.download_error": "Fehler beim Herunterladen der Daten vom Gerät",
  "import.usb.download_msg": "Daten vom Gerät herunterladen",
  "import.usb.erase_error": "Fehler beim Löschen der Daten vom Gerät",
  "import.usb.erase_msg": "Daten vom Gerät löschen",
  "import.usb.file_upload_error": "Fehler beim Hochladen der Datei",
  "import.usb.file_upload_msg": "Datei hochladen",
  "import.usb.job_error": "Gerätedatenimport fehlgeschlagen!",
  "import.usb.job_error_msg": "Der Import der Gerätedaten ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal.",
  "import.usb.job_state_updated_msg": "Status der Gerätedatenverarbeitung: {state}",
  "import.usb.job_success": "Gerätedatenimport abgeschlossen!",
  "import.usb.link_copy_success_msg": "URL in die Zwischenablage kopiert",
  "import.usb.no_data": "Keine Daten vom Gerät",
  "import.usb.processing_job": "Daten verarbeiten",
  "import.usb.success_msg": "Daten erfolgreich hochgeladen",
  "import.usb.unsupported_browser_msg": "Der USB-Upload ist im aktuellen Browser nicht verfügbar. Wir empfehlen die Verwendung des Google Chrome-Desktopbrowsers.",
  "import.usb.uploading_file_msg": "Gerätedatendatei wird hochgeladen ...",
  "intervals": "Intervalle",
  "lan.it-IT": "Italienisch",
  "lang.de-DE": "Deutsch",
  "lang.en-GB": "Englisch",
  "lang.en-US": "Englisch",
  "lang.es-ES": "Spanisch",
  "lang.fr-FR": "Französisch",
  "language": "Sprache",
  "league.ACADEMY": "Akademie",
  "league.INTERNATIONAL": "International",
  "league.PRO1": "Pro1",
  "league.PRO2": "Pro2",
  "light": "Lichtmodus",
  "list": "Aufführen",
  "live": "Live",
  "load]": "Belastung",
  "login": "Login",
  "login.caption": "Bitte einloggen zum Fortfahren",
  "manager": "Manager",
  "max": "Max",
  "monday": "Montag",
  "name": "Name",
  "night": "Nacht",
  "off": "Off",
  "offline": "Offline",
  "on": "An",
  "password": "Passwort",
  "password_reset.error": "Fehler beim Zurücksetzen des Passworts",
  "player.add": "Spieler hinzufügen",
  "player.birthdate": "Geburtsdatum",
  "player.delete": "{playerName} löschen",
  "player.delete.msg": "Sind Sie sicher, dass Sie {playerName} löschen möchten?",
  "player.firstName": "Vorname",
  "player.footRef": "Fuß",
  "player.footRef.LEFT": "Links",
  "player.footRef.RIGHT": "Rechts",
  "player.gender": "Geschlecht",
  "player.gender.MAN": "Mann",
  "player.gender.WOMAN": "Frau",
  "player.gps_profile": "GPS-Profil",
  "player.height": "Höhe",
  "player.hrMax": "HR Max",
  "player.lastName": "Nachname",
  "player.number": "Nummer",
  "player.position": "Position",
  "player.position.ASSISTANT_REFEREE": "Schiedsrichterassistent",
  "player.position.CENTER_BACK": "Innenverteidiger",
  "player.position.CENTRE_THREE_QUARTER": "Centre Three Quarter",
  "player.position.DEFENSIVE_MIDFIELDER": "Defensiver Mittelfeldspieler",
  "player.position.FLANKER": "Flanker",
  "player.position.FLY_HALF": "Fly half",
  "player.position.FORWARD": "Stürmer",
  "player.position.FULL_BACK": "Verteidiger",
  "player.position.GOALKEEPER": "Torwart",
  "player.position.HOOKER": "Hooker",
  "player.position.LOCK": "Lock",
  "player.position.MIDFIELDER": "Mittelfeldspieler",
  "player.position.OFFENSIVE_MIDFIELDER": "Offensiver Mittelfeldspieler",
  "player.position.PROP": "Prop",
  "player.position.REFEREE": "Schiedsrichter",
  "player.position.SCRUM_HALF": "Scrum half",
  "player.position.SIDE_MIDFIELDER": "Seitlicher Mittelfeldspieler",
  "player.position.THIRD_LINE": "Third line",
  "player.position.WINGER": "Flügelspieler",
  "player.profile": "Spielerprofil",
  "player.profile.AEROBIC": "Aerobic",
  "player.profile.INTERMEDIATE": "Dazwischenliegend",
  "player.profile.SPEED": "Geschwindigkeit",
  "player.speedMax": "MAXspeed",
  "player.status": "Status",
  "player.status.ABSENT": "Abwesend",
  "player.status.CARE": "Pflege",
  "player.status.FITNESS_REHAB": "Fitness-Rehab",
  "player.status.INJURED": "Verletzt",
  "player.status.PHYSIO_REHAB": "Physio-Rehab",
  "player.status.RECOVERED": "Geheilt",
  "player.status.RECOVERY": "Erholung",
  "player.status.SELECTION": "Auswahl",
  "player.status.SICK": "Krank",
  "player.status.VALID": "Gültig",
  "player.status.select": "Spielerstatus auswählen",
  "player.update": "Spieler aktualisieren",
  "player.weight": "Gewicht",
  "players": "Spieler",
  "profile": "Profil",
  "range.dates": "Bereichsdaten",
  "range.from": "Aus",
  "range.to": "Zu",
  "ratio": "Verhältnis",
  "record": "Aufzeichnen",
  "refresh": "Aktualisierung",
  "register": "Registrieren",
  "register.caption": "Ein neues Konto eröffnen",
  "report": "Bericht",
  "reset": "Zurücksetzen",
  "reset_password": "Passwort zurücksetzen",
  "reset_password.caption": "Setze dein Passwort zurück",
  "reset_password.error": "Fehler beim Zurücksetzen des Passworts",
  "reset_password.success": "E-Mail zum Zurücksetzen des Passworts gesendet",
  "rule.email": "Bitte geben Sie eine gültige Email-Adresse ein",
  "rule.password": "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten",
  "rule.required": "Dieses Feld ist erforderlich",
  "saturday": "Samstag",
  "search": "Suchen",
  "season": "Jahreszeit",
  "season.create": "Neue Season",
  "season.endDate": "Enddatum der Saison",
  "season.league": "Liga",
  "season.name": "Saisonname",
  "season.startDate": "Startdatum der Saison",
  "seasons": "Jahreszeiten",
  "selection": "Auswahl",
  "selection.slide_left_to_unselect": "Nach links schieben, um die Spielerauswahl aufzuheben",
  "selection.slide_right_to_select": "Schieben Sie nach rechts, um den Spieler auszuwählen",
  "selection.update": "Auswahl für {playerName} aktualisieren",
  "sessions": "Sitzungen",
  "settings": "Einstellungen",
  "speed": "Geschwindigkeit",
  "sprint": "Sprint",
  "sprint.history": "Sprintgeschichte",
  "sprints": "Sprints",
  "sprints.weekly_stats": "Wöchentliche Sprintstatistiken",
  "startDate": "Startdatum",
  "statistics": "Statistiken",
  "subscription": "Abonnement",
  "subscription.level": "Abonnementstufe",
  "subscription.limits.members": "Mitgliederlimit",
  "subscription.limits.players": "Spielerlimit",
  "subscription.limits.teams": "Teams-Limit",
  "subscription.plan": "Abonnementplan",
  "subscription.services": "Abonnementdienste",
  "subscriptions": "Abonnements",
  "sunday": "Sonntag",
  "team": "Team",
  "team.create": "Team erstellen",
  "team.delete": "Team löschen",
  "team.delete_keepPlayers": "Spieler behalten",
  "team.delete_keepPlayers_msg": "Möchten Sie die Spieler des Teams löschen?",
  "team.name": "Teamname",
  "team.players.assign": "Zuordnen zu {teamName}",
  "team.players.assign_confirm": "Sind Sie sicher, dass Sie {playerNames} {teamName} zuweisen möchten?",
  "team.players.unassign": "Zuweisung von {teamName} aufheben",
  "team.players.unassign_confirm": "Sind Sie sicher, dass Sie die Zuweisung von {playerNames} zu {teamName} aufheben möchten?",
  "team.update": "Update-Team",
  "teams": "Mannschaften",
  "thursday": "Donnerstag",
  "time": "Zeit",
  "timeline": "Zeitleiste",
  "tuesday": "Dienstag",
  "type": "Typ",
  "update": "Aktualisieren",
  "upload": "Hochladen",
  "upload.no_device": "Kein Gerät",
  "upload.no_jobs": "Keine Jobs",
  "usb": "USB",
  "user": "Benutzer",
  "user.copy_email": "E-Mail kopieren",
  "user.new": "Neuer Benutzer",
  "user.verification": "E-Mail-Verifizierung",
  "user.verification_msg": "Um Zugriff auf die App zu erhalten, müssen Sie Ihre E-Mail-Adresse bestätigen.",
  "users": "Benutzer",
  "verification_msg": "Um Ihr Konto zu verifizieren, klicken Sie bitte auf den Link, der Ihnen per E-Mail zugesandt wurde. \nWenn Sie die Verifizierung bereits durchgeführt haben, klicken Sie bitte auf die Schaltfläche unten, um auf die App zuzugreifen. \nWenn Sie die E-Mail nicht finden, überprüfen Sie bitte Ihren Spam-Ordner.",
  "verified": "Verifiziert",
  "verify": "Verifizieren",
  "version": "Ausführung",
  "wednesday": "Mittwoch",
  "week": "Woche",
  "welcome_msg": "Willkommen bei der Bewerbung! \nUm es zu nutzen, müssen Sie entweder einen neuen Club gründen oder einem bestehenden Club beitreten, indem Sie einen Clubmanager bitten, Ihre E-Mail-Adresse hinzuzufügen.",
  "workload": "Arbeitsbelastung"
}
