import gql from "graphql-tag";
import { paginationFragment } from "./baseQueries";
import { recordFragment } from "./recordQueries";
import { sessionFragment } from "./sessionQueries";
import { periodFragment, intervalFragment } from "./periodQueries";
import { rangeFragment } from "./rangeQueries";

export const selectionFragment = gql`
  fragment selectionFragment on Selection {
    id
    teamId
    playerId
    player {
      id
      name
      firstName
      lastName
      picture
      deleted
    }
    number
    position
    profile
    deviceId
    device {
      id
      name
      deleted
    }
    selected
  }
`;

export const EVENT_QUERY = gql`
  query event($eventId: ID!) {
    event(eventId: $eventId) {
      id
      locked
      teamId
      name
      number
      type
      selection {
        ...selectionFragment
      }
      periods {
        ...periodFragment
      }
      live
      finished
      startDate
      endDate
      place
      theme
      competition
      score {
        team
        adv
      }
      daysToMatch
      matchSystem
      training
      ranges {
        speed {
          ...rangeFragment
        }
        accel {
          ...rangeFragment
        }
      }
    }
  }
  ${rangeFragment}
  ${selectionFragment}
  ${periodFragment}
`;

export const EVENT_SPEED_SAMPLES_QUERY = gql`
  query eventSpeedSamples($eventId: ID!) {
    event(eventId: $eventId) {
      id
      speedSamples {
        data
        players
      }
    }
  }
`;

export const EVENT_INTERVALS_AND_SESSIONS_QUERY = gql`
  query eventIntervalsAndSessions($eventId: ID!) {
    event(eventId: $eventId) {
      id
      intervals {
        ...intervalFragment
      }
      sessions {
        ...sessionFragment
      }
    }
  }
  ${intervalFragment}
  ${sessionFragment}
`;

export const EVENT_RECORDS_QUERY = gql`
  query eventRecords($eventId: ID!) {
    event(eventId: $eventId) {
      id
      records {
        ...recordFragment
      }
    }
  }
  ${recordFragment}
`;

export const CREATE_EVENT_MUTATION = gql`
  mutation createEvent(
    $teamId: ID!
    $name: String!
    $type: EventType
    $startDate: DateTime
    $endDate: DateTime
    $selection: [ID]
  ) {
    createEvent(
      teamId: $teamId
      event: { name: $name, type: $type }
      startDate: $startDate
      endDate: $endDate
      playerIds: $selection
    ) {
      id
      name
      number
      teamId
      type
      selection {
        ...selectionFragment
      }
      periods {
        ...periodFragment
      }
      live
      finished
      startDate
      endDate
      place
      theme
      competition
      score {
        team
        adv
      }
      daysToMatch
      matchSystem
    }
  }
  ${periodFragment}
  ${selectionFragment}
`;

export const EVENT_UPDATED_SUB = gql`
  subscription eventUpdated($eventId: ID!) {
    eventUpdated(eventId: $eventId) {
      id
      name
      live
      finished
      startDate
      endDate
      periods {
        ...periodFragment
      }
    }
  }
  ${periodFragment}
`;

export const START_EVENT_MUTATION = gql`
  mutation startEvent($eventId: ID!) {
    startEvent(eventId: $eventId) {
      id
      live
      startDate
    }
  }
`;

export const STOP_EVENT_MUTATION = gql`
  mutation stopEvent($eventId: ID!) {
    stopEvent(eventId: $eventId) {
      id
      live
      finished
      endDate
    }
  }
`;

export const CREATE_PERIOD_MUTATION = gql`
  mutation createPeriod($eventId: ID!, $name: String, $startDate: DateTime, $endDate: DateTime, $force: Boolean) {
    createPeriod(eventId: $eventId, name: $name, startDate: $startDate, endDate: $endDate, force: $force) {
      id
      periods {
        ...periodFragment
      }
      intervals {
        ...intervalFragment
      }
      sessions {
        ...sessionFragment
      }
    }
  }
  ${intervalFragment}
  ${sessionFragment}
  ${periodFragment}
`;

export const REMOVE_PERIOD_MUTATION = gql`
  mutation removePeriod($eventId: ID!, $periodId: ID!) {
    removePeriod(eventId: $eventId, periodId: $periodId) {
      id
      periods {
        ...periodFragment
      }
      intervals {
        ...intervalFragment
      }
    }
  }
  ${intervalFragment}
  ${periodFragment}
`;

export const START_PERIOD_MUTATION = gql`
  mutation startPeriod($eventId: ID!, $periodId: ID!) {
    startPeriod(eventId: $eventId, periodId: $periodId) {
      id
      periods {
        ...periodFragment
      }
    }
  }
  ${periodFragment}
`;

export const STOP_PERIOD_MUTATION = gql`
  mutation stopPeriod($eventId: ID!, $periodId: ID!) {
    stopPeriod(eventId: $eventId, periodId: $periodId) {
      id
      periods {
        ...periodFragment
      }
    }
  }
  ${periodFragment}
`;

export const SELECT_PLAYER_MUTATION = gql`
  mutation selectPlayer($eventId: ID!, $playerId: ID!) {
    selectPlayer(eventId: $eventId, playerId: $playerId) {
      id
      playerId
      selected
    }
  }
`;

export const UNSELECT_PLAYER_MUTATION = gql`
  mutation unselectPlayer($eventId: ID!, $playerId: ID!) {
    unselectPlayer(eventId: $eventId, playerId: $playerId) {
      id
      playerId
      selected
    }
  }
`;

export const DELETE_EVENT_MUTATION = gql`
  mutation deleteEvent($eventId: ID!) {
    deleteEvent(eventId: $eventId)
  }
`;

export const TEAM_EVENTS_QUERY = gql`
  query teamEvents($teamId: ID!, $seasonId: ID, $filter: String, $type: String, $limit: Int, $page: Int) {
    team(teamId: $teamId) {
      id
      events(seasonId: $seasonId, type: $type, filter: $filter, pagination: { limit: $limit, page: $page }) {
        docs {
          id
          name
          live
          startDate
          endDate
          type
          number
        }
        pagination {
          ...paginationFragment
        }
      }
    }
  }
  ${paginationFragment}
`;

export const PLAYER_EVENTS_QUERY = gql`
  query playerEvents($playerId: ID, $limit: Int, $page: Int) {
    player(playerId: $playerId) {
      id
      events(limit: $limit, page: $page) {
        id
        name
        startDate
        type
      }
    }
  }
`;

export const PLAYER_EVENT_SEARCH_QUERY = gql`
  query playerEventSearch($playerId: ID!, $search: String) {
    playerEventSearch(playerId: $playerId, search: $search) {
      id
      name
      startDate
      type
    }
  }
`;

export const UPLOAD_EVENT_FILE = gql`
  mutation uploadEventFile($eventId: ID!, $file: File!, $periodId: ID, $deviceType: DeviceType!) {
    uploadEventFile(eventId: $eventId, file: $file, periodId: $periodId, deviceType: $deviceType) {
      ...recordFragment
    }
  }
  ${recordFragment}
`;

export const COMPUTE_EVENT_RECORDS = gql`
  mutation computeEventRecords($eventId: ID!) {
    computeEventRecords(eventId: $eventId) {
      ...recordFragment
    }
  }
  ${recordFragment}
`;

export const UPDATE_EVENT_MUTATION = gql`
  mutation updateEvent($eventId: ID!, $event: EventInput) {
    updateEvent(eventId: $eventId, event: $event) {
      id
      startDate
      endDate
      place
      theme
      competition
      score {
        team
        adv
      }
      daysToMatch
      matchSystem
    }
  }
`;

export const UPDATE_EVENT_DATES_MUTATION = gql`
  mutation updateEventDates($eventId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
    updateEventDates(eventId: $eventId, startDate: $startDate, endDate: $endDate) {
      id
      startDate
      endDate
      live
      finished
      periods {
        ...periodFragment
      }
      intervals {
        ...intervalFragment
      }
      sessions {
        ...sessionFragment
      }
    }
  }
  ${intervalFragment}
  ${sessionFragment}
  ${periodFragment}
`;

export const SET_EVENT_SELECTION_MUTATION = gql`
  mutation setEventSelection($eventId: ID!, $playerIds: [ID]) {
    setEventSelection(eventId: $eventId, playerIds: $playerIds) {
      ...selectionFragment
    }
  }
  ${selectionFragment}
`;

export const DELETE_SELECTION_MUTATION = gql`
  mutation deleteSelection($eventId: ID!, $playerId: ID!) {
    deleteSelection(eventId: $eventId, playerId: $playerId)
  }
`;

export const DOWNLOAD_EVENT_ARCHIVE_QUERY = gql`
  query getEventRawDataArchiveLink($eventId: ID!) {
    getEventRawDataArchiveLink(eventId: $eventId)
  }
`;

export const DOWNLOAD_EVENT_CSV_QUERY = gql`
  query getEventDataCSVLink($eventId: ID!) {
    getEventDataCSVLink(eventId: $eventId)
  }
`;

export const DOWNLOAD_EVENT_XLSX_QUERY = gql`
  query getEventDataXLSXLink($eventId: ID!) {
    getEventDataXLSXLink(eventId: $eventId)
  }
`;

export const UPDATE_SELECTION_MUTATION = gql`
  mutation updateSelection($eventId: ID!, $playerId: ID!, $selection: SelectionInput!) {
    updateSelection(eventId: $eventId, playerId: $playerId, selection: $selection) {
      ...selectionFragment
    }
  }
  ${selectionFragment}
`;
