import gql from "graphql-tag";

export const deviceFragment = gql`
  fragment deviceFragment on Device {
    id
    teamId
    playerId
    externalId
    name
    type
    serial
    udid
    fwVersion
    hwVersion
  }
`;

export const jobFragment = gql`
  fragment jobFragment on Job {
    id
    jobId
    state
    details
    device {
      ...deviceFragment
    }
  }
  ${deviceFragment}
`;

export const DEVICE_TYPES_QUERY = gql`
  query deviceTypes {
    deviceTypes
  }
`;

export const CLUB_DEVICES_QUERY = gql`
  query clubDevices {
    club {
      id
      devices {
        ...deviceFragment
      }
    }
  }
  ${deviceFragment}
`;

export const TEAM_DEVICES_QUERY = gql`
  query teamDevices($teamId: ID!) {
    team(teamId: $teamId) {
      id
      devices {
        ...deviceFragment
      }
    }
  }
  ${deviceFragment}
`;

export const ASSIGN_DEVICE_TO_PLAYER_MUTATION = gql`
  mutation assignDeviceToPlayer($playerId: ID!, $deviceId: ID!) {
    assignDeviceToPlayer(playerId: $playerId, deviceId: $deviceId) {
      ...deviceFragment
    }
  }
  ${deviceFragment}
`;

export const UNASSIGN_DEVICE_FROM_PLAYER_MUTATION = gql`
  mutation unassignDeviceFromPlayer($playerId: ID!) {
    unassignDeviceFromPlayer(playerId: $playerId) {
      ...deviceFragment
    }
  }
  ${deviceFragment}
`;

export const ASSIGN_TEAM_DEVICES_MUTATION = gql`
  mutation assignDevicesToTeam($teamId: ID!, $deviceIds: [ID]) {
    assignDevicesToTeam(teamId: $teamId, deviceIds: $deviceIds) {
      ...deviceFragment
    }
  }
  ${deviceFragment}
`;

export const UNASSIGN_DEVICES_MUTATION = gql`
  mutation unassignDevices($deviceIds: [ID]) {
    unassignDevices(deviceIds: $deviceIds) {
      ...deviceFragment
    }
  }
  ${deviceFragment}
`;

export const SYNC_DEVICES_MUTATION = gql`
  mutation syncDevices($clubId: ID!) {
    syncDevices(clubId: $clubId) {
      ...deviceFragment
    }
  }
  ${deviceFragment}
`;

export const UPLOAD_DEVICE_FILE_MUTATION = gql`
  mutation uploadDeviceFile($deviceUDID: ID!, $file: File!) {
    uploadDeviceFile(deviceUDID: $deviceUDID, file: $file) {
      ...jobFragment
    }
  }
  ${jobFragment}
`;

export const UPLOAD_DOCK_FILE_MUTATION = gql`
  mutation uploadDockFile($file: File!) {
    uploadDockFile(file: $file) {
      ...jobFragment
    }
  }
  ${jobFragment}
`;

export const JOB_STATE_UPDATED_SUB = gql`
  subscription jobStateUpdated($jobId: String!) {
    jobStateUpdated(jobId: $jobId) {
      ...jobFragment
    }
  }
  ${jobFragment}
`;

export const ADD_DEVICE_MUTATION = gql`
  mutation addDevice($clubId: ID!, $serial: String!, $udid: String!) {
    addDevice(clubId: $clubId, serial: $serial, udid: $udid) {
      ...deviceFragment
    }
  }
  ${deviceFragment}
`;
