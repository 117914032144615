<template>
  <q-list padding>
    <!-- <q-item
      :to="{ name: 'clubDashboard' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"      v-ripple
    >
      <q-item-section avatar>
        <q-icon name="location_city" />
      </q-item-section>

      <q-item-section> Club </q-item-section>
    </q-item> -->

    <q-item
      v-ripple
      :to="{ name: 'clubTeams' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="groups" />
      </q-item-section>

      <q-item-section> {{ $t("teams") }} </q-item-section>
    </q-item>

    <q-item
      v-if="user.manager"
      v-ripple
      :to="{ name: 'clubPlayers' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="directions_run" />
      </q-item-section>

      <q-item-section> {{ $t("players") }} </q-item-section>
    </q-item>

    <q-item
      v-if="user.manager"
      v-ripple
      :to="{ name: 'clubDevices' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="gps_fixed" />
      </q-item-section>

      <q-item-section> {{ $t("devices") }} </q-item-section>
    </q-item>
  </q-list>

  <q-space></q-space>
  <q-separator />
  <q-list padding>
    <q-item
      v-if="can('club:members:any')()"
      v-ripple
      :to="{ name: 'clubMembers' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="manage_accounts" />
      </q-item-section>

      <q-item-section> Members </q-item-section>
    </q-item>

    <q-item v-if="user.manager" v-ripple class="q-layout-padding q-px-md" active-class="text-secondary" disable>
      <q-item-section avatar>
        <q-icon name="payment" />
      </q-item-section>

      <q-item-section>
        {{ $t("subscription") }}
        <q-item-label caption> {{ club?.subscription?.plan }} </q-item-label>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script>
export default {
  props: {
    club: Object,
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
