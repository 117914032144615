<template>
  <q-list padding>
    <q-item
      v-if="can('team:analyses:read')()"
      v-ripple
      :to="{ name: 'teamAnalyses' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="insights" />
      </q-item-section>

      <q-item-section> {{ $t("analyses") }} </q-item-section>
    </q-item>

    <q-item
      v-ripple
      :to="{ name: 'teamPlayers' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="people" />
      </q-item-section>

      <q-item-section> {{ $t("players") }} </q-item-section>
    </q-item>

    <q-item
      v-ripple
      :to="{ name: 'teamEvents' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="sports_score" />
      </q-item-section>

      <q-item-section> {{ $t("sessions") }} </q-item-section>
    </q-item>

    <!-- <q-item
      v-if="can('player:health:any')()"
      v-ripple
      :to="{ name: 'teamHealth' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="health_and_safety" />
      </q-item-section>

      <q-item-section> {{ $t("health") }} </q-item-section>
    </q-item> -->

    <q-item
      v-if="can('player:compare:any')()"
      v-ripple
      :to="{
        name: 'teamPlayerCompare',
      }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="compare_arrows" />
      </q-item-section>

      <q-item-section> {{ $t("compare") }} </q-item-section>
    </q-item>
    <q-separator spaced />

    <q-item v-if="team?.season" class="q-layout-padding q-px-md">
      <q-item-section v-if="mini" avatar>
        <q-btn round push color="secondary" icon="add" @click="onCreateEventBtn" />
      </q-item-section>
      <q-item-section>
        <q-btn rounded push color="secondary" :label="$t('event.create')" @click="onCreateEventBtn"> </q-btn>
      </q-item-section>
      <q-dialog v-model="eventCreationModal" position="top" full-width>
        <q-card>
          <CreateEventStepper :club="club" @created="onCreated" />
        </q-card>
      </q-dialog>
    </q-item>
    <q-item class="q-layout-padding q-px-md q-pt-none">
      <q-item-section v-if="mini" avatar>
        <DeviceUSBImportBtn round push icon="upload" label="" />
      </q-item-section>
      <q-item-section>
        <DeviceUSBImportBtn />
      </q-item-section>
    </q-item>
  </q-list>
  <q-space></q-space>
  <q-separator />
  <q-list padding>
    <q-item
      v-ripple
      :to="{ name: 'teamDevices' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="gps_fixed" />
      </q-item-section>

      <q-item-section> {{ $t("devices") }} </q-item-section>
    </q-item>

    <q-item
      v-ripple
      :to="{ name: 'teamSettings' }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="tune" />
      </q-item-section>

      <q-item-section> {{ $t("settings") }} </q-item-section>
    </q-item>
  </q-list>
</template>
<script>
import CreateEventStepper from "../event/CreateEventStepper.vue";
import DeviceUSBImportBtn from "src/components/device/DeviceUSBImportBtn.vue";
import { TEAM_QUERY } from "src/graphql/teamQueries";

export default {
  components: {
    CreateEventStepper,
    DeviceUSBImportBtn,
  },
  props: {
    club: {
      type: Object,
      required: true,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      eventCreationModal: false,
      tab: "usb",
    };
  },

  methods: {
    onCreateEventBtn() {
      this.eventCreationModal = true;
    },
    onCreated(event) {
      this.eventCreationModal = false;

      this.$router.push({
        name: "eventDashboard",
        params: { eventId: event.id },
        query: {
          quickImport: !!event.endDate,
        },
      });
    },
  },
  apollo: {
    team: {
      query: TEAM_QUERY,
      variables() {
        return {
          teamId: this.$route.params.teamId,
        };
      },
      skip() {
        return !this.$route.params.teamId;
      },
    },
  },
};
</script>
