<template>
  <q-uploader
    ref="uploader"
    :label="$t('docking_station')"
    :color="color"
    flat
    bordered
    class="fit"
    :factory="uploadFile"
    :max-files="1"
    accept=".dkg, .zip"
  />
  <q-separator spaced inset />

  <DeviceUploadJobTable :jobs="jobs" />
</template>
<script>
import { UPLOAD_DOCK_FILE_MUTATION } from "src/graphql/deviceQueries.js";
import DeviceUploadJobTable from "src/components/device/DeviceUploadJobTable.vue";

export default {
  components: {
    DeviceUploadJobTable,
  },
  props: {
    color: {
      type: String,
      default: "secondary",
    },
  },
  data() {
    return {
      jobs: [],
    };
  },
  methods: {
    async uploadFile([file]) {
      const { data } = await this.$apollo.mutate({
        mutation: UPLOAD_DOCK_FILE_MUTATION,
        variables: {
          file,
        },
      });

      this.jobs.push(...data.uploadDockFile);

      this.$refs.uploader.removeFile(file);

      return true;
    },
  },
};
</script>
