const USB_ACK = 0x0a;
// const USB_NACK = 0x05;
const USB_CMD_POD_GET_ID = 0x11;
// const USB_CMD_POD_DFU = 0x12;
const USB_CMD_POD_GET_VER = 0x13;
const USB_CMD_DATA_READ = 0x31;
const USB_CMD_ERASE_DATA = 0x41;

export default {
  data() {
    return {
      vendorId: 0x1915,
    };
  },
  methods: {
    async eraseDeviceData(device) {
      let buff = new Uint8Array(1);
      let ack;

      if (device === null) return;

      buff[0] = USB_CMD_ERASE_DATA;

      await device.transferOut(1, buff);

      ack = await device.transferIn(1, 1);

      if (ack.data.getUint8(0) !== USB_ACK) {
        throw new Error("Erase error");
      }
    },
    async getDeviceUDID(device) {
      if (!device) return;

      try {
        /*----variable required----*/
        let cmdID = new Uint8Array(1).fill(USB_CMD_POD_GET_ID); //create an array and store the cmd ID.
        let udidBuff;
        let ack; //will be used to receive the acknowledge
        /*-------------------------*/

        await device.transferOut(1, cmdID);

        ack = await device.transferIn(1, 1);

        if (ack.data.getUint8(0) !== USB_ACK) return;

        udidBuff = await device.transferIn(1, 8);

        var str = new String("");

        for (let i = 0; i < 8; i++) {
          var myNumber = udidBuff.data.getUint8(i);
          var myNumberAsSTR = myNumber.toString(16);

          var formattedNumber = ("0" + myNumberAsSTR).slice(-2);

          str = str + formattedNumber;
        }

        return str; // return success
      } catch {
        return null;
      }
    },
    async getDeviceVersion(device) {
      if (!device) return; //stop if no device connected

      try {
        /*----variable required----*/
        let cmdID = new Uint8Array(1).fill(USB_CMD_POD_GET_VER); //create an array and store the cmd ID.
        let fversion;
        let bversion;
        let Sversion;
        let ack; //will be used to receive the acknowledge
        /*-------------------------*/

        await device.transferOut(1, cmdID);

        ack = await device.transferIn(1, 1);

        if (ack.data.getUint8(0) !== USB_ACK) return;

        fversion = await device.transferIn(1, 8);

        // eslint-disable-next-line no-unused-vars
        bversion = await device.transferIn(1, 8);

        // eslint-disable-next-line no-unused-vars
        Sversion = await device.transferIn(1, 8);

        var str = new String("");

        str = str + fversion.data.getUint8(0).toString(16);

        str = str + ".";

        str = str + fversion.data.getUint8(1).toString(16);

        str = str + ".";

        str = str + fversion.data.getUint8(2).toString(16);

        return str; // return success
      } catch {
        return null;
      }
    },
    async getdeviceData(device) {
      if (!device) return; //stop if no device connected

      try {
        /*----variable required----*/
        let cmdID = new Uint8Array(1).fill(USB_CMD_DATA_READ); //create an array and store the cmd ID.
        let dataSize;
        let data;
        let has256;
        let ack; //will be used to receive the acknowledge
        /*-------------------------*/

        /*-----------------cmd start--------------------*/
        await device.transferOut(1, cmdID); //send the cmd ID and wait the end of the transfer

        ack = await device.transferIn(1, 1); //wait the ack of the pod

        if (ack.data.getUint8(0) !== USB_ACK)
          //verify the ack
          return;

        /*-----------------get data---------------------*/
        let dataSizeTmp = await device.transferIn(1, 4); //get size

        dataSize = dataSizeTmp.data.getUint32(0);

        // statusDl.innerText = "Estimated time: " + String(dataSize / 240000) + "s";

        if (dataSize !== 0) {
          data = await device.transferIn(1, dataSize);

          // eslint-disable-next-line no-unused-vars
          has256 = await device.transferIn(1, 32);
        }

        return data; // return success
      } catch {
        return null;
      }
    },
    // async sendCfgParameter(device, param) {
    //   if (device === null) return; //stop if no device connected

    //   /*----variable required----*/
    //   let cmdID = new Uint8Array(1).fill(USB_CMD_CONFIG_WRITE); //create an array and store the cmd ID.
    //   let pLength = new Uint8Array(1).fill(param.length); //param length cropped to stay in uint8 range
    //   let paramUTF8 = new TextEncoder(100).encode(param); //convert and store the parm in utf8 format
    //   let ack; //will be used to receive the acknowledge
    //   /*-------------------------*/

    //   /*-----------------cmd start--------------------*/
    //   await device.transferOut(1, cmdID); //send the cmd ID and wait the end of the transfer

    //   ack = await device.transferIn(1, 1); //wait the ack of the pod

    //   if (ack.data.getUint8(0) !== USB_ACK)
    //     //verify the ack
    //     return;

    //   /*-----------------key validation---------------*/
    //   await device.transferOut(1, pLength); //send the size of the key

    //   await device.transferOut(1, paramUTF8); //send the converted key

    //   ack = await device.transferIn(1, 1); //wait the ack of the pod

    //   if (ack.data.getUint8(0) !== USB_ACK)
    //     //verify the ack
    //     return;

    //   return 1; // return success
    // },
    // async requestCfgParameter(device, Key) {
    //   if (device === null) return new String(""); //stop if no device connected

    //   /*----variable required----*/
    //   let cmdID = new Uint8Array(1).fill(USB_CMD_CONFIG_READ); //create an array and store the cmd ID.
    //   let keyLength = new Uint8Array(1).fill(Key.length); //key length cropped to stay in uint8 range
    //   let keyUTF8 = new TextEncoder(30).encode(Key); //convert and store the key in utf8 format
    //   let ack; //will be used to receive the acknowledge
    //   let valueLength; //will receive the length of the value
    //   let value; //will receive the parameter value
    //   /*-------------------------*/

    //   /*-----------------cmd start--------------------*/
    //   await device.transferOut(1, cmdID); //send the cmd ID and wait the end of the transfer

    //   ack = await device.transferIn(1, 1); //wait the ack of the pod

    //   if (ack.data.getUint8(0) !== USB_ACK)
    //     //verify the ack
    //     return new String("");

    //   /*-----------------key validation---------------*/
    //   await device.transferOut(1, keyLength); //send the size of the key

    //   await device.transferOut(1, keyUTF8); //send the converted key

    //   ack = await device.transferIn(1, 1); //wait the ack of the pod

    //   if (ack.data.getUint8(0) !== USB_ACK)
    //     //verify the ack
    //     return new String("");

    //   /*-----------------return value-----------------*/
    //   valueLength = await device.transferIn(1, 1); //wait the size of the data

    //   valueLength = valueLength.data.getUint8(0); //get the length from the array

    //   if (valueLength)
    //     // check if data are not empty
    //     value = await device.transferIn(1, valueLength); //wait the data
    //   else return new String("");

    //   return new TextDecoder("utf-8").decode(value.data); //convert to a string and return the result
    // },
  },
};
