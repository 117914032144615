{
  "accel": "Accel",
  "accel.history": "Accel history",
  "action": "Action",
  "actions": "Actions",
  "activity": "Activity",
  "analyses": "Analyses",
  "auto": "Auto",
  "average": "Average",
  "cancel": "Cancel",
  "charts": "Charts",
  "clipboard.copy_msg": "Copied to clipboard !",
  "club.create": "Create club",
  "club.create.msg": "Enter the name of the club you want to create. You will be the owner of this club.",
  "club.create.warning_msg": "This process will try to create an account with your email in our partner device provider.",
  "club.member.add": "Add member",
  "club.member.add_msg": "Enter the email of the user you want to add.",
  "club.member.edit_access": "Edit Access",
  "club.member.edit_access_msg": "Select access",
  "club.member.remove": "Remove member",
  "club.member.remove_msg": "Are you sure you want to remove this member ?",
  "club.members": "Club members",
  "club.name": "Club name",
  "club.subscription.active": "Active subscription",
  "club.subscription.not_active_msg": "Your club subscription is not active. Please contact our team to activate it.",
  "coach": "Coach",
  "columns": "Columns",
  "compare": "Compare",
  "compute": "Compute",
  "compute.range.name": "Range name",
  "compute.ranges.accel": "Accel Ranges",
  "compute.ranges.speed": "Speed Ranges",
  "compute.warning_msg": "Compute players records based on sessions. Don't panic this action               will take some time !",
  "confirm": "Comfirm",
  "connected": "Connected",
  "connection": "Connection",
  "dashboard": "Dashboard",
  "data": "Data",
  "day": "Day",
  "decel": "Decel",
  "demo_mode": "Demo mode",
  "device.add": "Add Device",
  "device.add.enter_serial": "Enter device serial number",
  "device.add.enter_serial_msg": "You can find the serial number on the back of the device",
  "device.assign": "Assign",
  "device.assign._confirm_msg": "Are you sure you want to assign this device to this player ?",
  "device.externalId": "Partner ID",
  "device.fwVersion": "Firmware version",
  "device.hwVersion": "Harware version",
  "device.serial": "Serial Number",
  "device.unassign": "Unassign",
  "device.unassign.confirm_msg": "Are you sure you want to unassign this device ?",
  "device.usb.connect": "Connect device",
  "device.usb.discover": "Discover USB devices",
  "devices": "Devices",
  "devices.sync": "Sync devices",
  "disconnected": "Disconnected",
  "distance": "Distance",
  "docking_station": "Docking station",
  "duration": "Duration",
  "email": "Email",
  "endDate": "EndDate",
  "error_codes.ASI_SERVICE_BULK_UPLOAD_INVALID_FILE": "ASI service bulk upload invalid file",
  "error_codes.ASI_SERVICE_DEVICE_ALREADY_OWENED": "ASI service device already owened",
  "error_codes.ASI_SERVICE_DEVICE_NOT_ALLOWED": "ASI service device not allowed",
  "error_codes.ASI_SERVICE_DEVICE_NOT_FOUND": "ASI service device not found",
  "error_codes.ASI_SERVICE_INVALID_CREDENTIALS": "ASI service invalid credentials",
  "error_codes.ASI_SERVICE_MISSING_CREDENTIALS": "ASI service missing credentials",
  "error_codes.ASI_SERVICE_NOT_CONFIGURED": "ASI service not configured",
  "error_codes.ASI_SERVICE_REFRESH_FAILED": "ASI service refresh failed",
  "error_codes.ASI_SERVICE_UNKNOW_ERROR": "ASI service unknow error",
  "error_codes.ASI_SERVICE_USERNAME_MISMATCH": "ASI service username mismatch",
  "error_codes.CANNOT_ASSIGN_DEVICE_TO_PLAYER": "Cannot assign device to player",
  "error_codes.CANNOT_DELETE_EVENT": "Cannot delete event",
  "error_codes.CANNOT_EDIT_MANAGER_ACCESS": "Cannot edit manager access",
  "error_codes.CLUB_NOT_FOUND": "Club not found",
  "error_codes.CLUB_USER_NOT_FOUND": "Club user not found",
  "error_codes.DEVICE_ASSIGNED": "Device assigned",
  "error_codes.DEVICE_NOT_FOUND": "Device not found",
  "error_codes.DEVICE_TYPE_NOT_SUPPORTED": "Device type not supported",
  "error_codes.EMAIL_ALREADY_EXISTS": "Email already exists",
  "error_codes.EMAIL_ALREADY_SENT": "Email already sent",
  "error_codes.EVENT_ALREADY_EXISTS": "Event already exists",
  "error_codes.EVENT_COMPUTE_RUNNING": "Event compute running",
  "error_codes.EVENT_FINISHED": "Event finished",
  "error_codes.EVENT_LOCKED": "Event locked",
  "error_codes.EVENT_NOT_FINISHED": "Event not finished",
  "error_codes.EVENT_NOT_FOUND": "Event not found",
  "error_codes.EVENT_NOT_STARTED": "Event not started",
  "error_codes.EVENT_STARTED": "Event started",
  "error_codes.INJURY_NOT_FOUND": "Injury not found",
  "error_codes.INTERNAL_SERVER_ERROR": "Internal server error",
  "error_codes.INVALID_CREDENTIALS": "Invalid credentials",
  "error_codes.INVALID_TOKEN": "Invalid token",
  "error_codes.MEMBER_LIMIT_REACHED": "Member limit reached",
  "error_codes.NO_CLUB_FOR_USER": "No club for user",
  "error_codes.NO_CURRENT_SEASON": "No current season",
  "error_codes.PERIODS_OVERLAP": "Periods overlap",
  "error_codes.PERIOD_FINISHED": "Period finished",
  "error_codes.PERIOD_NOT_FOUND": "Period not found",
  "error_codes.PERIOD_OVERLAP": "Period overlap",
  "error_codes.PERIOD_STARTED": "Period started",
  "error_codes.PLAYER_HAS_NO_PICTURE": "Player has no picture",
  "error_codes.PLAYER_HAS_TEAM": "Player has team",
  "error_codes.PLAYER_LIMIT_REACHED": "Player limit reached",
  "error_codes.PLAYER_NOT_FOUND": "Player not found",
  "error_codes.PLAYER_NOT_SELECTED": "Player not selected",
  "error_codes.PLAYER_SELECTED": "Player selected",
  "error_codes.RANGE_LIMIT_EXCEEDED": "Data range limit exceeded",
  "error_codes.RANGE_LIMIT_REACHED": "Data range limit reached",
  "error_codes.RANGE_NOT_FOUND": "Data range not found",
  "error_codes.RECORD_NOT_FOUND": "Record not found",
  "error_codes.SEASON_NOT_FOUND": "Season not found",
  "error_codes.SELECTION_NOT_FOUND": "Selection not found",
  "error_codes.SERVER_UNREACHABLE": "Server unreachable",
  "error_codes.SESSION_NOT_FOUND": "Session not found",
  "error_codes.TEAM_LIMIT_REACHED": "Team limit reached",
  "error_codes.TEAM_NOT_FOUND": "Team not found",
  "error_codes.UNAUTHORIZED": "Unauthorized",
  "error_codes.UNEXPECTED_ERROR": "Unexpected error",
  "error_codes.UNKNOWN_ERROR": "Unknown error",
  "error_codes.USER_ALREADY_EXISTS": "User already exists",
  "error_codes.USER_HAS_CLUB": "User has club",
  "error_codes.USER_NOT_FOUND": "User not found",
  "error_codes.USER_NOT_VERIFIED": "User not verified",
  "errors": "Errors",
  "event.compute": "Compute event",
  "event.create": "Create event",
  "event.creation": "Event creation",
  "event.export.download.fieldwiz_analyze_CSV": "Download FieldWiz Analyze CSV",
  "event.export.download_report": "Download report",
  "event.export.empty_report": "Empty report",
  "event.export.reset_report": "Reset report",
  "event.name": "Event Name",
  "event.period.create": "Create period",
  "event.period.create.force": "Force sessions for all player over this period",
  "event.period.new": "New period",
  "event.period.start": "Start period",
  "event.period.stop": "Stop period",
  "event.quick_import": "Quick import",
  "event.start": "Start event",
  "event.stop": "Stop event",
  "event.type": "Event type",
  "event.type.INDIVIDUAL_MATCH": "Individual Match",
  "event.type.INDIVIDUAL_TRAINING": "Individual Training",
  "event.type.MATCH": "Match",
  "event.type.TRAINING": "Training",
  "exit": "Exit",
  "exit_confirm_msg": "Are you sure you want to logout?",
  "export": "Export",
  "friday": "Friday",
  "global.assign": "Assign",
  "global.assigned": "Assigned",
  "global.back": "Back",
  "global.cancel": "Cancel",
  "global.compute": "Compute",
  "global.contact_us": "Contact us",
  "global.delete": "Delete",
  "global.finish": "Finish",
  "global.hybrid": "Hybrid",
  "global.live": "Live",
  "global.loading": "Loading",
  "global.next": "Next",
  "global.offline": "Offline",
  "global.online": "Online",
  "global.options": "Options",
  "global.period": "Period",
  "global.scenario": "Scenario",
  "global.start": "Start",
  "global.stop": "Stop",
  "global.success": "Success",
  "global.system": "System",
  "global.unassign": "Unassign",
  "global.view": "View",
  "health": "Health",
  "heartRate": "HeartRate",
  "heatmap": "HeatMap",
  "home": "Home",
  "hr": "HR",
  "import": "Import",
  "import.usb-dkg": "USB / DKG import",
  "import.usb.close_modal": "Close modal",
  "import.usb.create_file_error": "Error creating file",
  "import.usb.create_file_msg": "Creating file for upload...",
  "import.usb.data_upload": "Device data upload",
  "import.usb.detected_browser": "Detected browser : {name}",
  "import.usb.download_device_data_msg": "Downloading device data ...",
  "import.usb.download_error": "Error downloading data from device",
  "import.usb.download_msg": "Downloading data from device",
  "import.usb.erase_error": "Error erasing data from device",
  "import.usb.erase_msg": "Erasing data from device",
  "import.usb.file_upload_error": "Error uploading file",
  "import.usb.file_upload_msg": "Uploading file",
  "import.usb.job_error": "Device data import failed !",
  "import.usb.job_error_msg": "Device data import failed, please try again later.",
  "import.usb.job_state_updated_msg": "Device data processing state : {state}",
  "import.usb.job_success": "Device data import finished !",
  "import.usb.link_copy_success_msg": "URL copied to clipboard",
  "import.usb.no_data": "No data from device",
  "import.usb.processing_job": "Processing data",
  "import.usb.success_msg": "Data uploaded successfully",
  "import.usb.unsupported_browser_msg": "USB upload is not available on the current browser, we recommend to use Google Chrome desktop browser.",
  "import.usb.uploading_file_msg": "Uploading device data file ...",
  "intervals": "Intervals",
  "lan.it-IT": "Italiano",
  "lang.de-DE": "Deutsch",
  "lang.en-GB": "English",
  "lang.en-US": "English",
  "lang.es-ES": "Español",
  "lang.fr-FR": "Français",
  "language": "Language",
  "league.ACADEMY": "Academy",
  "league.INTERNATIONAL": "International",
  "league.PRO1": "Pro1",
  "league.PRO2": "Pro2",
  "light": "Light mode",
  "list": "List",
  "live": "Live",
  "load]": "Load",
  "login": "Login",
  "login.caption": "Please login to continue",
  "manager": "Manager",
  "max": "Max",
  "monday": "Monday",
  "name": "Name",
  "night": "Night",
  "off": "Off",
  "offline": "Offline",
  "on": "On",
  "password": "Password",
  "password_reset.error": "Error resetting password",
  "player.add": "Add player",
  "player.birthdate": "Birthdate",
  "player.delete": "Delete {playerName}",
  "player.delete.msg": "Are you sure you want to delete {playerName} ?",
  "player.firstName": "First name",
  "player.footRef": "Foot",
  "player.footRef.LEFT": "Left",
  "player.footRef.RIGHT": "Right",
  "player.gender": "Gender",
  "player.gender.MAN": "Man",
  "player.gender.WOMAN": "Woman",
  "player.gps_profile": "GPS profile",
  "player.height": "height",
  "player.hrMax": "HR Max",
  "player.lastName": "Last name",
  "player.number": "Number",
  "player.position": "Position",
  "player.position.ASSISTANT_REFEREE": "Assistant Referee",
  "player.position.CENTER_BACK": "Center Back",
  "player.position.CENTRE_THREE_QUARTER": "Centre Three Quarter",
  "player.position.DEFENSIVE_MIDFIELDER": "Defensive Midfielder",
  "player.position.FLANKER": "Flanker",
  "player.position.FLY_HALF": "Fly Half",
  "player.position.FORWARD": "Forward",
  "player.position.FULL_BACK": "Full Back",
  "player.position.GOALKEEPER": "Goalkeeper",
  "player.position.HOOKER": "Hooker",
  "player.position.LOCK": "Lock",
  "player.position.MIDFIELDER": "Midfielder",
  "player.position.OFFENSIVE_MIDFIELDER": "Offensive Midfielder",
  "player.position.PROP": "Prop",
  "player.position.REFEREE": "Referee",
  "player.position.SCRUM_HALF": "Scrum Half",
  "player.position.SIDE_MIDFIELDER": "Side Midfielder",
  "player.position.THIRD_LINE": "Third Line",
  "player.position.WINGER": "Winger",
  "player.profile": "Player profile",
  "player.profile.AEROBIC": "Aerobic",
  "player.profile.INTERMEDIATE": "Intermediate",
  "player.profile.SPEED": "Speed",
  "player.speedMax": "Max speed",
  "player.status": "Status",
  "player.status.ABSENT": "Absent",
  "player.status.CARE": "Care",
  "player.status.FITNESS_REHAB": "Fitness rehab",
  "player.status.INJURED": "Injured",
  "player.status.PHYSIO_REHAB": "Physio rehab",
  "player.status.RECOVERED": "Recovered",
  "player.status.RECOVERY": "Recovery",
  "player.status.SELECTION": "Selection",
  "player.status.SICK": "Sick",
  "player.status.VALID": "Valid",
  "player.status.select": "Select player status",
  "player.update": "Update player",
  "player.weight": "Weight",
  "players": "Players",
  "profile": "Profile",
  "range.dates": "Range dates",
  "range.from": "From",
  "range.to": "To",
  "ratio": "Ratio",
  "record": "Record",
  "refresh": "Refresh",
  "register": "Register",
  "register.caption": "Register a new account",
  "report": "Report",
  "reset": "Reset",
  "reset_password": "Reset password",
  "reset_password.caption": "Reset your password",
  "reset_password.error": "Error resetting password",
  "reset_password.success": "Password reset email sent",
  "rule.email": "Please enter a valid email",
  "rule.password": "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter and one number",
  "rule.required": "This field is required",
  "saturday": "Saturday",
  "search": "Search",
  "season": "Season",
  "season.create": "New season",
  "season.endDate": "Season end date",
  "season.league": "League",
  "season.name": "Season name",
  "season.startDate": "Season start date",
  "seasons": "Seasons",
  "selection": "Selection",
  "selection.slide_left_to_unselect": "Slide left to unselect player",
  "selection.slide_right_to_select": "Slide right to select player",
  "selection.update": "Update selection for {playerName}",
  "sessions": "Sessions",
  "settings": "Settings",
  "speed": "Speed",
  "sprint": "Sprint",
  "sprint.history": "Sprint history",
  "sprints": "Sprints",
  "sprints.weekly_stats": "Weekly sprints stats",
  "startDate": "StartDate",
  "statistics": "Statistics",
  "subscription": "Subscription",
  "subscription.level": "Subscription level",
  "subscription.limits.members": "Member limit",
  "subscription.limits.players": "Players limit",
  "subscription.limits.teams": "Teams limit",
  "subscription.plan": "Subscription plan",
  "subscription.services": "Subscription services",
  "subscriptions": "Subscriptions",
  "sunday": "Sunday",
  "team": "Team",
  "team.create": "Create team",
  "team.delete": "Delete team",
  "team.delete_keepPlayers": "Keep players",
  "team.delete_keepPlayers_msg": "Do you want to delete the players of the team ?",
  "team.name": "Team name",
  "team.players.assign": "Assign to {teamName}",
  "team.players.assign_confirm": "Are you sure you want to assign {playerNames} to {teamName}?",
  "team.players.unassign": "Unassign from {teamName}",
  "team.players.unassign_confirm": "Are you sure you want to unassign {playerNames} from {teamName}?",
  "team.update": "Update team",
  "teams": "Teams",
  "thursday": "Thursday",
  "time": "Time",
  "timeline": "Timeline",
  "tuesday": "Tuesday",
  "type": "Type",
  "update": "Update",
  "upload": "Upload",
  "upload.no_device": "No device",
  "upload.no_jobs": "No jobs",
  "usb": "USB",
  "user": "User",
  "user.copy_email": "Copy email",
  "user.new": "New user",
  "user.verification": "Email verification",
  "user.verification_msg": "To get access to the app, you need to verify your email.",
  "users": "Users",
  "verification_msg": "To verify your account, please click on the link sent to your email. If you have already done the verification, please click on the button below to access the app. If you don't find the email, please check your spam folder.",
  "verified": "Verified",
  "verify": "Verify",
  "version": "Version",
  "wednesday": "Wednesday",
  "week": "Week",
  "welcome_msg": "Welcome to the application ! to start using it you need to either to create a new one or join an existing club by asking a club manager to add your email.",
  "workload": "Workload"
}
