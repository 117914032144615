import { date } from "quasar";
const { extractDate, formatDate } = date;
const DATE_MASK = "YYYY-MM-DD";
const TIME_MASK = "HH:mm";
const MASK = `${DATE_MASK} ${TIME_MASK}`;

export default {
  data() {
    return {
      mask: "YYYY-MM-DD HH:mm",
      inputMask: "####-##-## ##:##",
      dateMask: "YYYY-MM-DD",
      inputDateMask: "####-##-##",
      timeMask: "HH:mm",
      inputTimeMask: "##:##",
    };
  },
  methods: {
    extractDateTimeString(dateTimeString) {
      return new Date(extractDate(dateTimeString, MASK));
    },
    extractDateString(dateString) {
      return new Date(extractDate(dateString, DATE_MASK));
    },
    maskDate(date, type = "datetime") {
      if (!date) return "";

      const mask = type === "datetime" ? MASK : DATE_MASK;

      return formatDate(new Date(date), mask);
    },
    formatTimerFromSec(sec) {
      const hours = Math.floor(sec / 3600);
      const minutes = Math.floor((sec % 3600) / 60);
      const seconds = Math.floor(sec % 60);

      return `${hours}:${minutes}:${seconds}`;
    },
  },
};
