<template>
  <q-stepper v-if="!$apollo.queries.eventIntervalsAndSessions.loading" v-model="step" animated header-nav flat>
    <q-step
      v-for="(interval, index) in intervals"
      :key="`interval-${index}`"
      :name="index"
      active-color="secondary"
      :title="formatStepTitle(interval, index)"
      :caption="intervalCaption(interval)"
      :icon="interval.periodId ? 'pending_actions' : 'fast_forward'"
    >
      <div>
        <q-item v-if="interval.periodId" class="fit" expand-separator group="periods">
          <template #default>
            <q-list class="fit">
              <q-item>
                <q-item-section avatar>
                  <q-icon color="primary" name="sports_kabaddi" />
                </q-item-section>
                <q-item-section> TEAM </q-item-section>
                <q-item-section v-if="sessionsByInterval[index]" side>
                  <q-badge rounded outline color="primary" label="global" />
                </q-item-section>
              </q-item>
              <q-item>
                <q-form class="col-12 row wrap" @submit="onPeriodSessionsSet(interval)">
                  <div class="col-12">
                    <BarSliderVue
                      :data="speedSamples?.data ?? []"
                      :from="new Date(interval.startDate).getTime()"
                      :to="new Date(interval.endDate).getTime()"
                      :min="new Date(interval.startDate).getTime()"
                      :max="new Date(interval.endDate).getTime()"
                      @update="(data) => onUpdate(interval, data)"
                    />
                  </div>
                  <div class="col-12 row wrap justify-end">
                    <q-btn
                      flat
                      fab-mini
                      dense
                      color="negative"
                      icon="playlist_remove"
                      @click="onPeriodSessionsDelete(interval)"
                    />
                    <q-btn icon="save" type="submit" color="primary" fab-mini flat />
                  </div>
                </q-form>
                <q-separator inset />
              </q-item>
            </q-list>
          </template>
        </q-item>

        <q-item
          v-for="{ player } in event.selection"
          :key="`interval-${index}-player-${player.id}`"
          expand-separator
          class="fit"
          group="periods"
        >
          <template #default>
            <q-list class="fit">
              <q-item>
                <q-item-section avatar>
                  <q-icon :color="sessionsByInterval[index][player.id] ? 'secondary' : 'grey'" name="directions_run" />
                </q-item-section>
                <q-item-section>
                  {{ player.lastName }}
                </q-item-section>
                <q-item-section side>
                  <q-btn
                    flat
                    fab-mini
                    dense
                    color="secondary"
                    icon="add"
                    @click="onSessionCreate(interval, player.id)"
                  />
                </q-item-section>
              </q-item>

              <q-item v-for="session in sessionsByInterval[index][player.id]" :key="session.id">
                <q-form class="col-12 row wrap" @reset="onSessionReset(session)" @submit="onSessionUpdate(session)">
                  <div class="col-12">
                    <BarSliderVue
                      :data="speedSamples?.players?.[player.id] ?? []"
                      :from="new Date(session.startDate).getTime()"
                      :to="new Date(session.endDate).getTime()"
                      :min="new Date(interval.startDate).getTime()"
                      :max="new Date(interval.endDate).getTime()"
                      @update="(data) => onUpdate(session, data)"
                    />
                  </div>
                  <div class="col-12 row wrap justify-end">
                    <q-btn icon="restart_alt" type="reset" color="secondary" flat fab-mini dense />
                    <q-btn
                      flat
                      fab-mini
                      dense
                      color="negative"
                      icon="playlist_remove"
                      @click="onSessionDelete(session)"
                    />
                    <q-btn icon="save" type="submit" color="positive" fab-mini flat />
                  </div>
                </q-form>
                <q-separator inset />
              </q-item>
            </q-list>
          </template>
        </q-item>
      </div>
    </q-step>
  </q-stepper>
</template>

<script>
import { groupBy, keyBy, round, sum } from "lodash";
import { EVENT_INTERVALS_AND_SESSIONS_QUERY } from "src/graphql/eventQueries";
import { isWithinInterval } from "date-fns";
import {
  CREATE_SESSION_MUTATION,
  DELETE_PERIOD_SESSIONS_MUTATION,
  DELETE_SESSION_MUTATION,
  SET_PERIOD_SESSIONS_MUTATION,
  UPDATE_SESSION_MUTATION,
} from "src/graphql/sessionQueries";
import BarSliderVue from "src/components/ui/BarSlider.vue";
import { cloneDeep } from "@apollo/client/utilities";
const MIN = 60 * 1000;

export default {
  components: {
    BarSliderVue,
  },
  props: {
    event: Object,
    speedSamples: {
      type: Object,
    },
  },
  data() {
    return {
      step: 0,
      prettify: (interval) => {
        const startTs = new Date(interval.startDate).getTime();

        return (ts) => {
          return `${Math.round((ts - startTs) / MIN)} min`;
        };
      },
    };
  },
  computed: {
    eventStartTs() {
      return new Date(this.event.startDate).getTime();
    },
    playersById() {
      return keyBy(this.event.selection, (s) => s.player.id);
    },
    numberOfPlayers() {
      return this.event.selection.length;
    },
    recordByPlayerId() {
      return keyBy(this.eventIntervalsAndSessions.records, "playerId");
    },
    intervals() {
      return cloneDeep(this.eventIntervalsAndSessions.intervals);
    },
    sessionsByInterval() {
      return this.getSessionsByInterval(this.intervals, cloneDeep(this.eventIntervalsAndSessions.sessions));
    },
  },
  methods: {
    ts2min(ts) {
      return Math.round(ts / MIN);
    },
    intervalCaption(interval) {
      const startTs = new Date(interval.startDate).getTime();
      const endTs = new Date(interval.endDate).getTime();

      return `${this.ts2min(startTs - this.eventStartTs)} - ${this.ts2min(endTs - this.eventStartTs)} (${this.ts2min(
        endTs - startTs
      )} min)`;
    },
    onUpdate(interval, range) {
      interval.startTs = new Date(range.min);

      interval.endTs = new Date(range.max);
    },
    round: round,
    onPeriodSessionsSet(interval) {
      this.$apollo.mutate({
        mutation: SET_PERIOD_SESSIONS_MUTATION,
        variables: {
          eventId: this.event.id,
          periodId: interval.periodId,
          startDate: new Date(interval.startTs || interval.startDate),
          endDate: new Date(interval.endTs || interval.endDate),
        },
      });
    },
    onPeriodSessionsDelete(interval) {
      const { periodId } = interval;

      this.$apollo.mutate({
        mutation: DELETE_PERIOD_SESSIONS_MUTATION,
        variables: {
          eventId: this.event.id,
          periodId,
        },
      });
    },
    formatStepTitle(interval, index) {
      return `${interval.name} (${Object.keys(this.sessionsByInterval[index]).length} / ${this.numberOfPlayers})`;
    },
    formatPlayerBadgeLabel(interval, sessions) {
      return `${Math.round(sum(sessions.map((s) => s.endTs - s.startTs)) / MIN)} min`;
    },
    async onSessionDelete(session) {
      await this.$apollo.mutate({
        mutation: DELETE_SESSION_MUTATION,
        variables: {
          eventId: this.event.id,
          sessionId: session.id,
        },
        update: (store) => {
          const query = {
            query: EVENT_INTERVALS_AND_SESSIONS_QUERY,
            variables: {
              eventId: this.event.id,
            },
          };
          const data = store.readQuery(query);

          store.writeQuery({
            ...query,
            data: {
              event: {
                ...data.event,
                sessions: data.event.sessions.filter((s) => s.id !== session.id),
              },
            },
          });
        },
      });
    },
    onSessionReset(session) {
      delete session.startTs;

      delete session.endTs;
    },
    onSessionUpdate(session) {
      if (!session.startTs || !session.endTs) return;

      session.startDate = new Date(session.startTs);

      session.endDate = new Date(session.endTs);

      this.$apollo.mutate({
        mutation: UPDATE_SESSION_MUTATION,
        variables: {
          eventId: this.event.id,
          sessionId: session.id,
          session: {
            startDate: session.startDate,
            endDate: session.endDate,
          },
        },
      });
    },
    onSessionCreate(interval, playerId) {
      const { periodId, startDate, endDate } = interval;
      const eventId = this.event.id;

      this.$apollo.mutate({
        mutation: CREATE_SESSION_MUTATION,
        variables: {
          eventId,
          startDate,
          endDate,
          playerId,
          periodId,
        },
        update: (store, { data: { createSession } }) => {
          store.modify({
            id: store.identify(this.event),
            fields: {
              sessions: (sessions) => [...sessions, createSession],
            },
          });
        },
      });
    },
    getSessionsByInterval(intervals, sessions) {
      const sessionsByInterval = [];
      const sessionsByPeriodId = groupBy(sessions, "periodId");

      for (const interval of intervals) {
        let sessions = [];
        const periodSessions = sessionsByPeriodId[interval.periodId] || [];

        if (interval.periodId) {
          sessions = periodSessions;
        } else {
          sessions = periodSessions.filter((session) =>
            isWithinInterval(new Date(session.startDate), {
              start: new Date(interval.startDate),
              end: new Date(interval.endDate),
            })
          );
        }

        sessionsByInterval.push(groupBy(sessions, "playerId"));
      }

      return sessionsByInterval;
    },
  },
  apollo: {
    eventIntervalsAndSessions: {
      query: EVENT_INTERVALS_AND_SESSIONS_QUERY,
      variables() {
        return {
          eventId: this.event.id,
        };
      },
      update({ event: { intervals, sessions } }) {
        return { intervals, sessions };
      },
    },
  },
};
</script>
