import gql from "graphql-tag";

export const paginationFragment = gql`
  fragment paginationFragment on Pagination {
    totalDocs
    limit
    page
    totalPages
    hasNextPage
    nextPage
    hasPrevPage
    prevPage
    pagingCounter
  }
`;


export const OPTIONS_QUERY = gql`
  query options {
    playerPositions
    playerProfiles
    playerStatuses
    playerGenders
    playerFoots
    leagues
    eventTypes
  }
`;