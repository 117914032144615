<template>
  <q-btn
    rounded
    push
    color="accent"
    :label="$t('import.usb-dkg')"
    @click="onUSBImportBtn"
  >
    <q-dialog v-model="usbImportModal" position="top" full-width>
      <q-card>
        <q-tabs
          v-model="tab"
          dense
          active-color="accent"
          indicator-color="accent"
          align="justify"
        >
          <q-tab name="usb" label="USB" />
          <q-tab name="dkg" label="DKG" />
        </q-tabs>

        <q-separator />

        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="usb">
            <DeviceUSBTableVue
              v-if="usbUploadEnabled"
              bordered
              color="accent"
            />
            <q-banner
              v-else
              inline-actions
              class="text-white bg-accent rounded-borders"
            >
              {{ $t("import.usb.unsupported_browser_msg") }}
            </q-banner>
          </q-tab-panel>

          <q-tab-panel name="dkg">
            <DockingStationFileUploader color="accent" />
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </q-dialog>
  </q-btn>
</template>
<script>
import DeviceUSBTableVue from "src/components/device/DeviceUSBTable.vue";
import DockingStationFileUploader from "src/components/device/DockingStationFileUploader.vue";

export default {
  components: {
    DockingStationFileUploader,
    DeviceUSBTableVue,
  },
  data() {
    return {
      usbImportModal: false,
      tab: "usb",
    };
  },
  computed: {
    usbUploadEnabled() {
      return navigator.usb;
    },
  },
  methods: {
    onUSBImportBtn() {
      this.usbImportModal = true;
    },
  },
};
</script>
