import gql from "graphql-tag";

export const userFragment = gql`
  fragment userFragment on User {
    id
    email
    verified
    admin
    manager
    coach
  }
`;

export const USER_QUERY = gql`
  query user {
    user {
      ...userFragment
    }
  }
  ${userFragment}
`;

export const USERS_QUERY = gql`
  query users {
    users {
      id
      clubId
      email
      admin
      verified
      club {
        name
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(user: { email: $email, password: $password }) {
      user {
        ...userFragment
      }
      token
    }
  }
  ${userFragment}
`;

export const REGISTER_MUTATION = gql`
  mutation register($email: String!, $password: String!) {
    register(user: { email: $email, password: $password }) {
      user {
        ...userFragment
      }
      token
    }
  }
  ${userFragment}
`;

export const IMPERSONATE_MUTATION = gql`
  mutation impersonate($userId: ID!) {
    impersonate(userId: $userId)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const NEW_PASSWORD_MUTATION = gql`
  mutation newPassword($token: String!, $password: String!) {
    newPassword(token: $token, password: $password) {
      ...userFragment
    }
  }
  ${userFragment}
`;

export const VERIFY_EMAIL_MUTATION = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token) {
      ...userFragment
    }
  }
  ${userFragment}
`;

export const PASSWORD_CONFIG_QUERY = gql`
  query passwordConfig {
    passwordConfig
  }
`;
