<template>
  <q-layout view="hHh LpR fFf">
    <q-header reveal elevated height-hint="98">
      <q-pull-to-refresh @refresh="refreshApolloCache">
        <q-toolbar class="q-pr-none">
          <!-- <q-avatar size="2rem" class="cursor-pointer" @click="leftDrawerOpen = !leftDrawerOpen">
            <img :src="logo" class="bg-white" />
          </q-avatar> -->
          <q-avatar
            size="2rem"
            class="cursor-pointer"
            color="secondary"
            text-color="white"
            @click="leftDrawerOpen = !leftDrawerOpen"
          >
            {{ club?.name[0] }}
          </q-avatar>
          <q-toolbar-title shrink class="cursor-pointer" @click="onTitleClick">
            {{ appName }}
          </q-toolbar-title>
          <BreadcrumbsNavVue v-if="$q.screen.gt.xs" :club="club" />

          <q-space />

          <q-btn dense flat round icon="tune" class="q-mr-sm" @click="rightDrawerOpen = !rightDrawerOpen" />
        </q-toolbar>
      </q-pull-to-refresh>
    </q-header>

    <q-drawer
      v-if="club && $route.meta.drawerHidden !== true"
      v-model="leftDrawerOpen"
      side="left"
      show-if-above
      :mini="isLeftDrawerMini"
      persistent
      :width="leftdrawerWidth"
      :breakpoint="0"
      bordered
      @mouseover="!$q.platform.has.touch && (leftDrawerMini = false)"
      @mouseout="!$q.platform.has.touch && (leftDrawerMini = true)"
    >
      <div class="full-height column no-wrap">
        <ClubNavList v-if="$route.meta.hasClubDrawer" :user="user" :club="club" />
        <TeamNavList v-if="$route.meta.hasTeamDrawer" :club="club" :mini="isLeftDrawerMini" />
        <EventNavList v-if="$route.meta.hasEventDrawer" :user="user" :club="club" :mini="isLeftDrawerMini" />
        <PlayerNavList v-if="$route.meta.hasPlayerDrawer" :user="user" :club="club" />
        <AdminNavList v-if="$route.meta.hasAdminDrawer" :user="user" :club="club" />
      </div>
    </q-drawer>

    <q-drawer v-model="rightDrawerOpen" side="right" :width="rightDrawerWidth" bordered overlay>
      <div class="fit column">
        <q-list padding>
          <q-item v-if="user" :clickable="user.admin" :to="user.admin && { name: 'admin' }">
            <q-item-section avatar>
              <q-avatar color="secondary" text-color="white">
                {{ user.email[0].toUpperCase() }}
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ user.email.split("@")[0] }}</q-item-label>
              <q-item-label caption lines="1">@{{ user.email.split("@")[1] }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator spaced />

          <div v-if="club">
            <q-item-label header>{{ $t("connection") }}</q-item-label>
            <AsiConnect :club="club" />
            <q-separator spaced />
          </div>

          <q-item-label header>{{ $t("settings") }}</q-item-label>
          <q-item>
            <q-item-section>
              <q-item-label>{{ $t("light") }} : </q-item-label>
              <q-item-label caption lines="1">
                {{ $q.dark.mode === "auto" ? $t("auto") : $q.dark.mode ? $t("night") : $t("day") }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-toggle
                :model-value="$q.dark.mode"
                toggle-indeterminate
                :true-value="false"
                :false-value="true"
                :indeterminate-value="'auto'"
                icon="lightbulb"
                color="secondary"
                @update:model-value="onToggleDarkMode"
              />
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label>{{ $t("demo_mode") }} : </q-item-label>
              <q-item-label caption lines="1">
                {{ apolloContext.demo ? $t("on") : $t("off") }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-toggle
                :model-value="apolloContext.demo"
                icon="science"
                color="secondary"
                @update:model-value="onDemoModeToggle"
              />
            </q-item-section>
          </q-item>
          <q-item>
            <q-btn-dropdown rounded flat class="full-width">
              <template #label>
                <q-item dense class="q-px-none">
                  <q-item-section avatar>
                    <q-icon :name="getFlagUrlFromLocale($i18n.locale)" />
                  </q-item-section>
                  <q-item-section>{{ $i18n.locale }}</q-item-section>
                </q-item>
              </template>
              <q-list>
                <q-item
                  v-for="(locale, i) in $i18n.availableLocales"
                  :key="`lang-${i}`"
                  v-close-popup
                  clickable
                  @click="onSelectLang(locale)"
                >
                  <q-item-section avatar>
                    <q-icon :name="getFlagUrlFromLocale(locale)" />
                  </q-item-section>
                  <q-item-section>{{ locale }}</q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </q-item>

          <q-separator spaced />

          <q-item @click="logout()">
            <q-btn :label="$t('exit')" class="full-width" flat color="negative" rounded @click="logout()" />
          </q-item>
        </q-list>
        <q-space />
        <q-list>
          <q-item>
            <q-item-section>
              <q-item-label>{{ $t("version") }}</q-item-label>
            </q-item-section>

            <q-item-section side>
              <q-item-label> v{{ version }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-drawer>
    <q-page-container>
      <router-view v-if="!$apollo.loading" :user="user" :club="club" />
    </q-page-container>

    <FooterNav v-if="$q.screen.lt.sm" @menu-click="leftDrawerOpen = !leftDrawerOpen" />
  </q-layout>
</template>

<script>
import { USER_QUERY } from "src/graphql/userQueries";
import AsiConnect from "src/components/club/AsiConnectItem.vue";
import ClubNavList from "src/components/club/ClubNavList.vue";
import TeamNavList from "src/components/team/TeamNavList.vue";
import PlayerNavList from "src/components/player/PlayerNavList.vue";
import EventNavList from "src/components/event/EventNavList.vue";
import BreadcrumbsNavVue from "src/components/ui/BreadcrumbsNav.vue";
import AdminNavList from "src/components/admin/AdminNavList.vue";
import { version } from "src/../project.json";
import { createMetaMixin } from "quasar";
import { CLUB_EXTERNAL_SERVICES_UPDATED_SUB, CLUB_QUERY } from "src/graphql/clubQueries";
import { apolloContext } from "src/boot/apollo";
import { langs } from "src/i18n";
import { cloneDeep } from "@apollo/client/utilities";
import FooterNav from "src/components/ui/FooterNav.vue";

export default {
  name: "MainLayout",
  components: {
    AdminNavList,
    AsiConnect,
    ClubNavList,
    TeamNavList,
    PlayerNavList,
    EventNavList,
    BreadcrumbsNavVue,
    FooterNav,
  },
  mixins: [
    createMetaMixin(function () {
      return {
        titleTemplate: (title) => `${title} - ${process.env.APP_NAME}`,
        title: this.club?.name,
      };
    }),
  ],
  data() {
    return {
      leftDrawerOpen: false,
      rightDrawerOpen: false,
      leftDrawerMini: true,
      leftdrawerWidth: 230,
      rightDrawerWidth: 250,
      version,
      apolloContext,
    };
  },

  computed: {
    hasDrawer() {
      return (
        this.$route.meta.hasClubDrawer ||
        this.$route.meta.hasTeamDrawer ||
        this.$route.meta.hasPlayerDrawer ||
        this.$route.meta.hasEventDrawer
      );
    },
    logo() {
      return this.club?.logo || "icons/favicon-96x96.png";
    },
    teamId() {
      return this.$route.params.teamId;
    },
    clubId() {
      return this.$route.params.clubId;
    },
    isLeftDrawerMini() {
      return this.$q.screen.lt.xl && this.leftDrawerMini;
    },
  },
  mounted() {
    this.leftDrawerOpen = this.$q.screen.gt.xs;
  },
  methods: {
    onToggleDarkMode(val) {
      this.$q.dark.set(val);
    },
    getFlagUrlFromLocale(lang) {
      const flag = lang.split("-")[1]?.toLowerCase();

      return flag ? `img:flags/${flag}.svg` : "";
    },
    refreshApolloCache(done) {
      this.$apollo.getClient().resetStore().then(done());
    },
    logout() {
      this.$q
        .dialog({
          title: this.$t("exit"),
          message: this.$t("exit_confirm_msg"),
          cancel: {
            rounded: true,
            flat: true,
            label: this.$t("cancel"),
          },
          ok: {
            rounded: true,
            push: true,
            label: this.$t("exit"),
          },
          color: "negative",
        })
        .onOk(async () => {
          const isImpersonating = localStorage.getItem("impersonate_token");
          const apolloClient = this.$apollo.getClient();

          if (isImpersonating) {
            localStorage.removeItem("impersonate_token");

            await apolloClient.resetStore();
          } else {
            localStorage.removeItem("auth_token");

            await apolloClient.clearStore();
          }

          this.$router.replace({ name: "login" });
        });
    },
    onSelectLang(lang) {
      this.$i18n.locale = lang;

      this.$q.lang.set(langs[lang]);

      this.$q.localStorage.set("locale", lang);
    },
    async onDemoModeToggle() {
      this.$q.loading.show();

      this.apolloContext.demo = !this.apolloContext.demo;

      await this.$apollo.getClient().resetStore();

      this.$q.loading.hide();
    },
    onLeftDrawerMouseover() {
      if (this.isLeftDrawerMini) {
        this.leftDrawerOpen = true;
      }
    },
    onLeftDrawerMouseleave() {
      if (this.isLeftDrawerMini) {
        this.leftDrawerOpen = false;
      }
    },
    onTitleClick() {
      this.$router.push({ name: "clubDashboard" });
    },
  },
  apollo: {
    club: {
      query: CLUB_QUERY,
      subscribeToMore: [
        {
          document: CLUB_EXTERNAL_SERVICES_UPDATED_SUB,
          updateQuery: ({ club }, { subscriptionData: { data } }) => {
            club = cloneDeep(club);

            club.services = data.externalServicesUpdated;

            return { club };
          },
          skip() {
            return !this.club;
          },
        },
      ],
    },
    user: USER_QUERY,
  },
};
</script>
