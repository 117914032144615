import gql from "graphql-tag";

export const ExternalServicesFragment = gql`
  fragment ExternalServicesFragment on ExternalServices {
    asi {
      email
      connected
    }
  }
`;

export const clubFragment = gql`
  fragment clubFragment on Club {
    id
    name
    sport
    services {
      ...ExternalServicesFragment
    }
    subscription {
      active
      plan
      limits {
        members
        teams
        players
      }
      options {
        tagManagement
      }
      devices
    }
  }
  ${ExternalServicesFragment}
`;

export const memberFragment = gql`
  fragment memberFragment on User {
    id
    email
    manager
    coach
  }
`;

export const CLUB_QUERY = gql`
  query club {
    club {
      ...clubFragment
    }
  }
  ${clubFragment}
`;

export const CLUBS_QUERY = gql`
  query clubs {
    clubs {
      ...clubFragment
    }
  }
  ${clubFragment}
`;

export const SUBSCRIPTION_PLANS_QUERY = gql`
  query subscriptionPlans {
    subscriptionPlans
  }
`;

export const CLUB_MEMBERS_QUERY = gql`
  query club {
    club {
      id
      members {
        ...memberFragment
      }
    }
  }
  ${memberFragment}
`;

export const CREATE_CLUB_MUTATION = gql`
  mutation createClub($name: String!) {
    createClub(club: { name: $name }) {
      ...clubFragment
    }
  }
  ${clubFragment}
`;

export const ADD_CLUB_MEMBER_MUTATION = gql`
  mutation addMember($clubId: ID!, $email: String!) {
    addMember(clubId: $clubId, email: $email) {
      ...memberFragment
    }
  }
  ${memberFragment}
`;

export const REMOVE_CLUB_MEMBER_MUTATION = gql`
  mutation removeMember($clubId: ID!, $memberId: ID!) {
    removeMember(clubId: $clubId, memberId: $memberId) {
      ...memberFragment
    }
  }
  ${memberFragment}
`;

export const CLUB_EXTERNAL_SERVICES_UPDATED_SUB = gql`
  subscription externalServicesUpdated {
    externalServicesUpdated {
      ...ExternalServicesFragment
    }
  }
  ${ExternalServicesFragment}
`;

export const EDIT_CLUB_MEMBER_ACCESS_MUTATION = gql`
  mutation editMemberAccess($clubId: ID!, $memberId: ID!, $manager: Boolean, $coach: [ID]) {
    editMemberAccess(clubId: $clubId, memberId: $memberId, manager: $manager, coach: $coach) {
      ...memberFragment
    }
  }
  ${memberFragment}
`;

export const UPDATE_CLUB_SUBSCRIPTION_MUTATION = gql`
  mutation updateClubSubscription($clubId: ID!, $subscription: ClubSubscriptionInput!) {
    updateClubSubscription(clubId: $clubId, subscription: $subscription) {
      ...clubFragment
    }
  }
  ${clubFragment}
`;
