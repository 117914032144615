import gql from "graphql-tag";
import { rangeFragment } from "./rangeQueries";
import { deviceFragment } from "./deviceQueries";
import { playerFragment, playerAnalysisFragment } from "./playerQueries";
import { seasonFragment } from "./seasonQueries";

export const TEAMS_QUERY = gql`
  query teams {
    teams {
      id
      name
      season {
        name
        league
        startDate
      }
    }
  }
`;

export const TEAMS_PLAYERS_QUERY = gql`
  query teamsPlayers {
    teams {
      id
      name
      players {
        ...playerFragment
      }
    }
  }
  ${playerFragment}
`;

export const TEAM_PLAYERS_QUERY = gql`
  query teamPlayers($teamId: ID!) {
    team(teamId: $teamId) {
      id
      name
      players {
        ...playerFragment
        ...playerAnalysisFragment
      }
    }
  }
  ${playerFragment}
  ${playerAnalysisFragment}
`;

export const TEAM_QUERY = gql`
  query team($teamId: ID!) {
    team(teamId: $teamId) {
      id
      name
      season {
        ...seasonFragment
      }
      seasons {
        ...seasonFragment
      }
      ranges {
        speed {
          ...rangeFragment
        }
        accel {
          ...rangeFragment
        }
      }
      devices {
        ...deviceFragment
      }
    }
  }
  ${seasonFragment}
  ${deviceFragment}
  ${rangeFragment}
`;

export const CREATE_TEAM_MUTATION = gql`
  mutation createTeam($clubId: ID!, $team: TeamInput!) {
    createTeam(clubId: $clubId, team: $team) {
      id
      name
      season {
        ...seasonFragment
      }
    }
  }
  ${seasonFragment}
`;

export const UPDATE_TEAM_MUTATION = gql`
  mutation updateTeam($teamId: ID!, $team: TeamInput!) {
    updateTeam(teamId: $teamId, team: $team) {
      id
      name
    }
  }
`;

export const DELETE_TEAM_MUTATION = gql`
  mutation deleteTeam($teamId: ID!, $keepPlayers: Boolean) {
    deleteTeam(teamId: $teamId, keepPlayers: $keepPlayers)
  }
`;
