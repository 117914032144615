<template>
  <q-stepper
    ref="stepper"
    v-model="step"
    color="secondary"
    animated
    active-color="secondary"
    done-color="secondary"
    class="rounded-borders"
  >
    <q-step
      :name="STEPS.SCENARIO"
      :title="$t('global.scenario')"
      :caption="SCENARIOS_NAMES[selectedScenario]"
      icon="sports_score"
      :done="step > STEPS.SCENARIO"
      class="q-pa-none"
    >
      <q-card flat bordered>
        <q-tabs
          v-model="selectedScenario"
          active-color="secondary"
          indicator-color="secondary"
          inline-label
          align="justify"
        >
          <q-tab
            v-if="ENABLED_DEVICES.INSPIRIT || ENABLED_DEVICES.INTENSE"
            :name="SCENARIOS.INSIDERS_LIVE"
            icon="radio_button_checked"
            label="Insiders"
          >
            <q-badge color="accent" class="q-ml-xs">{{ $t("global.live") }}</q-badge>
            <q-badge color="positive" class="q-ml-xs">{{ $t("global.hybrid") }} / Tag</q-badge>
          </q-tab>
          <q-tab
            v-if="ENABLED_DEVICES.INTENSE"
            :name="SCENARIOS.INTENSE_OFFLINE"
            icon="radio_button_checked"
            label="Intense"
          >
            <q-badge color="secondary" class="q-ml-xs">{{ $t("global.offline") }}</q-badge>
          </q-tab>
          <q-tab v-if="ENABLED_DEVICES.INSPIRIT" :name="SCENARIOS.INSPIRIT_OFFLINE" icon="gps_fixed" label="Inspirit">
            <q-badge color="secondary" class="q-ml-xs">{{ $t("global.offline") }}</q-badge>
          </q-tab>
          <q-tab v-if="ENABLED_DEVICES.POLAR" :name="SCENARIOS.POLAR" icon="explore" label="Polar" />
        </q-tabs>
      </q-card>

      <q-tab-panels v-model="selectedScenario" animated>
        <q-tab-panel :name="SCENARIOS.INSIDERS_LIVE">
          <div class="text-grey-8">
            <div class="text-h6 text-secondary align-center">
              {{ SCENARIOS_NAMES[SCENARIOS.INSIDERS_LIVE] }}
              <insiders-intense-device-badge v-if="ENABLED_DEVICES.INTENSE">
                <q-badge color="accent" floating></q-badge>
              </insiders-intense-device-badge>
              <insiders-inspirit-device-badge v-if="ENABLED_DEVICES.INSPIRIT" />
            </div>
            Start a live event with Insiders Intense devices. (also work with Inspirit devices)
          </div>
        </q-tab-panel>
        <q-tab-panel :name="SCENARIOS.INTENSE_OFFLINE">
          <div class="text-grey-8">
            <div class="text-h6 text-secondary">
              {{ SCENARIOS_NAMES[SCENARIOS.INTENSE_OFFLINE] }}
              <insiders-intense-device-badge />
              <insiders-inspirit-device-badge />
            </div>
            Create an event from Insiders Intense devices data via Insiders API. (also work with Inspirit devices if you
            already uploaded the data to Insiders API)
          </div>
        </q-tab-panel>
        <q-tab-panel :name="SCENARIOS.INSPIRIT_OFFLINE">
          <div class="text-grey-8">
            <div class="text-h6 text-secondary">
              {{ SCENARIOS_NAMES[SCENARIOS.INSPIRIT_OFFLINE] }}
              <insiders-inspirit-device-badge />
            </div>
            Create an event from Insiders Intense devices data via Insiders API. You will be redirected to the data
            upload page. You can upload the data either via USB or using a DKG file from a dock station.
          </div>
        </q-tab-panel>
        <q-tab-panel :name="SCENARIOS.POLAR">
          <div class="text-grey-8">
            <div class="text-h6 text-secondary">
              {{ SCENARIOS_NAMES[SCENARIOS.POLAR] }}
              <polar-device-badge></polar-device-badge>
            </div>
            Create an event from Polar CSV file.
          </div>
        </q-tab-panel>
      </q-tab-panels>

      <q-stepper-navigation class="row justify-end q-gutter-sm">
        <q-btn color="secondary" push rounded :label="$t('global.next')" @click="goToStep(STEPS.TYPE)" />
      </q-stepper-navigation>
    </q-step>

    <q-step
      :name="STEPS.TYPE"
      :title="$t('event.type')"
      :caption="$t(`event.type.${selectedEventType}`)"
      icon="settings"
      :done="step > STEPS.TYPE"
      :disable="!!event"
    >
      <q-card flat bordered>
        <q-tabs
          v-model="selectedEventType"
          class="text-grey-7"
          active-color="secondary"
          indicator-color="secondary"
          inline-label
          align="justify"
        >
          <q-tab :name="EVENT_TYPES.MATCH" icon="sports_soccer" :label="$t(`event.type.${EVENT_TYPES.MATCH}`)" />
          <q-tab :name="EVENT_TYPES.TRAINING" icon="fitness_center" :label="$t(`event.type.${EVENT_TYPES.TRAINING}`)" />
          <q-tab
            :name="EVENT_TYPES.INDIVIDUAL_MATCH"
            icon="sports_soccer"
            :label="$t(`event.type.${EVENT_TYPES.INDIVIDUAL_MATCH}`)"
          />
          <q-tab
            :name="EVENT_TYPES.INDIVIDUAL_TRAINING"
            icon="fitness_center"
            :label="$t(`event.type.${EVENT_TYPES.INDIVIDUAL_TRAINING}`)"
          />
        </q-tabs>
      </q-card>

      <q-tab-panels v-model="selectedEventType" animated>
        <q-tab-panel :name="EVENT_TYPES.MATCH">
          <div class="text-grey-8">
            <div class="text-h6 text-secondary align-center">
              {{ $t(`event.type.${EVENT_TYPES.MATCH}`) }}
            </div>
            It is a match between two teams. You can select the players.
          </div>
        </q-tab-panel>
        <q-tab-panel :name="EVENT_TYPES.TRAINING">
          <div class="text-grey-8">
            <div class="text-h6 text-secondary">
              {{ $t(`event.type.${EVENT_TYPES.TRAINING}`) }}
            </div>
            Training is a session where players are not divided into teams.
          </div>
        </q-tab-panel>
        <q-tab-panel :name="EVENT_TYPES.INDIVIDUAL_MATCH">
          <div class="text-grey-8">
            <div class="text-h6 text-secondary">
              {{ $t(`event.type.${EVENT_TYPES.INDIVIDUAL_MATCH}`) }}
            </div>
            Simulate a match for one player.
          </div>
        </q-tab-panel>
        <q-tab-panel :name="EVENT_TYPES.INDIVIDUAL_TRAINING">
          <div class="text-grey-8">
            <div class="text-h6 text-secondary">
              {{ $t(`event.type.${EVENT_TYPES.INDIVIDUAL_TRAINING}`) }}
            </div>
            Training is a session for one player.
          </div>
        </q-tab-panel>
      </q-tab-panels>

      <q-stepper-navigation class="row justify-end q-gutter-sm">
        <q-btn
          flat
          rounded
          color="secondary"
          :label="$t('global.back')"
          class="q-ml-sm"
          @click="goToStep(STEPS.SCENARIO)"
        />
        <q-btn color="secondary" push rounded :label="$t('global.next')" @click="goToSelectionStep" />
      </q-stepper-navigation>
    </q-step>

    <q-step
      :name="STEPS.SELECTION"
      :title="$t('selection')"
      :caption="`${selection.length} players`"
      icon="people"
      :done="step > STEPS.SELECTION"
    >
      <PlayersSelectionTable v-model="selection" :team-id="teamId" class="rounded-borders" bordered>
        <template #action>
          <q-btn push rounded color="secondary" label="Select" icon="task_alt" @click="onPlayersSelected" />
        </template>
      </PlayersSelectionTable>
      <q-stepper-navigation class="row justify-end q-gutter-sm">
        <q-btn
          flat
          rounded
          color="secondary"
          :label="$t('global.back')"
          class="q-ml-sm"
          @click="goToStep(STEPS.TYPE)"
        />
      </q-stepper-navigation>
    </q-step>
    <q-step
      :name="STEPS.CREATION"
      :title="$t('event.creation')"
      :caption="name"
      icon="add"
      :done="step > STEPS.CREATION"
    >
      <q-form ref="form" @submit="onCreateEvent">
        <q-input
          v-model="name"
          dense
          filled
          class="col-12 col-md-6"
          color="secondary"
          :label="$t('event.name')"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Please type something']"
        />

        <div v-if="!isLiveScenario" class="q-gutter-md">
          <DateTimePicker v-model="startDate" dense :rules="[requiredRule]" />

          <DateTimePicker v-model="endDate" dense :option-fn="endDateOptionsFn" :rules="[requiredRule, endDateRule]" />
        </div>
      </q-form>
      <q-stepper-navigation class="row justify-end q-gutter-sm">
        <q-btn
          flat
          rounded
          color="secondary"
          :label="$t('global.back')"
          class="q-ml-sm"
          @click="goToStep(STEPS.SELECTION)"
        />
        <q-btn
          color="secondary"
          push
          rounded
          :label="$t('event.create')"
          :loading="eventCreationLoading"
          @click="onCreateEvent"
        />
      </q-stepper-navigation>
    </q-step>
  </q-stepper>
</template>
<script>
import datesMixin from "src/mixins/datesMixin";
import InsidersInspiritDeviceBadge from "../InsidersInspiritDeviceBadge.vue";
import InsidersIntenseDeviceBadge from "../InsidersIntenseDeviceBadge.vue";
import PolarDeviceBadge from "../PolarDeviceBadge.vue";
import rulesMixin from "src/mixins/rulesMixin";
import { addMinutes, differenceInDays, differenceInMinutes } from "date-fns";
import { CREATE_EVENT_MUTATION } from "src/graphql/eventQueries";
import PlayersSelectionTable from "src/components/player/PlayersSelectionTable.vue";
import { TEAM_PLAYERS_QUERY } from "src/graphql/teamQueries";
import scenariosMixin from "src/mixins/scenariosMixin";

const EVENT_TYPES = Object.freeze({
  TRAINING: "TRAINING",
  MATCH: "MATCH",
  INDIVIDUAL_TRAINING: "INDIVIDUAL_TRAINING",
  INDIVIDUAL_MATCH: "INDIVIDUAL_MATCH",
});

const STEPS = Object.freeze({
  SCENARIO: 1,
  TYPE: 2,
  SELECTION: 3,
  CREATION: 4,
});

export default {
  components: {
    InsidersInspiritDeviceBadge,
    InsidersIntenseDeviceBadge,
    PolarDeviceBadge,
    PlayersSelectionTable,
  },
  mixins: [datesMixin, rulesMixin, scenariosMixin],
  props: {
    club: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      eventId: null,
      step: 1,
      selectedScenario: "",
      STEPS,
      EVENT_TYPES,
      name: "",
      players: [],
      selectedEventType: EVENT_TYPES.TRAINING,
      selection: [],
      eventCreationLoading: false,
      startDate: this.maskDate(new Date()),
      endDate: this.maskDate(addMinutes(new Date(), 90)),
      endDateRule: (endDate) => {
        if (!this.startDate) return true;

        const diff = differenceInMinutes(
          this.extractDateTimeString(endDate),
          this.extractDateTimeString(this.startDate)
        );

        return (diff >= 1 && diff <= 3 * 60) || "Event must be at least 1 minutes long and max 3 hours";
      },
    };
  },
  computed: {
    isComputeScenario() {
      return [this.SCENARIOS.INSIDERS_LIVE, this.SCENARIOS.INSPIRIT_OFFLINE, this.SCENARIOS.INTENSE_OFFLINE].includes(
        this.selectedScenario
      );
    },
    isLiveScenario() {
      return this.selectedScenario === this.SCENARIOS.INSIDERS_LIVE;
    },
    teamId() {
      return this.$route.params.teamId;
    },
    isComputeEnabled() {
      return [this.SCENARIOS.INSIDERS_LIVE, this.SCENARIOS.INTENSE_OFFLINE, this.SCENARIOS.INSPIRIT_OFFLINE].includes(
        this.selectedScenario
      );
    },
  },
  mounted() {
    this.selectedScenario = this.ENABLED_SCENARIOS.INSIDERS_LIVE
      ? this.SCENARIOS.INSIDERS_LIVE
      : this.ENABLED_SCENARIOS.INTENSE_OFFLINE
      ? this.SCENARIOS.INTENSE_OFFLINE
      : this.ENABLED_SCENARIOS.INSPIRIT_OFFLINE
      ? this.SCENARIOS.INSPIRIT_OFFLINE
      : this.ENABLED_SCENARIOS.POLAR
      ? this.SCENARIOS.POLAR
      : "";
  },
  methods: {
    goToStep(step) {
      this.$refs.stepper.goTo(step);
    },
    goToSelectionStep() {
      switch (this.selectedEventType) {
        case EVENT_TYPES.MATCH:
        case EVENT_TYPES.TRAINING:
          this.selection = this.players.filter((p) => p.status == "VALID").map((p) => p.id);

          break;

        default:
          this.selection = [];

          break;
      }

      this.goToStep(STEPS.SELECTION);
    },

    onPlayersSelected() {
      if (this.selection.length) {
        this.goToStep(STEPS.CREATION);
      }
    },
    endDateOptionsFn(endDate) {
      return differenceInDays(new Date(endDate), this.extractDateTimeString(this.startDate)) >= 0;
    },
    async onCreateEvent() {
      try {
        this.$refs.form.validate().then(async (success) => {
          if (success) {
            this.eventCreationLoading = true;

            const { data } = await this.$apollo.mutate({
              mutation: CREATE_EVENT_MUTATION,
              variables: {
                teamId: this.teamId,
                name: this.name,
                type: this.selectedEventType,
                selection: this.selection,
                startDate: this.isLiveScenario ? undefined : this.extractDateTimeString(this.startDate),
                endDate: this.isLiveScenario ? undefined : this.extractDateTimeString(this.endDate),
              },
            });
            const event = data.createEvent;

            this.$emit("created", event);
          } else {
            // oh no, user has filled in
            // at least one invalid value
          }
        });
      } catch (error) {
        this.eventCreationLoading = false;
      }
    },
  },
  apollo: {
    players: {
      query: TEAM_PLAYERS_QUERY,
      variables() {
        return {
          teamId: this.teamId,
        };
      },
      update({ team }) {
        return team.players;
      },
    },
  },
};
</script>
