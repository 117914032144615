import enUS from "./en-US.json";
import enGB from "./en-GB.json";
import frFR from "./fr-FR.json";
import esES from "./es-ES.json";
import itIT from "./it-IT.json";
import deDE from "./de-DE.json";
import langFr from "quasar/lang/fr";
import langEnUs from "quasar/lang/en-US";
import langEs from "quasar/lang/es";
import langIt from "quasar/lang/it";
import langEnGb from "quasar/lang/en-GB";
import langDe from "quasar/lang/de";

export const messages = {
  "en-US": enUS,
  "fr-FR": frFR,
  "en-GB": enGB,
  "es-ES": esES,
  "it-IT": itIT,
  "de-DE": deDE,
};

export const langs = {
  "en-US": langEnUs,
  "fr-FR": langFr,
  "en-GB": langEnGb,
  "es-ES": langEs,
  "it-IT": langIt,
  "de-DE": langDe,
};
