import gql from "graphql-tag";

export const sessionFragment = gql`
  fragment sessionFragment on Session {
    id
    playerId
    periodId
    startDate
    endDate
  }
`;

export const CREATE_SESSION_MUTATION = gql`
  mutation createSession(
    $eventId: ID!
    $playerId: ID!
    $periodId: ID
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    createSession(
      eventId: $eventId
      playerId: $playerId
      periodId: $periodId
      startDate: $startDate
      endDate: $endDate
    ) {
      ...sessionFragment
    }
  }
  ${sessionFragment}
`;

export const UPDATE_SESSION_MUTATION = gql`
  mutation updateSession(
    $eventId: ID!
    $sessionId: ID!
    $session: SessionInput
  ) {
    updateSession(eventId: $eventId, sessionId: $sessionId, session: $session) {
      ...sessionFragment
    }
  }
  ${sessionFragment}
`;

export const DELETE_SESSION_MUTATION = gql`
  mutation deleteSession($eventId: ID!, $sessionId: ID!) {
    deleteSession(eventId: $eventId, sessionId: $sessionId)
  }
`;

export const SET_PERIOD_SESSIONS_MUTATION = gql`
  mutation setPeriodSessions(
    $eventId: ID!
    $periodId: ID!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    setPeriodSessions(
      eventId: $eventId
      periodId: $periodId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      sessions {
        ...sessionFragment
      }
    }
  }
  ${sessionFragment}
`;

export const DELETE_PERIOD_SESSIONS_MUTATION = gql`
  mutation deletePeriodSessions($eventId: ID!, $periodId: ID!) {
    deletePeriodSessions(eventId: $eventId, periodId: $periodId) {
      id
      sessions {
        ...sessionFragment
      }
    }
  }
  ${sessionFragment}
`;
