{
  "accel": "Accel",
  "accel.history": "Accel riepilogo",
  "action": "Azione",
  "actions": "Azioni",
  "activity": "Attività",
  "analyses": "Analisi",
  "auto": "Auto",
  "average": "Media",
  "cancel": "Annulla",
  "charts": "Grafici",
  "clipboard.copy_msg": "Copiato negli appunti!",
  "club.create": "Creare un club",
  "club.create.msg": "Inserisci il nome del club che desideri creare. \nSarai il proprietario di questo club.",
  "club.create.warning_msg": "Questo processo proverà a creare un account con la tua e-mail nel nostro fornitore di dispositivi partner.",
  "club.member.add": "Aggiungi membro",
  "club.member.add_msg": "Inserisci l'e-mail dell'utente che desideri aggiungere.",
  "club.member.edit_access": "Modifica accesso",
  "club.member.edit_access_msg": "Seleziona l'accesso",
  "club.member.remove": "Rimuovi membro",
  "club.member.remove_msg": "Sei sicuro di voler rimuovere questo membro?",
  "club.members": "Membri del club",
  "club.name": "Nome del club",
  "club.subscription.active": "Abbonamento attivo",
  "club.subscription.not_active_msg": "Il tuo abbonamento al club non è attivo. \nSi prega di contattare il nostro team per attivarlo.",
  "coach": "Allenatore",
  "columns": "Colonne",
  "compare": "Confrontare",
  "compute": "Calcolare",
  "compute.range.name": "Nome dell'intervallo",
  "compute.ranges.accel": "Intervalli accel",
  "compute.ranges.speed": "Gamma di velocità",
  "compute.warning_msg": "Calcola i record dei giocatori in base alle sessioni. \nNiente panico, questa azione richiederà del tempo!",
  "confirm": "Conferma",
  "connected": "Collegato",
  "connection": "Connessione",
  "dashboard": "Dashboard",
  "data": "Dati",
  "day": "Giorno",
  "decel": "Decel",
  "demo_mode": "Modalità demo",
  "device.add": "Aggiungi device",
  "device.add.enter_serial": "Immettere il numero di serie del device",
  "device.add.enter_serial_msg": "Puoi trovare il numero di serie sul retro del device",
  "device.assign": "Assegnare",
  "device.assign._confirm_msg": "Sei sicuro di voler assegnare questo device a questo giocatore?",
  "device.externalId": "Id del partner",
  "device.fwVersion": "Versione del firmware",
  "device.hwVersion": "Versione hardware",
  "device.serial": "Numero di serie",
  "device.unassign": "Annulla assegnazione",
  "device.unassign.confirm_msg": "Sei sicuro di voler annullare l'assegnazione di questo device?",
  "device.usb.connect": "Connetti il ​​device",
  "device.usb.discover": "Scopri i devices USB",
  "devices": "Devices",
  "devices.sync": "Sincronizza i devices",
  "disconnected": "Disconnesso",
  "distance": "Distanza",
  "docking_station": "Docking station",
  "duration": "Durata",
  "email": "E-mail",
  "endDate": "Data di fine",
  "error_codes.ASI_SERVICE_BULK_UPLOAD_INVALID_FILE": "File non valido per il caricamento collettivo del servizio ASI",
  "error_codes.ASI_SERVICE_DEVICE_ALREADY_OWENED": "Dispositivo tagliando ASI già posseduto",
  "error_codes.ASI_SERVICE_DEVICE_NOT_ALLOWED": "Dispositivo di servizio ASI non consentito",
  "error_codes.ASI_SERVICE_DEVICE_NOT_FOUND": "Dispositivo di servizio ASI non trovato",
  "error_codes.ASI_SERVICE_INVALID_CREDENTIALS": "Credenziali del servizio ASI non valide",
  "error_codes.ASI_SERVICE_MISSING_CREDENTIALS": "Mancano le credenziali del servizio ASI",
  "error_codes.ASI_SERVICE_NOT_CONFIGURED": "Servizio ASI non configurato",
  "error_codes.ASI_SERVICE_REFRESH_FAILED": "Aggiornamento del servizio ASI non riuscito",
  "error_codes.ASI_SERVICE_UNKNOW_ERROR": "Errore sconosciuto del servizio ASI",
  "error_codes.ASI_SERVICE_USERNAME_MISMATCH": "Mancata corrispondenza del nome utente del servizio ASI",
  "error_codes.CANNOT_ASSIGN_DEVICE_TO_PLAYER": "Impossibile assegnare il device al giocatore",
  "error_codes.CANNOT_DELETE_EVENT": "Impossibile eliminare l'evento",
  "error_codes.CANNOT_EDIT_MANAGER_ACCESS": "Impossibile modificare l'accesso gestore",
  "error_codes.CLUB_NOT_FOUND": "Circolo non trovato",
  "error_codes.CLUB_USER_NOT_FOUND": "Utente del club non trovato",
  "error_codes.DEVICE_ASSIGNED": "Device assegnato",
  "error_codes.DEVICE_NOT_FOUND": "Device non trovato",
  "error_codes.DEVICE_TYPE_NOT_SUPPORTED": "Tipo di device non supportato",
  "error_codes.EMAIL_ALREADY_EXISTS": "Email già esistente",
  "error_codes.EMAIL_ALREADY_SENT": "Email già inviata",
  "error_codes.EVENT_ALREADY_EXISTS": "L'evento esiste già",
  "error_codes.EVENT_COMPUTE_RUNNING": "Calcolo dell'evento in esecuzione",
  "error_codes.EVENT_FINISHED": "Evento terminato",
  "error_codes.EVENT_LOCKED": "Evento bloccato",
  "error_codes.EVENT_NOT_FINISHED": "Evento non terminato",
  "error_codes.EVENT_NOT_FOUND": "Evento non trovato",
  "error_codes.EVENT_NOT_STARTED": "Evento non iniziato",
  "error_codes.EVENT_STARTED": "Evento iniziato",
  "error_codes.INJURY_NOT_FOUND": "Lesione non trovata",
  "error_codes.INTERNAL_SERVER_ERROR": "Errore interno del server",
  "error_codes.INVALID_CREDENTIALS": "Credenziali non valide",
  "error_codes.INVALID_TOKEN": "Gettone non valido",
  "error_codes.MEMBER_LIMIT_REACHED": "Limite membri raggiunto",
  "error_codes.NO_CLUB_FOR_USER": "Utente sense club",
  "error_codes.NO_CURRENT_SEASON": "Nessuna stagione in corso",
  "error_codes.PERIODS_OVERLAP": "I periodi si sovrappongono",
  "error_codes.PERIOD_FINISHED": "Periodo terminato",
  "error_codes.PERIOD_NOT_FOUND": "Periodo non trovato",
  "error_codes.PERIOD_OVERLAP": "Sovrapposizione di periodi",
  "error_codes.PERIOD_STARTED": "Periodo iniziato",
  "error_codes.PLAYER_HAS_NO_PICTURE": "Il giocatore non ha immagine",
  "error_codes.PLAYER_HAS_TEAM": "Il giocatore ha una squadra",
  "error_codes.PLAYER_LIMIT_REACHED": "Limite di giocatori raggiunto",
  "error_codes.PLAYER_NOT_FOUND": "Giocatore non trovato",
  "error_codes.PLAYER_NOT_SELECTED": "Giocatore non selezionato",
  "error_codes.PLAYER_SELECTED": "Giocatore selezionato",
  "error_codes.RANGE_LIMIT_EXCEEDED": "Limite dell'intervallo di dati superato",
  "error_codes.RANGE_LIMIT_REACHED": "Limite dell'intervallo dati raggiunto",
  "error_codes.RANGE_NOT_FOUND": "Intervallo di dati non trovato",
  "error_codes.RECORD_NOT_FOUND": "Inserimento non trovato",
  "error_codes.SEASON_NOT_FOUND": "Stagione non trovata",
  "error_codes.SELECTION_NOT_FOUND": "Selezione non trovata",
  "error_codes.SERVER_UNREACHABLE": "Server irraggiungibile",
  "error_codes.SESSION_NOT_FOUND": "Sessione non trovata",
  "error_codes.TEAM_LIMIT_REACHED": "Limite della squadra raggiunto",
  "error_codes.TEAM_NOT_FOUND": "Squadra non trovata",
  "error_codes.UNAUTHORIZED": "Non autorizzato",
  "error_codes.UNEXPECTED_ERROR": "Errore inaspettato",
  "error_codes.UNKNOWN_ERROR": "Errore sconosciuto",
  "error_codes.USER_ALREADY_EXISTS": "L'utente esiste già",
  "error_codes.USER_HAS_CLUB": "L'utente ha un club",
  "error_codes.USER_NOT_FOUND": "Utente non trovato",
  "error_codes.USER_NOT_VERIFIED": "Utente non verificato",
  "errors": "Errori",
  "event.compute": "Calcola evento",
  "event.create": "Crea Evento",
  "event.creation": "Creazione di eventi",
  "event.export.download.fieldwiz_analyze_CSV": "Scaricare FieldWiz Analizza CSV",
  "event.export.download_report": "Scaricare il rapporto",
  "event.export.empty_report": "Rapporto vuoto",
  "event.export.reset_report": "Reimpostare il rapporto",
  "event.name": "Nome dell'evento",
  "event.period.create": "Creare un periodo",
  "event.period.create.force": "Forza le sessioni per tutti i giocatori in questo periodo",
  "event.period.new": "Nuovo periodo",
  "event.period.start": "Periodo di inizio",
  "event.period.stop": "Periodo di sosta",
  "event.quick_import": "Importazione rapida",
  "event.start": "Inizio l'evento",
  "event.stop": "Fermare l'evento",
  "event.type": "Tipo di evento",
  "event.type.INDIVIDUAL_MATCH": "Partita individuale",
  "event.type.INDIVIDUAL_TRAINING": "Allenamento individuale",
  "event.type.MATCH": "Partita",
  "event.type.TRAINING": "Allenamento",
  "exit": "Uscita",
  "exit_confirm_msg": "Sei sicuro di voler uscire?",
  "export": "Esportare",
  "friday": "Venerdì",
  "global.assign": "Assegnare",
  "global.assigned": "Assegnato",
  "global.back": "Indietro",
  "global.cancel": "Annulla",
  "global.compute": "Calcolare",
  "global.contact_us": "Contattaci",
  "global.delete": "Eliminare",
  "global.finish": "Fine",
  "global.hybrid": "Ibrido",
  "global.live": "Live",
  "global.loading": "Caricamento",
  "global.next": "Prossimo",
  "global.offline": "disconnesso",
  "global.online": "in linea",
  "global.options": "Opzioni",
  "global.period": "Periodo",
  "global.scenario": "Scenario",
  "global.start": "Inizio",
  "global.stop": "Fermare",
  "global.success": "Successo",
  "global.system": "Sistema",
  "global.unassign": "Annulla assegnazione",
  "global.view": "Visualizzazione",
  "health": "Salute",
  "heartRate": "Frequenza cardiaco",
  "heatmap": "Heat map",
  "home": "Home",
  "hr": "FC",
  "import": "Importare",
  "import.usb-dkg": "Importazione USB/DKG",
  "import.usb.close_modal": "Chiudi modale",
  "import.usb.create_file_error": "Errore durante la creazione del file",
  "import.usb.create_file_msg": "Creazione file da caricare...",
  "import.usb.data_upload": "Caricare dei dati del device",
  "import.usb.detected_browser": "Browser rilevato: {name}",
  "import.usb.download_device_data_msg": "Download dei dati del device...",
  "import.usb.download_error": "Errore durante il download dei dati dal device",
  "import.usb.download_msg": "Download dei dati dal device",
  "import.usb.erase_error": "Errore durante la cancellazione dei dati dal device",
  "import.usb.erase_msg": "Cancellazione dei dati dal device",
  "import.usb.file_upload_error": "Errore durante il caricamento del file",
  "import.usb.file_upload_msg": "Caricare file",
  "import.usb.job_error": "Importazione dei dati del device fallita!",
  "import.usb.job_error_msg": "Importazione dei dati del device non riuscita, riprova più tardi.",
  "import.usb.job_state_updated_msg": "Stato elaborazione dati dispositivo: {state}",
  "import.usb.job_success": "Importazione dei dati del dispositivo terminata!",
  "import.usb.link_copy_success_msg": "URL copiato negli appunti",
  "import.usb.no_data": "Nessun dato dal dispositivo",
  "import.usb.processing_job": "Processing data",
  "import.usb.success_msg": "Data caricare con successo",
  "import.usb.unsupported_browser_msg": "Il caricamento USB non è disponibile sul browser corrente, si consiglia di utilizzare il browser desktop Google Chrome.",
  "import.usb.uploading_file_msg": "Caricamento del file di data del device in corso...",
  "intervals": "Intervalli",
  "lan.it-IT": "Italiano",
  "lang.de-DE": "Tedesco",
  "lang.en-GB": "Inglese",
  "lang.en-US": "Inglese",
  "lang.es-ES": "Español",
  "lang.fr-FR": "francese",
  "language": "Lingua",
  "league.ACADEMY": "Accademia",
  "league.INTERNATIONAL": "Internazionale",
  "league.PRO1": "Pro1",
  "league.PRO2": "Pro2",
  "light": "Luce",
  "list": "Elenco",
  "live": "Live",
  "load]": "Caricare",
  "login": "Login",
  "login.caption": "Per favore login per continuare",
  "manager": "Manager",
  "max": "Massimo",
  "monday": "Lunedi",
  "name": "Nome",
  "night": "Notte",
  "off": "Off",
  "offline": "Offline",
  "on": "On",
  "password": "Password",
  "password_reset.error": "Errore durante il ripristino del password",
  "player.add": "Aggiungi giocatore",
  "player.birthdate": "Data di nascita",
  "player.delete": "Elimina {playerName}",
  "player.delete.msg": "Sei sicuro di voler eliminare {playerName}?",
  "player.firstName": "Nome",
  "player.footRef": "Piede",
  "player.footRef.LEFT": "Sinistra",
  "player.footRef.RIGHT": "Destra",
  "player.gender": "Genere",
  "player.gender.MAN": "Uomo",
  "player.gender.WOMAN": "Donna",
  "player.gps_profile": "profilo GPS",
  "player.height": "altezza",
  "player.hrMax": "FC max",
  "player.lastName": "Cognome",
  "player.number": "Numero",
  "player.position": "Posizione",
  "player.position.ASSISTANT_REFEREE": "Assistente arbitro",
  "player.position.CENTER_BACK": "Difensore centrale",
  "player.position.CENTRE_THREE_QUARTER": "Center Three Quarter",
  "player.position.DEFENSIVE_MIDFIELDER": "Centrocampista difensivo",
  "player.position.FLANKER": "Flanker",
  "player.position.FLY_HALF": "Fly Half",
  "player.position.FORWARD": "Attacante",
  "player.position.FULL_BACK": "Difensore lateral",
  "player.position.GOALKEEPER": "Portiere",
  "player.position.HOOKER": "Hooker",
  "player.position.LOCK": "Lock",
  "player.position.MIDFIELDER": "Centrocampista",
  "player.position.OFFENSIVE_MIDFIELDER": "Centrocampista offensivo",
  "player.position.PROP": "Prop",
  "player.position.REFEREE": "Arbitro",
  "player.position.SCRUM_HALF": "Scrum half",
  "player.position.SIDE_MIDFIELDER": "Centrocampista laterale",
  "player.position.THIRD_LINE": "Third Line",
  "player.position.WINGER": "Ala",
  "player.profile": "Profilo del giocatore",
  "player.profile.AEROBIC": "Aerobico",
  "player.profile.INTERMEDIATE": "Intermedio",
  "player.profile.SPEED": "Velocità",
  "player.speedMax": "massima velocità",
  "player.status": "Stato",
  "player.status.ABSENT": "Assente",
  "player.status.CARE": "Cura",
  "player.status.FITNESS_REHAB": "Riabilitazione fisica",
  "player.status.INJURED": "Ferito",
  "player.status.PHYSIO_REHAB": "Riabilitazione fisioterapica",
  "player.status.RECOVERED": "Recuperato",
  "player.status.RECOVERY": "Recupero",
  "player.status.SELECTION": "Selezione",
  "player.status.SICK": "Malato",
  "player.status.VALID": "Valido",
  "player.status.select": "Seleziona lo stato del giocatore",
  "player.update": "Aggiorna giocatore",
  "player.weight": "Peso",
  "players": "Giocatori",
  "profile": "Profilo",
  "range.dates": "Intervallo di date",
  "range.from": "Da",
  "range.to": "Per",
  "ratio": "Rapporto",
  "record": "Record",
  "refresh": "Ricaricare",
  "register": "Registrati",
  "register.caption": "Registra un nuovo account",
  "report": "Rapporto",
  "reset": "Ripristina",
  "reset_password": "Resetta la password",
  "reset_password.caption": "Reimposta la tua password",
  "reset_password.error": "Errore durante il ripristino della password",
  "reset_password.success": "Email di reimpostazione password inviata",
  "rule.email": "Inserisci una email valida",
  "rule.password": "La password deve essere lunga almeno 8 caratteri e contenere almeno una lettera maiuscola, una lettera minuscola e un numero",
  "rule.required": "Questo campo è obbligatorio",
  "saturday": "Sabato",
  "search": "Ricerca",
  "season": "Stagione",
  "season.create": "Nuova stagione",
  "season.endDate": "Data di fine stagione",
  "season.league": "Lega",
  "season.name": "Nome della stagione",
  "season.startDate": "Data di inizio stagione",
  "seasons": "Le stagioni",
  "selection": "Selezione",
  "selection.slide_left_to_unselect": "Scorri a sinistra per deselezionare il giocatore",
  "selection.slide_right_to_select": "Scorri verso destra per selezionare il giocatore",
  "selection.update": "Aggiorna la selezione per {playerName}",
  "sessions": "Sessioni",
  "settings": "Impostazioni",
  "speed": "Velocità",
  "sprint": "Sprint",
  "sprint.history": "Storia dello sprint",
  "sprints": "Sprint",
  "sprints.weekly_stats": "Statistiche sugli sprint settimanali",
  "startDate": "Data d'inizio",
  "statistics": "Statistiche",
  "subscription": "Sottoscrizione",
  "subscription.level": "Livello di abbonamento",
  "subscription.limits.members": "Limite di membri",
  "subscription.limits.players": "Limite dei giocatori",
  "subscription.limits.teams": "Limite squadre",
  "subscription.plan": "Piano di abbonamento",
  "subscription.services": "Servizi in abbonamento",
  "subscriptions": "Abbonamenti",
  "sunday": "Domenica",
  "team": "Squadra",
  "team.create": "Crea squadra",
  "team.delete": "Elimina squadra",
  "team.delete_keepPlayers": "Mantieni i giocatori",
  "team.delete_keepPlayers_msg": "Vuoi eliminare i giocatori della squadra?",
  "team.name": "Nome della squadra",
  "team.players.assign": "Assegna a {teamName}",
  "team.players.assign_confirm": "Sei sicuro di voler assegnare {playerNames} a {teamName}?",
  "team.players.unassign": "Annulla l'assegnazione da {teamName}",
  "team.players.unassign_confirm": "Sei sicuro di voler annullare l'assegnazione di {playerNames} a {teamName}?",
  "team.update": "Aggiorna squadra",
  "teams": "Squadre",
  "thursday": "Giovedì",
  "time": "Tempo",
  "timeline": "Sequenza temporale",
  "tuesday": "Martedì",
  "type": "Tipo",
  "update": "Aggiornare",
  "upload": "Caricamento",
  "upload.no_device": "Nessun dispositivo",
  "upload.no_jobs": "Nessun lavoro",
  "usb": "USB",
  "user": "Utente",
  "user.copy_email": "Copia la posta elettronica",
  "user.new": "Nuovo utente",
  "user.verification": "Verifica email",
  "user.verification_msg": "Per accedere all'app, devi verificare la tua email.",
  "users": "Utenti",
  "verification_msg": "Per verificare il tuo account, fai clic sul link inviato alla tua email. \nSe hai già effettuato la verifica, fai clic sul pulsante in basso per accedere all'app. \nSe non trovi l'e-mail, controlla la cartella della posta indesiderata.",
  "verified": "Verificato",
  "verify": "Verificare",
  "version": "Versione",
  "wednesday": "Mercoledì",
  "week": "Settimana",
  "welcome_msg": "Benvenuto nell'applicazione! \nper iniziare a usarlo devi crearne uno nuovo o unirti a un club esistente chiedendo a un manager del club di aggiungere la tua email.",
  "workload": "Carico di lavoro"
}
