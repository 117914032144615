{
  "accel": "Accel",
  "accel.history": "Historique Accel",
  "action": "Action",
  "actions": "Actions",
  "activity": "Activité",
  "analyses": "Analyses",
  "auto": "Auto",
  "average": "Moyenne",
  "cancel": "Annuler",
  "charts": "Graphiques",
  "clipboard.copy_msg": "Copié dans le presse-papier !",
  "club.create": "Créer un club",
  "club.create.msg": "Saisissez le nom du club que vous souhaitez créer",
  "club.create.warning_msg": "Ce processus tentera de créer un compte avec votre adresse e-mail chez notre fournisseur d'appareils partenaire.",
  "club.member.add": "Ajouter un membre",
  "club.member.add_msg": "Saisissez l'e-mail de l'utilisateur que vous souhaitez ajouter.",
  "club.member.edit_access": "Modifier l'accès",
  "club.member.edit_access_msg": "Sélectionnez l'accès",
  "club.member.remove": "Supprimer un membre",
  "club.member.remove_msg": "Voulez-vous vraiment supprimer ce membre ?",
  "club.members": "Membres du club",
  "club.name": "Nom du club",
  "club.subscription.active": "Abonnement actif",
  "club.subscription.not_active_msg": "Votre abonnement au club n'est pas actif. \nVeuillez contacter notre équipe pour l'activer.",
  "coach": "Entraîneur",
  "columns": "Colonnes",
  "compare": "Comparer",
  "compute": "Calculer",
  "compute.range.name": "Nom de la plage",
  "compute.ranges.accel": "Plages d'accélération",
  "compute.ranges.speed": "Plages de vitesse",
  "compute.warning_msg": "Calculez les enregistrements des joueurs en fonction des sessions. \nPas de panique cette action prendra du temps !",
  "confirm": "Confirmer",
  "connected": "Connecté",
  "connection": "Connexion",
  "dashboard": "Tableau de bord",
  "data": "Données",
  "day": "Jour",
  "decel": "Decel",
  "demo_mode": "Mode démo",
  "device.add": "Ajouter un device",
  "device.add.enter_serial": "Entrez le numéro de série du device",
  "device.add.enter_serial_msg": "Vous pouvez trouver le numéro de série à l'arrière du device",
  "device.assign": "Attribuer un device",
  "device.assign._confirm_msg": "Voulez-vous vraiment attribuer ce device ?",
  "device.externalId": "ID partenaire",
  "device.fwVersion": "Version du firmware",
  "device.hwVersion": "Version matérielle",
  "device.serial": "Numéro de série",
  "device.unassign": "Désattribuer le device",
  "device.unassign.confirm_msg": "Voulez-vous vraiment annuler l'attribution de ce device ?",
  "device.usb.connect": "Connecter le device",
  "device.usb.discover": "Découvrez les périphériques USB",
  "devices": "Dispositifs",
  "devices.sync": "Synchroniser les devices",
  "disconnected": "Déconnecté",
  "distance": "Distance",
  "docking_station": "Docking Station",
  "duration": "Durée",
  "email": "E-mail",
  "endDate": "Date de fin",
  "error_codes.ASI_SERVICE_BULK_UPLOAD_INVALID_FILE": "Fichier invalide de téléchargement groupé du service ASI",
  "error_codes.ASI_SERVICE_DEVICE_ALREADY_OWENED": "Device de service ASI déjà possédé",
  "error_codes.ASI_SERVICE_DEVICE_NOT_ALLOWED": "Device de service ASI non autorisé",
  "error_codes.ASI_SERVICE_DEVICE_NOT_FOUND": "Device de service ASI introuvable",
  "error_codes.ASI_SERVICE_INVALID_CREDENTIALS": "Informations d'identification non valides pour le service ASI",
  "error_codes.ASI_SERVICE_MISSING_CREDENTIALS": "Informations d'identification manquantes pour le service ASI",
  "error_codes.ASI_SERVICE_NOT_CONFIGURED": "Service ASI non configuré",
  "error_codes.ASI_SERVICE_REFRESH_FAILED": "Échec de l'actualisation du service ASI",
  "error_codes.ASI_SERVICE_UNKNOW_ERROR": "Erreur inconnue du service ASI",
  "error_codes.ASI_SERVICE_USERNAME_MISMATCH": "Non-concordance du nom d'utilisateur du service ASI",
  "error_codes.CANNOT_ASSIGN_DEVICE_TO_PLAYER": "Impossible d'attribuer le device au joueur",
  "error_codes.CANNOT_DELETE_EVENT": "Impossible de supprimer l'événement",
  "error_codes.CANNOT_EDIT_MANAGER_ACCESS": "Impossible de modifier l'accès du manager",
  "error_codes.CLUB_NOT_FOUND": "Club introuvable",
  "error_codes.CLUB_USER_NOT_FOUND": "Utilisateur du club introuvable",
  "error_codes.DEVICE_ASSIGNED": "Device attribué",
  "error_codes.DEVICE_NOT_FOUND": "Device non trouvé",
  "error_codes.DEVICE_TYPE_NOT_SUPPORTED": "Device non pris en charge",
  "error_codes.EMAIL_ALREADY_EXISTS": "l'email existe déjà",
  "error_codes.EMAIL_ALREADY_SENT": "E-mail déjà envoyé",
  "error_codes.EVENT_ALREADY_EXISTS": "L'événement existe déjà",
  "error_codes.EVENT_COMPUTE_RUNNING": "Calcul d'événement en cours d'exécution",
  "error_codes.EVENT_FINISHED": "Événement terminé",
  "error_codes.EVENT_LOCKED": "Événement verrouillé",
  "error_codes.EVENT_NOT_FINISHED": "Événement non terminé",
  "error_codes.EVENT_NOT_FOUND": "Evénement introuvable",
  "error_codes.EVENT_NOT_STARTED": "Événement non démarré",
  "error_codes.EVENT_STARTED": "L'événement a débuté",
  "error_codes.INJURY_NOT_FOUND": "Blessure non retrouvée",
  "error_codes.INTERNAL_SERVER_ERROR": "Erreur interne du serveur",
  "error_codes.INVALID_CREDENTIALS": "Informations d'identification invalides",
  "error_codes.INVALID_TOKEN": "Token invalide",
  "error_codes.MEMBER_LIMIT_REACHED": "Limite de membres atteinte",
  "error_codes.NO_CLUB_FOR_USER": "Pas de club pour l'utilisateur",
  "error_codes.NO_CURRENT_SEASON": "Pas de saison en cours",
  "error_codes.PERIODS_OVERLAP": "Les périodes se chevauchent",
  "error_codes.PERIOD_FINISHED": "Période terminée",
  "error_codes.PERIOD_NOT_FOUND": "Période introuvable",
  "error_codes.PERIOD_OVERLAP": "Chevauchement de période",
  "error_codes.PERIOD_STARTED": "Période commencée",
  "error_codes.PLAYER_HAS_NO_PICTURE": "Le joueur n'a pas de photo",
  "error_codes.PLAYER_HAS_TEAM": "Le joueur a une équipe",
  "error_codes.PLAYER_LIMIT_REACHED": "Limite de joueurs atteinte",
  "error_codes.PLAYER_NOT_FOUND": "Joueur non trouvé",
  "error_codes.PLAYER_NOT_SELECTED": "Joueur non sélectionné",
  "error_codes.PLAYER_SELECTED": "Joueur sélectionné",
  "error_codes.RANGE_LIMIT_EXCEEDED": "Limite de plage de données dépassée",
  "error_codes.RANGE_LIMIT_REACHED": "Limite de plage de données atteinte",
  "error_codes.RANGE_NOT_FOUND": "Plage de données introuvable",
  "error_codes.RECORD_NOT_FOUND": "Enregistrement non trouvé",
  "error_codes.SEASON_NOT_FOUND": "Saison introuvable",
  "error_codes.SELECTION_NOT_FOUND": "Sélection introuvable",
  "error_codes.SERVER_UNREACHABLE": "Serveur inaccessible",
  "error_codes.SESSION_NOT_FOUND": "Séance introuvable",
  "error_codes.TEAM_LIMIT_REACHED": "Limite d'équipe atteinte",
  "error_codes.TEAM_NOT_FOUND": "Equipe introuvable",
  "error_codes.UNAUTHORIZED": "Non autorisé",
  "error_codes.UNEXPECTED_ERROR": "Erreur inattendue",
  "error_codes.UNKNOWN_ERROR": "Erreur inconnue",
  "error_codes.USER_ALREADY_EXISTS": "L'utilisateur existe déjà",
  "error_codes.USER_HAS_CLUB": "L'utilisateur a un club",
  "error_codes.USER_NOT_FOUND": "Utilisateur non trouvé",
  "error_codes.USER_NOT_VERIFIED": "Utilisateur non vérifié",
  "errors": "Erreurs",
  "event.compute": "Calcul de l'événement",
  "event.create": "Créer un évènement",
  "event.creation": "Création d'événement",
  "event.export.download.fieldwiz_analyze_CSV": "Télécharger FieldWiz Analyse CSV",
  "event.export.download_report": "Télécharger le rapport",
  "event.export.empty_report": "Rapport vide",
  "event.export.reset_report": "Réinitialiser le rapport",
  "event.name": "Nom de l'événement",
  "event.period.create": "Créer une période",
  "event.period.create.force": "Forcer les sessions pour tous les joueurs sur cette période",
  "event.period.new": "Nouvelle période",
  "event.period.start": "Début Période",
  "event.period.stop": "Arrêt Période",
  "event.quick_import": "Importation rapide",
  "event.start": "Démarrer l'événement",
  "event.stop": "Arrêter l'événement",
  "event.type": "Type d'évènement",
  "event.type.INDIVIDUAL_MATCH": "Match individuel",
  "event.type.INDIVIDUAL_TRAINING": "Entraînement individuel",
  "event.type.MATCH": "Match",
  "event.type.TRAINING": "Entraînement",
  "exit": "Sortir",
  "exit_confirm_msg": "Êtes-vous sûr de vouloir vous déconnecter?",
  "export": "Exporter",
  "friday": "Vendredi",
  "global.assign": "Attribuer",
  "global.assigned": "Attribué",
  "global.back": "Retour",
  "global.cancel": "Annuler",
  "global.compute": "Calculer",
  "global.contact_us": "Contactez-nous",
  "global.delete": "Supprimer",
  "global.finish": "Finir",
  "global.hybrid": "Hybride",
  "global.live": "En direct",
  "global.loading": "Chargement",
  "global.next": "Suivant",
  "global.offline": "Hors ligne",
  "global.online": "En ligne",
  "global.options": "Choix",
  "global.period": "Période",
  "global.scenario": "Scénario",
  "global.start": "Commencer",
  "global.stop": "Arrêt",
  "global.success": "Succès",
  "global.system": "Système",
  "global.unassign": "Annuler l'attribution",
  "global.view": "Voir",
  "health": "Santé",
  "heartRate": "Rythme cardiaque",
  "heatmap": "Heat Map",
  "home": "Home",
  "hr": "FC",
  "import": "Importer",
  "import.usb-dkg": "Importation USB/DKG",
  "import.usb.close_modal": "Fermer modal",
  "import.usb.create_file_error": "Erreur lors de la création du fichier",
  "import.usb.create_file_msg": "Création du fichier à télécharger...",
  "import.usb.data_upload": "Téléchargement des données de l'appareil",
  "import.usb.detected_browser": "Navigateur détecté : {name}",
  "import.usb.download_device_data_msg": "Téléchargement des données de l'appareil...",
  "import.usb.download_error": "Erreur lors du téléchargement des données depuis l'appareil",
  "import.usb.download_msg": "Téléchargement de données depuis l'appareil",
  "import.usb.erase_error": "Erreur lors de la suppression des données de l'appareil",
  "import.usb.erase_msg": "Effacement des données de l'appareil",
  "import.usb.file_upload_error": "Erreur lors du téléchargement du fichier",
  "import.usb.file_upload_msg": "Télécharger le fichier",
  "import.usb.job_error": "L'importation des données de l'appareil a échoué !",
  "import.usb.job_error_msg": "L'importation des données de l'appareil a échoué, veuillez réessayer plus tard.",
  "import.usb.job_state_updated_msg": "État du traitement des données de l'appareil : {state}",
  "import.usb.job_success": "Importation des données de l'appareil terminée !",
  "import.usb.link_copy_success_msg": "URL copiée dans le presse-papiers",
  "import.usb.no_data": "Aucune donnée de l'appareil",
  "import.usb.processing_job": "Données en cours",
  "import.usb.success_msg": "Données téléchargées avec succès",
  "import.usb.unsupported_browser_msg": "Le téléchargement USB n'est pas disponible sur le navigateur actuel, nous vous recommandons d'utiliser le navigateur de bureau Google Chrome.",
  "import.usb.uploading_file_msg": "Téléchargement du fichier de données de l'appareil...",
  "intervals": "Intervalles",
  "lan.it-IT": "Italien",
  "lang.de-DE": "Allemand",
  "lang.en-GB": "Anglais",
  "lang.en-US": "Anglais",
  "lang.es-ES": "Espagnol",
  "lang.fr-FR": "English",
  "language": "Langue",
  "league.ACADEMY": "Académie",
  "league.INTERNATIONAL": "International",
  "league.PRO1": "Pro1",
  "league.PRO2": "Pro2",
  "light": "Mode lumière",
  "list": "Liste",
  "live": "Live",
  "load]": "Charger",
  "login": "Connexion",
  "login.caption": "Merci de vous connecter pour continuer",
  "manager": "Manager",
  "max": "Max",
  "monday": "Lundi",
  "name": "Nom",
  "night": "Nuit",
  "off": "À l'arrêt",
  "offline": "Hors ligne",
  "on": "Sur",
  "password": "Mot de passe",
  "password_reset.error": "Erreur lors de la réinitialisation du mot de passe",
  "player.add": "Ajouter un joueur",
  "player.birthdate": "Date de naissance",
  "player.delete": "Supprimer {playerName}",
  "player.delete.msg": "Etes-vous sûr de vouloir supprimer {playerName} ?",
  "player.firstName": "Prénom",
  "player.footRef": "Pied",
  "player.footRef.LEFT": "Gauche",
  "player.footRef.RIGHT": "Droit",
  "player.gender": "Genre",
  "player.gender.MAN": "Homme",
  "player.gender.WOMAN": "Femme",
  "player.gps_profile": "Profil GPS",
  "player.height": "Taille",
  "player.hrMax": "FC max",
  "player.lastName": "Nom",
  "player.number": "Numéro",
  "player.position": "Position",
  "player.position.ASSISTANT_REFEREE": "Arbitre assistant",
  "player.position.CENTER_BACK": "Défenseur Central",
  "player.position.CENTRE_THREE_QUARTER": "Trois Quart Centre",
  "player.position.DEFENSIVE_MIDFIELDER": "Milieu défensif",
  "player.position.FLANKER": "Troisième ligne",
  "player.position.FLY_HALF": "Demi d'ouverture",
  "player.position.FORWARD": "Attaquant",
  "player.position.FULL_BACK": "Arrière Latéral",
  "player.position.GOALKEEPER": "Gardien de but",
  "player.position.HOOKER": "Talonneur",
  "player.position.LOCK": "Deuxième ligne",
  "player.position.MIDFIELDER": "Milieu de terrain",
  "player.position.OFFENSIVE_MIDFIELDER": "Milieu offensif",
  "player.position.PROP": "Première ligne",
  "player.position.REFEREE": "Arbitre",
  "player.position.SCRUM_HALF": "Demi de mêlée",
  "player.position.SIDE_MIDFIELDER": "Milieu excentré",
  "player.position.THIRD_LINE": "Troisième ligne",
  "player.position.WINGER": "Ailier",
  "player.profile": "Profil joueur",
  "player.profile.AEROBIC": "Aérobie",
  "player.profile.INTERMEDIATE": "Intermédiaire",
  "player.profile.SPEED": "Vitesse",
  "player.speedMax": "Vitesse maximale",
  "player.status": "Statut",
  "player.status.ABSENT": "Absent",
  "player.status.CARE": "Soins",
  "player.status.FITNESS_REHAB": "Réhab physique",
  "player.status.INJURED": "Blessée",
  "player.status.PHYSIO_REHAB": "Réhab kiné",
  "player.status.RECOVERED": "Rétabli",
  "player.status.RECOVERY": "Récupération",
  "player.status.SELECTION": "Sélection",
  "player.status.SICK": "Malade",
  "player.status.VALID": "Valide",
  "player.status.select": "Sélectionnez le statut du joueur",
  "player.update": "Mettre à jour le joueur",
  "player.weight": "Poids",
  "players": "Joueurs",
  "profile": "Profil",
  "range.dates": "Plage de dates",
  "range.from": "De",
  "range.to": "À",
  "ratio": "Ratio",
  "record": "Enregistrement",
  "refresh": "Rafraîchir",
  "register": "Enregistrer",
  "register.caption": "Enregistrer un nouveau compte",
  "report": "Rapport",
  "reset": "Réinitialiser",
  "reset_password": "Réinitialiser le mot de passe",
  "reset_password.caption": "Réinitialisez votre mot de passe",
  "reset_password.error": "Erreur lors de la réinitialisation du mot de passe",
  "reset_password.success": "E-mail de réinitialisation du mot de passe envoyé",
  "rule.email": "Veuillez entrer un email valide",
  "rule.password": "Le mot de passe doit comporter au moins 8 caractères et contenir au moins une lettre majuscule, une lettre minuscule et un chiffre",
  "rule.required": "Ce champ est obligatoire",
  "saturday": "Samedi",
  "search": "Chercher",
  "season": "Saison",
  "season.create": "Nouvelle saison",
  "season.endDate": "Date de fin de saison",
  "season.league": "Ligue",
  "season.name": "Nom de la saison",
  "season.startDate": "Date de début de saison",
  "seasons": "Saisons",
  "selection": "Sélection",
  "selection.slide_left_to_unselect": "Faites glisser vers la gauche pour désélectionner le joueur",
  "selection.slide_right_to_select": "Faites glisser vers la droite pour sélectionner un joueur",
  "selection.update": "Mettre à jour la sélection pour {playerName}",
  "sessions": "Séances",
  "settings": "Réglages",
  "speed": "Vitesse",
  "sprint": "Sprint",
  "sprint.history": "Historique des sprints",
  "sprints": "Sprints",
  "sprints.weekly_stats": "Suivi hebdomadaire des sprints",
  "startDate": "Date de début",
  "statistics": "Statistiques",
  "subscription": "Abonnement",
  "subscription.level": "Niveau d'abonnement",
  "subscription.limits.members": "Limite de membres",
  "subscription.limits.players": "Limite de joueurs",
  "subscription.limits.teams": "Limite d'équipes",
  "subscription.plan": "Plan d'abonnement",
  "subscription.services": "Services d'abonnement",
  "subscriptions": "Abonnements",
  "sunday": "Dimanche",
  "team": "Équipe",
  "team.create": "Créer une équipe",
  "team.delete": "Supprimer l'équipe",
  "team.delete_keepPlayers": "Conservez les joueurs",
  "team.delete_keepPlayers_msg": "Vous souhaitez supprimer les joueurs de l'équipe ?",
  "team.name": "Nom de l'équipe",
  "team.players.assign": "Attribuer à {teamName}",
  "team.players.assign_confirm": "Êtes-vous sûr de vouloir attribuer {playerNames} à {teamName} ?",
  "team.players.unassign": "Annuler l'attribution de {teamName}",
  "team.players.unassign_confirm": "Êtes-vous sûr de vouloir annuler l'attribution de {playerNames} à {teamName} ?",
  "team.update": "Mettre à jour l'équipe",
  "teams": "Équipes",
  "thursday": "Jeudi",
  "time": "Temps",
  "timeline": "Chronologie",
  "tuesday": "Mardi",
  "type": "Type",
  "update": "Mise à jour",
  "upload": "Télécharger",
  "upload.no_device": "Aucun dispositif",
  "upload.no_jobs": "Aucun emploi",
  "usb": "USB",
  "user": "Utilisateur",
  "user.copy_email": "Copier l'e-mail",
  "user.new": "Nouvel utilisateur",
  "user.verification": "Vérification de l'E-mail",
  "user.verification_msg": "Pour accéder à l'application, vous devez vérifier votre adresse e-mail.",
  "users": "Utilisateurs",
  "verification_msg": "Pour vérifier votre compte, veuillez cliquer sur le lien envoyé à votre adresse e-mail. \nSi vous avez déjà effectué la vérification, veuillez cliquer sur le bouton ci-dessous pour accéder à l'application. \nSi vous ne trouvez pas l'e-mail, veuillez vérifier votre dossier spam.",
  "verified": "Vérifié",
  "verify": "Vérifier",
  "version": "Version",
  "wednesday": "Mercredi",
  "week": "Semaine",
  "welcome_msg": "Bienvenue sur l'appli ! \npour commencer à l'utiliser, vous devez soit en créer un nouveau, soit rejoindre un club existant en demandant à un responsable de club d'ajouter votre adresse e-mail.",
  "workload": "Charge de travail"
}
