import { createI18n } from "vue-i18n";
import { messages, langs } from "src/i18n";
import { LocalStorage } from "quasar";
import { Quasar } from "quasar";

const locale = LocalStorage.getItem("locale") || navigator?.language || "fr-FR";

const i18n = createI18n({
  locale,
  fallbackLocale: "en-US",
  globalInjection: true,
  messages,
});

export default ({ app }) => {
  // Set i18n instance on app
  Quasar.lang.set(langs[locale]);

  app.use(i18n);
};

const i18ng = i18n.global;

export { i18ng as i18n };
