export const SCENARIOS = {
  INSIDERS_LIVE: "INSIDERS_LIVE",
  INTENSE_OFFLINE: "INTENSE_OFFLINE",
  INSPIRIT_OFFLINE: "INSPIRIT_OFFLINE",
  POLAR: "POLAR",
};

export const SCENARIOS_NAMES = {
  [SCENARIOS.INSIDERS_LIVE]: "Insiders Live",
  [SCENARIOS.INTENSE_OFFLINE]: "Intense Offline",
  [SCENARIOS.INSPIRIT_OFFLINE]: "Inspirit Offline",
  [SCENARIOS.POLAR]: "Polar",
};

export const DEVICES = Object.freeze({
  INSPIRIT: "INSPIRIT",
  POLAR: "POLAR",
  INTENSE: "INTENSE",
});

export default {
  props: {
    club: {
      type: Object,
      required: true,
    },
  },
  data() {
    const ENABLED_DEVICES = {
      [DEVICES.INTENSE]: this.club.subscription.devices.includes(DEVICES.INTENSE),
      [DEVICES.INSPIRIT]: this.club.subscription.devices.includes(DEVICES.INSPIRIT),
      [DEVICES.POLAR]: this.club.subscription.devices.includes(DEVICES.POLAR),
    };

    const ENABLED_SCENARIOS = {
      [SCENARIOS.INSIDERS_LIVE]: ENABLED_DEVICES.INTENSE,
      [SCENARIOS.INTENSE_OFFLINE]: ENABLED_DEVICES.INTENSE,
      [SCENARIOS.INSPIRIT_OFFLINE]: ENABLED_DEVICES.INSPIRIT,
      [SCENARIOS.POLAR]: ENABLED_DEVICES.POLAR,
    };

    return {
      SCENARIOS,
      SCENARIOS_NAMES,
      ENABLED_SCENARIOS,
      ENABLED_DEVICES,
      DEVICES,
    };
  },
};
