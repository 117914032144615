import {
  isAuthenticated,
  isAdmin,
  isClubManager,
  isTeamManager,
  isPlayerManager,
  isVerified,
  isClubSubscriptionActive,
  canAsync,
  getUser,
  getClub,
} from "./guards";
import { apolloClient } from "boot/apollo";
import MainLayout from "layouts/MainLayout.vue";
import { VERIFY_EMAIL_MUTATION } from "src/graphql/userQueries";

const routes = [
  // * app
  {
    path: "",
    redirect: { name: "clubTeams" },
    component: MainLayout,
    children: [
      {
        path: "club",
        component: () => import("layouts/ClubLayout.vue"),
        meta: { hasClubDrawer: true },
        children: [
          {
            path: "",
            name: "clubDashboard",
            redirect: { name: "clubTeams" },
            component: () => import("pages/club/ClubDashboardPage.vue"),
          },
          {
            path: "members",
            name: "clubMembers",
            component: () => import("src/pages/club/clubMembersPage.vue"),
            beforeEnter: async () => {
              const [isManager, canAccessClubMembers] = await Promise.all([
                isClubManager(),
                canAsync("club:members:any"),
              ]);

              if (!canAccessClubMembers() || !isManager) {
                return { name: "clubDashboard" };
              }
            },
          },
          {
            path: "teams",
            name: "clubTeams",
            component: () => import("src/pages/club/ClubTeamsPage.vue"),
          },
          {
            path: "players",
            name: "clubPlayers",
            component: () => import("src/pages/club/ClubPlayersPage.vue"),
            beforeEnter: async (_, __, next) => {
              (await isClubManager()) ? next() : next({ name: "clubTeams" });
            },
          },
          {
            path: "devices",
            name: "clubDevices",
            component: () => import("src/pages/club/ClubDevicesPage.vue"),
            beforeEnter: async (_, __, next) => {
              (await isClubManager()) ? next() : next({ name: "clubTeams" });
            },
          },
        ],
      },
      // * team
      {
        path: "team/:teamId",
        component: () => import("layouts/TeamLayout.vue"),
        meta: { hasTeamDrawer: true },
        children: [
          {
            path: "dashboard",
            name: "teamDashboard",
            redirect: { name: "teamAnalyses" },
          },
          {
            path: "analyses",
            name: "teamAnalyses",
            component: () => import("src/pages/team/TeamAnalysesPage.vue"),
            beforeEnter: async (to) => {
              if (!(await canAsync("team:analyses:read"))()) {
                to.name = "teamPlayers";

                return to;
              }
            },
          },
          {
            path: "players",
            name: "teamPlayers",
            component: () => import("src/pages/team/TeamPlayersPage.vue"),
          },
          {
            path: "events",
            name: "teamEvents",
            component: () => import("src/pages/team/TeamEventsPage.vue"),
          },
          {
            path: "health",
            name: "teamHealth",
            component: () => import("src/pages/team/TeamHealthPage.vue"),
            beforeEnter: async () => {
              if (!(await canAsync("player:health:any"))()) {
                return {
                  name: "teamDashboard",
                };
              }
            },
          },
          {
            path: "compare",
            name: "teamPlayerCompare",
            component: () => import("src/pages/player/PlayerComparePage.vue"),
            beforeEnter: async () => {
              if (!(await canAsync("player:compare:any"))()) {
                return {
                  name: "teamDashboard",
                };
              }
            },
          },
          {
            name: "teamDevices",
            path: "devices",
            component: () => import("src/pages/team/TeamDevicesPage.vue"),
          },
          {
            name: "device",
            path: "device/:deviceId",
            component: () => import("src/pages/team/DevicePage.vue"),
          },
          {
            name: "teamSettings",
            path: "settings",
            component: () => import("src/pages/team/TeamSettingsPage.vue"),
          },
        ],
        beforeEnter: async ({ params: { teamId } }, _, next) => {
          (await isTeamManager(teamId)) ? next() : next({ name: "teams" });
        },
      },
      // * player
      {
        path: "player/:playerId",
        component: () => import("layouts/PlayerLayout.vue"),
        meta: { hasPlayerDrawer: true },
        children: [
          {
            path: "dashboard",
            name: "playerDashboard",
            component: () => import("src/pages/player/PlayerDashboardPage.vue"),
          },
          {
            path: "events",
            name: "playerEvents",
            component: () => import("src/pages/player/PlayerRecordsPage.vue"),
            beforeEnter: async () => {
              if (!(await canAsync("player:records:read"))()) {
                return {
                  name: "playerDashboard",
                };
              }
            },
          },
          {
            path: "health",
            name: "playerHealth",
            component: () => import("src/pages/player/PlayerHealthPage.vue"),
            beforeEnter: async () => {
              if (!(await canAsync("player:health:any"))()) {
                return {
                  name: "playerDashboard",
                };
              }
            },
          },
          {
            path: "compare",
            name: "playerCompare",
            component: () => import("src/pages/player/PlayerComparePage.vue"),
            beforeEnter: async () => {
              if (!(await canAsync("player:compare:any"))()) {
                return {
                  name: "playerDashboard",
                };
              }
            },
          },
          {
            name: "playerReport",
            path: "report",
            component: () => import("src/pages/player/PlayerReportPage.vue"),
          },
        ],
        beforeEnter: async ({ params: { playerId } }, _, next) => {
          (await isPlayerManager(playerId)) ? next() : next({ name: "players" });
        },
      },
      // * event
      {
        path: "event/:eventId",
        component: () => import("layouts/EventLayout.vue"),
        meta: { hasEventDrawer: true },
        children: [
          {
            path: "dashboard",
            name: "eventDashboard",
            component: () => import("pages/event/EventDashboardPage.vue"),
          },
          {
            path: "players",
            name: "eventSelection",
            component: () => import("pages/event/EventSelectionPage.vue"),
          },
          {
            path: "live",
            name: "eventLive",
            component: () => import("pages/event/EventLivePage.vue"),
          },
          {
            path: "records",
            name: "eventRecords",
            component: () => import("pages/event/EventRecordsPage.vue"),
          },
          {
            path: "charts",
            name: "eventCharts",
            component: () => import("pages/event/EventChartsPage.vue"),
            // beforeEnter: async () => {
            //   if (!(await canAsync("event:charts:offline:read"))()) {
            //     return {
            //       name: "eventDashboard",
            //     };
            //   }
            // },
          },
          {
            path: "intervals",
            name: "eventIntervals",
            component: () => import("src/pages/event/EventIntervalsPage.vue"),
          },
          {
            path: "export",
            name: "eventExport",
            component: () => import("pages/event/EventExportPage.vue"),
            beforeEnter: async () => {
              if (!(await canAsync("event:export:any"))()) {
                return {
                  name: "eventDashboard",
                };
              }
            },
          },
          {
            path: "import",
            name: "eventImport",
            component: () => import("pages/event/EventImportPage.vue"),
          },
        ],
      },
    ],
    beforeEnter: async () => {
      const user = await getUser();

      if (!user) {
        return { name: "login" };
      }

      if (!user.verified) {
        return {
          name: "verification",
        };
      }

      if (user.admin) {
        return {
          name: "admin",
        };
      }

      const club = await getClub();

      if (!club?.subscription?.active) {
        return {
          name: "landing",
        };
      }
    },
  },
  // verification
  {
    path: "/verify-email/:token?",
    name: "verifyEmail",
    beforeEnter: async ({ params: { token } }) => {
      if (token) {
        try {
          await apolloClient.mutate({
            mutation: VERIFY_EMAIL_MUTATION,
            variables: { token },
          });
        } catch (error) {
          return {
            name: "login",
          };
        }

        return {
          name: "clubDashboard",
        };
      } else {
        return {
          name: "login",
        };
      }
    },
  },
  {
    path: "",
    name: "verification",
    component: () => import("pages/VerificationPage.vue"),
    beforeEnter: async () => {
      if (!(await isAuthenticated())) return { name: "login" };

      if (await isVerified()) {
        return {
          name: "clubDashboard",
        };
      }
    },
  },
  // admin
  {
    path: "",
    component: MainLayout,
    children: [
      {
        path: "admin",
        component: () => import("layouts/AdminLayout.vue"),
        meta: { hasAdminDrawer: true },
        children: [
          {
            path: "",
            name: "admin",
            redirect: { name: "adminUsers" },
          },
          {
            path: "users",
            name: "adminUsers",
            component: () => import("pages/admin/AdminUsers.vue"),
          },
          {
            path: "subscriptions",
            name: "adminSubscriptions",
            component: () => import("pages/admin/AdminSubscriptions.vue"),
          },
          {
            path: "system",
            name: "adminSystem",
            component: () => import("pages/admin/AdminSystem.vue"),
          },
        ],
        beforeEnter: async () => {
          if (!(await isAdmin())) return { name: "landing" };
        },
      },
    ],
  },
  // landing
  {
    path: "",
    component: MainLayout,
    meta: { drawerHidden: true },
    children: [
      {
        path: "",
        name: "landing",
        component: () => import("pages/LandingPage.vue"),
        beforeEnter: async () => {
          if (await isClubSubscriptionActive()) {
            return {
              name: "clubDashboard",
            };
          }
        },
      },
    ],
  },
  // * auth
  {
    path: "",
    component: () => import("layouts/AuthLayout.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("src/components/user/LoginCard.vue"),
      },
      {
        path: "register",
        name: "register",
        component: () => import("src/components/user/RegisterCard.vue"),
      },
      {
        path: "reset-password",
        name: "resetPassword",
        component: () => import("src/components/user/ResetPasswordCard.vue"),
      },
      {
        path: "new-password/:token?",
        name: "newPassword",
        beforeEnter: async ({ params: { token } }) => {
          if (!token) {
            return {
              name: "login",
            };
          }
        },
      },
    ],
    beforeEnter: async () => {
      if (await isAdmin()) {
        return {
          name: "admin",
        };
      }

      if (await isAuthenticated()) return { name: "clubDashboard" };
    },
  },

  // * 404
  {
    path: "/:catchAll(.*)*",
    redirect: { name: "clubDashboard" },
  },
];

export default routes;
