<template>
  <q-stepper
    ref="stepper"
    v-model="step"
    color="secondary"
    animated
    active-color="secondary"
    done-color="secondary"
    class="rounded-borders"
  >
    <q-step :name="STEPS.TIME" title="EVENT" :caption="eventDuration" icon="view_timeline" :done="step > STEPS.TIME">
      <EventTimeRangePicker :event="event" :speed-samples="speedSamples" />
      <q-stepper-navigation class="row justify-end q-gutter-sm">
        <q-btn
          color="secondary"
          :disable="!(event.startDate && event.endDate)"
          push
          rounded
          label="Next"
          @click="goToStep(STEPS.PERIODS)"
        />
      </q-stepper-navigation>
    </q-step>

    <q-step
      :name="STEPS.PERIODS"
      title="PERIODS"
      :caption="`${event?.periods?.length ?? 0} periods`"
      icon="view_timeline"
      :done="step > STEPS.PERIODS"
    >
      <EventPeriodsStepper :event="event" :speed-samples="speedSamples" bordered class="rounded-borders" rounded />
      <q-stepper-navigation class="row justify-end q-gutter-sm">
        <q-btn flat rounded color="secondary" label="Back" class="q-ml-sm" @click="goToStep(STEPS.TIME)" />
        <q-btn color="secondary" push rounded label="Next" @click="goToStep(STEPS.SESSIONS)" />
      </q-stepper-navigation>
    </q-step>

    <q-step :name="STEPS.SESSIONS" title="SESSIONS" icon="view_timeline" :done="step > STEPS.SESSIONS">
      <EventSessionsStepper
        ref="sessionsStepper"
        :event="event"
        :speed-samples="speedSamples"
        bordered
        class="rounded-borders"
      />
      <q-stepper-navigation class="row justify-end q-gutter-sm">
        <q-btn flat rounded color="secondary" label="Back" class="q-ml-sm" @click="goToStep(STEPS.PERIODS)" />
        <q-btn color="secondary" push rounded label="Next" @click="goToStep(STEPS.IMPORT)" />
      </q-stepper-navigation>
    </q-step>

    <q-step :name="STEPS.IMPORT" title="IMPORT" icon="file_upload">
      <q-card flat bordered>
        <q-tabs
          v-model="selectedSource"
          active-color="secondary"
          indicator-color="secondary"
          inline-label
          align="justify"
        >
          <q-tab
            v-if="ENABLED_DEVICES.INTENSE || ENABLED_DEVICES.INSPIRIT"
            name="INSIDERS"
            icon="radio_button_checked"
            label="Insiders"
          >
            <q-badge class="q-ml-xs" color="accent">USB / DKG</q-badge>
            <q-badge class="q-ml-xs" color="secondary">API</q-badge>
          </q-tab>
          <q-tab v-if="ENABLED_DEVICES.POLAR" :name="DEVICES.POLAR" icon="explore" label="POLAR">
            <q-badge class="q-ml-xs" dense color="grey">CSV</q-badge>
          </q-tab>
        </q-tabs>
      </q-card>

      <q-card bordered flat class="q-mt-sm">
        <q-tab-panels v-model="selectedSource" animated>
          <q-tab-panel name="INSIDERS">
            <div class="row justify-between">
              <div>
                <DeviceUSBImportBtn />
              </div>
              <div>
                <EventComputeBtn :event="event" @done="$emit('imported')" />
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel :name="DEVICES.POLAR">
            <EventTimeline :event="event">
              <template #event="props">
                <EventFilesUpload :event="props.event" :club="club" />
              </template>
              <template #period="props">
                <EventFilesUpload :event="props.event" :period-id="props.period.id" :club="club" />
              </template>
            </EventTimeline>

            <q-stepper-navigation class="row justify-end q-gutter-sm">
              <q-btn
                flat
                rounded
                color="secondary"
                :label="$t('global.back')"
                class="q-ml-sm"
                @click="goToStep(STEPS.SESSIONS)"
              />
              <q-btn v-close-popup color="secondary" push rounded :label="$t('global.finish')" />
            </q-stepper-navigation>
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </q-step>
  </q-stepper>
</template>
<script>
import EventTimeRangePicker from "src/components/event/EventTimeRangePicker.vue";
import EventPeriodsStepper from "src/components/event/EventPeriodsStepper.vue";
import EventSessionsStepper from "src/components/event/EventSessionsStepper.vue";
import { EVENT_SPEED_SAMPLES_QUERY } from "src/graphql/eventQueries";
import scenariosMixin from "src/mixins/scenariosMixin";
import { intervalToDuration } from "date-fns";
import { get } from "lodash";
import EventComputeBtn from "src/components/event/EventComputeBtn.vue";
import EventFilesUpload from "src/components/event/EventFilesUpload.vue";
import EventTimeline from "src/components/event/EventTimeline.vue";
import DeviceUSBImportBtn from "src/components/device/DeviceUSBImportBtn.vue";

const STEPS = {
  TIME: 1,
  PERIODS: 2,
  SESSIONS: 3,
  IMPORT: 4,
};

export default {
  components: {
    EventTimeRangePicker,
    DeviceUSBImportBtn,
    EventPeriodsStepper,
    EventSessionsStepper,
    EventComputeBtn,
    EventFilesUpload,
    EventTimeline,
  },
  mixins: [scenariosMixin],
  props: {
    club: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      STEPS: STEPS,
      step: 1,
      selectedSource: "",
      speedSamples: [],
    };
  },
  computed: {
    eventDuration() {
      return this.fomatDuration(this.getEventDuration(this.event));
    },
    isUSBEnabled() {
      return !!navigator.usb;
    },
  },
  mounted() {
    this.selectedSource =
      this.ENABLED_DEVICES.INTENSE || this.ENABLED_DEVICES.INSPIRIT
        ? "INSIDERS"
        : this.ENABLED_DEVICES.POLAR
        ? this.DEVICES.POLAR
        : null;
  },
  methods: {
    goToStep(step) {
      this.$refs.stepper.goTo(step);
    },
    fomatDuration(duration) {
      return `${get(duration, "hours", 0)}:${get(duration, "minutes", 0)}:${get(duration, "secondes", 0)}`;
    },
    getEventDuration(event) {
      if (!event.startDate || !event.endDate) {
        return null;
      }

      return intervalToDuration({
        start: new Date(event.startDate),
        end: new Date(event.endDate),
      });
    },
  },
  apollo: {
    speedSamples: {
      query: EVENT_SPEED_SAMPLES_QUERY,
      variables() {
        return {
          eventId: this.event.id,
        };
      },
      update(data) {
        return data.event.speedSamples;
      },
      skip() {
        return !this.event?.id;
      },
    },
  },
};
</script>
