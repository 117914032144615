<template>
  <q-uploader
    ref="uploader"
    color="secondary"
    :label="label"
    :factory="uploadEventFile"
    :max-files="1"
    accept=".csv"
    class="fit"
    flat
    bordered
    style="max-height: none"
    @added="parseEventFile"
    @removed="
      data = null;
      error = null;
    "
  >
    <template #header="scope">
      <div class="row no-wrap items-center q-pa-sm q-gutter-xs">
        <q-spinner v-if="scope.isUploading" class="q-uploader__spinner" />
        <div class="col">
          <div class="q-uploader__title">Upload your files</div>
          <div class="q-uploader__subtitle">{{ scope.uploadSizeLabel }} / {{ scope.uploadProgressLabel }}</div>
        </div>
        <q-btn v-if="scope.canAddFiles" type="a" icon="add_box" round dense flat @click="scope.pickFiles">
          <q-uploader-add-trigger />
        </q-btn>
        <q-btn-dropdown v-if="scope.canUpload" flat rounded :label="selectedDeviceType || 'Select file type'" clea>
          <q-list>
            <q-item
              v-for="(deviceType, index) in devices"
              :key="index"
              v-close-popup
              clickable
              @click="selectedDeviceType = deviceType"
            >
              <q-item-section>
                <q-item-label>{{ deviceType }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <q-btn v-if="scope.canUpload && selectedDeviceType" icon="upload_file" round dense flat @click="scope.upload" />

        <q-btn v-if="scope.isUploading" icon="clear" round dense flat @click="scope.abort" />
      </div>
    </template>

    <template #list="scope">
      <q-table v-if="data" title="File data" :rows="data" flat dense bordered :rows-per-page-options="[0]">
        <template #top-right>
          <q-btn
            class="gt-xs"
            size="12px"
            flat
            color="negative"
            rounded
            label="Clear"
            icon-right="delete"
            @click="scope.removeFile(scope.files[0])"
          />
        </template>
        <template #no-data>
          <q-banner rounded class="bg-warning q-mt-sm bordered">
            {{ error }}
          </q-banner>
        </template>
      </q-table>
    </template>
  </q-uploader>
</template>
<script>
import Papa from "papaparse";
import { UPLOAD_EVENT_FILE, EVENT_RECORDS_QUERY } from "src/graphql/eventQueries";
// import { intersection } from "lodash";

export default {
  props: {
    club: {
      type: Object,
      required: true,
    },
    event: Object,
    periodId: String,
    label: { type: String, default: () => "Select a session file" },
  },
  data() {
    return {
      selectedDeviceType: "POLAR",
      data: null,
      error: null,
    };
  },
  computed: {
    devices() {
      return this.club.subscription?.devices;
    },
  },
  methods: {
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (res) => {
          resolve(res.target.result);
        };

        reader.onerror = (err) => reject(err);

        reader.readAsText(file);
      });
    },
    async parseEventFile(files) {
      try {
        const file = files[0];
        let fileData = await this.readFile(file);

        fileData = fileData.replaceAll('"', "");

        this.data = Papa.parse(fileData, { header: true }).data;

        this.error = null;
      } catch (error) {
        this.error = error;
      }

      return this.data;
    },
    async uploadEventFile(files) {
      await this.$apollo.mutate({
        mutation: UPLOAD_EVENT_FILE,
        variables: {
          eventId: this.event.id,
          file: files[0],
          periodId: this.periodId,
          deviceType: this.selectedDeviceType,
        },
        update: (store, { data: { uploadEventFile } }) => {
          const query = {
            query: EVENT_RECORDS_QUERY,
            variables: { eventId: this.event.id },
          };
          const data = store.readQuery(query);

          store.writeQuery({
            ...query,
            data: {
              event: {
                ...data.event,
                records: uploadEventFile,
              },
            },
          });
        },
      });

      this.$refs.uploader.removeFile(files[0]);

      return true;
    },
  },
};
</script>
