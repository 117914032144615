import { PASSWORD_CONFIG_QUERY } from "src/graphql/userQueries";
import isEmail from "validator/lib/isEmail";
import isStrongPassword from "validator/lib/isStrongPassword";

export default {
  data() {
    return {
      passwordConfig: {},
      requiredRule: (val) => !!val || this.$t("rule.required"),
      emailRule: (val) => isEmail(val + "") || this.$t("rule.email"),
      passwordRule: (val) => isStrongPassword(val + "", { ...this.passwordConfig }) || this.$t("rule.password"),
    };
  },
  apollo: {
    passwordConfig: PASSWORD_CONFIG_QUERY,
  },
};
