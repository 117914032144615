import gql from "graphql-tag";

export const rangeFragment = gql`
  fragment rangeFragment on Range {
    id
    name
    range
  }
`;

export const CREATE_TEAM_RANGE_MUTATION = gql`
  mutation createTeamRange($teamId: ID!, $type: RangeType!) {
    createTeamRange(teamId: $teamId, type: $type) {
      id
      ranges {
        speed {
          ...rangeFragment
        }
        accel {
          ...rangeFragment
        }
      }
    }
  }
  ${rangeFragment}
`;

export const DELETE_TEAM_RANGE_MUTATION = gql`
  mutation deleteTeamRange($teamId: ID!, $id: ID!, $type: RangeType!) {
    deleteTeamRange(teamId: $teamId, id: $id, type: $type) {
      id
      ranges {
        speed {
          ...rangeFragment
        }
        accel {
          ...rangeFragment
        }
      }
    }
  }
  ${rangeFragment}
`;

export const UPDATE_TEAM_RANGE_MUTATION = gql`
  mutation updateTeamRange($teamId: ID!, $id: ID!, $type: RangeType!, $range: RangeInput!) {
    updateTeamRange(teamId: $teamId, id: $id, range: $range, type: $type) {
      id
      ranges {
        speed {
          ...rangeFragment
        }
        accel {
          ...rangeFragment
        }
      }
    }
  }
  ${rangeFragment}
`;

export const CREATE_EVENT_RANGE_MUTATION = gql`
  mutation createEventRange($eventId: ID!, $type: RangeType!) {
    createEventRange(eventId: $eventId, type: $type) {
      id
      ranges {
        speed {
          ...rangeFragment
        }
        accel {
          ...rangeFragment
        }
      }
    }
  }
  ${rangeFragment}
`;

export const DELETE_EVENT_RANGE_MUTATION = gql`
  mutation deleteEventRange($eventId: ID!, $type: RangeType!, $id: ID!) {
    deleteEventRange(eventId: $eventId, id: $id, type: $type) {
      id
      ranges {
        speed {
          ...rangeFragment
        }
        accel {
          ...rangeFragment
        }
      }
    }
  }
  ${rangeFragment}
`;

export const UPDATE_EVENT_RANGE_MUTATION = gql`
  mutation updateEventRange($eventId: ID!, $id: ID!, $type: RangeType!, $range: RangeInput!) {
    updateEventRange(eventId: $eventId, id: $id, range: $range, type: $type) {
      id
      ranges {
        speed {
          ...rangeFragment
        }
        accel {
          ...rangeFragment
        }
      }
    }
  }
  ${rangeFragment}
`;
