<template>
  <q-btn :loading="loading" color="secondary" rounded push inline @click="computeRecords">
    {{ $t("event.compute") }}
    <template #loading>
      <q-spinner-hourglass class="on-left" />
      {{ $t("global.compute") }} ...
    </template>
  </q-btn>
</template>
<script>
import { COMPUTE_EVENT_RECORDS, EVENT_SPEED_SAMPLES_QUERY, EVENT_RECORDS_QUERY } from "src/graphql/eventQueries";

export default {
  props: { event: Object },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async computeRecords() {
      try {
        this.loading = true;

        await this.$apollo.mutate({
          mutation: COMPUTE_EVENT_RECORDS,
          variables: {
            eventId: this.event.id,
          },
          update: (store, { data: { computeEventRecords } }) => {
            const query = {
              query: EVENT_RECORDS_QUERY,
              variables: { eventId: this.event.id },
            };
            const data = store.readQuery(query);

            store.writeQuery({
              ...query,
              data: {
                event: {
                  ...data.event,
                  records: computeEventRecords,
                },
              },
            });
          },
          refetchQueries: [
            {
              query: EVENT_SPEED_SAMPLES_QUERY,
              variables: {
                eventId: this.event.id,
              },
            },
          ],
        });
      } finally {
        this.loading = false;

        this.$emit("done");
      }
    },
  },
};
</script>
