<template>
  <q-list v-if="event" padding>
    <q-item
      v-ripple
      :to="{
        name: 'eventDashboard',
        query: { periodId: $route.query.periodId },
      }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="space_dashboard" />
      </q-item-section>

      <q-item-section> {{ $t("dashboard") }} </q-item-section>
    </q-item>

    <q-item
      v-if="!event.locked && !event.endDate"
      v-ripple
      :to="{ name: 'eventLive', query: { periodId: $route.query.periodId } }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="radio_button_checked" />
      </q-item-section>

      <q-item-section> {{ $t("live") }} </q-item-section>
    </q-item>

    <q-item
      v-if="!event.locked"
      v-ripple
      :to="{ name: 'eventSelection', query: { periodId: $route.query.periodId } }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="people" />
      </q-item-section>

      <q-item-section> {{ $t("selection") }} </q-item-section>
    </q-item>

    <q-item
      v-ripple
      :to="{ name: 'eventRecords', query: { periodId: $route.query.periodId } }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="playlist_play" />
      </q-item-section>

      <q-item-section> {{ $t("statistics") }} </q-item-section>
    </q-item>

    <q-item
      v-if="event.live || can('event:charts:offline:read')()"
      v-ripple
      :to="{ name: 'eventCharts', query: { periodId: $route.query.periodId } }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="insights" />
      </q-item-section>

      <q-item-section> {{ $t("charts") }} </q-item-section>
    </q-item>

    <div v-if="event?.endDate && !event.locked">
      <q-separator spaced />

      <q-item
        v-ripple
        :to="{
          name: 'eventIntervals',
          query: { periodId: $route.query.periodId },
        }"
        clickable
        class="q-layout-padding q-px-md"
        active-class="text-secondary"
      >
        <q-item-section avatar>
          <q-icon name="view_timeline" />
        </q-item-section>

        <q-item-section> {{ $t("intervals") }} </q-item-section>
      </q-item>

      <q-item
        v-if="event?.endDate && !event.locked"
        v-ripple
        :to="{
          name: 'eventImport',
          query: { periodId: $route.query.periodId },
        }"
        clickable
        class="q-layout-padding q-px-md"
        active-class="text-secondary"
      >
        <q-item-section avatar>
          <q-icon name="file_upload" />
        </q-item-section>

        <q-item-section> {{ $t("upload") }} </q-item-section>
      </q-item>
      <q-separator spaced />

      <q-item class="q-layout-padding q-px-md">
        <q-item-section v-if="mini" avatar>
          <q-btn round push color="secondary" icon="bolt" @click="onQuickImport" />
        </q-item-section>
        <q-item-section>
          <q-btn rounded push color="secondary" :label="$t('event.quick_import')" @click="onQuickImport"> </q-btn>
        </q-item-section>
        <q-dialog v-model="quickImportDialog" position="top" full-width>
          <q-card>
            <ImportEventStepper :club="club" :event="event" @imported="onImported" />
          </q-card>
        </q-dialog>
      </q-item>

      <q-item v-if="can('event:compute:any')()" class="q-layout-padding q-px-md q-pt-none">
        <q-item-section v-if="mini" avatar>
          <q-btn round push color="accent" icon="memory" @click="computeDialog = true" />
        </q-item-section>
        <q-item-section>
          <q-btn rounded push color="accent" :label="$t('global.compute')" @click="computeDialog = true">
            <q-dialog v-model="computeDialog" :persistent="$refs?.computeBtn?.loading">
              <q-card>
                <q-toolbar class="bg-accent text-white">
                  <q-toolbar-title> {{ $t("compute") }} </q-toolbar-title>
                </q-toolbar>
                <q-card-section>
                  {{ $t("compute.warning_msg") }}
                </q-card-section>
                <q-card-actions align="right">
                  <EventComputeBtn ref="computeBtn" :event="event" color="accent" @done="computeDialog = false" />
                </q-card-actions>
              </q-card>
            </q-dialog>
          </q-btn>
        </q-item-section>
      </q-item>
    </div>
  </q-list>
  <q-space></q-space>
  <q-separator />
  <q-list padding>
    <q-item
      v-if="can('event:export:any')()"
      v-ripple
      :to="{ name: 'eventExport', query: { periodId: $route.query.periodId } }"
      clickable
      class="q-layout-padding q-px-md"
      active-class="text-secondary"
    >
      <q-item-section avatar>
        <q-icon name="cloud_download" />
      </q-item-section>

      <q-item-section> Download </q-item-section>
    </q-item>
  </q-list>
</template>
<script>
import { EVENT_QUERY } from "src/graphql/eventQueries";
import EventComputeBtn from "src/components/event/EventComputeBtn.vue";
import ImportEventStepper from "./ImportEventStepper.vue";

export default {
  components: { EventComputeBtn, ImportEventStepper },
  props: {
    club: {
      type: Object,
      required: true,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      computeDialog: false,
      quickImportDialog: false,
    };
  },
  mounted() {
    if (this.$route.query.quickImport == "true") {
      setTimeout(() => {
        this.quickImportDialog = true;
      }, 500); // Idk why but it doesn't work without a timeout
    }
  },
  methods: {
    onQuickImport() {
      this.quickImportDialog = true;
    },
    onImported() {
      this.$router.replace({ query: { quickImport: null } });

      this.quickImportDialog = false;
    },
  },
  apollo: {
    event: {
      query: EVENT_QUERY,
      variables() {
        return { eventId: this.$route.params.eventId };
      },
      skip() {
        return !this.$route.params.eventId;
      },
    },
  },
};
</script>
