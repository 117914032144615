import gql from "graphql-tag";

export const seasonFragment = gql`
  fragment seasonFragment on Season {
    id
    name
    league
    startDate
    endDate
  }
`;



export const START_SEASON_MUTATION = gql`
  mutation startSeason($teamId: ID!, $season: SeasonInput!) {
    startSeason(teamId: $teamId, season: $season) {
      id
      name
      season {
        ...seasonFragment
      }
      seasons {
        ...seasonFragment
      }
    }
  }
  ${seasonFragment}
`;
