<template>
  <q-card flat>
    <q-form @reset="onReset()" @submit="onSubmit()">
      <q-card-section>
        <div class="row q-col-gutter-sm">
          <div class="col-12">
            <BarSliderVue
              :data="speedSamples"
              :from="new Date(form.startDate).getTime()"
              :to="new Date(form.endDate).getTime()"
              :min="new Date(event.startDate).getTime()"
              :max="new Date(event.endDate).getTime()"
              @update="onUpdate"
            />
          </div>
          <div class="col-xs-12 col-md-6">
            <DateTimePicker v-model="form.startDate" label="From" dense :rules="[requiredRule]" />
          </div>
          <div class="col-xs-12 col-md-6">
            <DateTimePicker
              v-model="form.endDate"
              label="To"
              dense
              :option-fn="endDateOptionsFn"
              :rules="[requiredRule, endDateRule]"
            />
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn icon="restart_alt" type="reset" color="secondary" flat fab-mini dense />
        <q-btn :disable="!canSave" icon="save" type="submit" color="positive" fab-mini flat />
      </q-card-actions>
    </q-form>
  </q-card>
</template>
<script>
import datesMixin from "src/mixins/datesMixin";
import { differenceInDays, differenceInMinutes } from "date-fns";
import { UPDATE_EVENT_DATES_MUTATION, EVENT_SPEED_SAMPLES_QUERY } from "src/graphql/eventQueries";
import BarSliderVue from "src/components/ui/BarSlider.vue";
import rulesMixin from "src/mixins/rulesMixin";

export default {
  components: {
    BarSliderVue,
  },
  mixins: [datesMixin, rulesMixin],
  props: {
    data: Array,
    event: {
      type: Object,
      required: true,
    },
    speedSamples: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: this.getFormData(),
    };
  },
  computed: {
    canSave() {
      return this.form.startDate && this.form.endDate;
    },
  },
  watch: {
    form: {
      handler() {
        this.range = {
          from: this.extractDateTimeString(this.form.startDate).getTime(),
          to: this.extractDateTimeString(this.form.endDate).getTime(),
        };
      },
      deep: true,
    },
  },
  methods: {
    onUpdate(range) {
      this.form.startDate = this.maskDate(new Date(range.min));

      this.form.endDate = this.maskDate(new Date(range.max));
    },
    getFormData() {
      return {
        startDate: this.event.startDate ? this.maskDate(new Date(this.event.startDate)) : null,
        endDate: this.event.endDate ? this.maskDate(new Date(this.event.endDate)) : null,
      };
    },
    endDateRule(endDate) {
      if (!this.startDate) return true;

      const diff = differenceInMinutes(this.extractDateTimeString(endDate), this.extractDateTimeString(this.startDate));

      return (diff >= 1 && diff <= 3 * 60) || "Event must be at least 1 minutes long and max 3 hours";
    },
    endDateOptionsFn(endDate) {
      return differenceInDays(new Date(endDate), this.extractDateTimeString(this.startDate)) >= 0;
    },
    onSubmit() {
      this.$apollo.mutate({
        mutation: UPDATE_EVENT_DATES_MUTATION,
        variables: {
          eventId: this.event.id,
          startDate: this.extractDateTimeString(this.form.startDate),
          endDate: this.extractDateTimeString(this.form.endDate),
        },
        refetchQueries: [
          {
            query: EVENT_SPEED_SAMPLES_QUERY,
            variables: {
              eventId: this.event.id,
            },
          },
        ],
      });

      this.form = this.getFormData();
    },
    onReset() {
      this.form = this.getFormData();
    },
  },
};
</script>
