import gql from "graphql-tag";

export const injuryFragment = gql`
  fragment injuryFragment on Injury {
    id
    startDate
    runDate
    trainDate
    playDate
    status
    comment
  }
`;

export const TEAM_INJURIES_QUERY = gql`
  query teamInjuries($teamId: ID!) {
    team(teamId: $teamId) {
      id
      injuries {
        ...injuryFragment
      }
    }
  }
  ${injuryFragment}
`;

export const INJURIES_STATUSES_QUERY = gql`
  query injuriesStatuses{
    injuryStatuses
  }
`;
